// PROFILE MODULE
body.dark {
	// FRIENDS
	--app-friend-requests-badge-background: var(--primary);
	--app-friend-requests-badge-text: var(--black);
	--app-friend-request-date: #ffffff66;
	--app-friend-request-card-background: var(--dark-1);
	// OVERVIEW
	--app-profile-overview-rank-title: #333333;
	--app-profile-gravity-horizon-background: var(--background);
	--app-profile-gravity-earth-background: var(--background);
	--level-badge-background: var(--color-P5);
	--profile-avatar-border: #9537b9b0;
	--profile-avatar-bg-color: linear-gradient(0deg, #060513, #0605139c 50%);
	--profile-level-reached-bg-color: var(--primary);
	--profile-level-reached-trxt-color: var(--black);
	--profile-level-next-bg-color: #706f97;
	--profile-level-next-text-color: #1a1a25;
	--profile-xp-progress-color: linear-gradient(90deg, #7a48cd, #b55ff4, #b309f0);
	--profile-xp-progress-bg-color: 112, 111, 151;
	--profile-xp-reached-color: var(--primary);
	--profile-xp-next-color: #706f97;
	--profile-rank-title-bg-color: linear-gradient(90deg, #7c4dd1bd, #ad0ce9bd);
	--profile-balance-background-color: #1f1f43;
	// SETTINGS
	--profile-setting-input-background: var(--dark-1);
	// REFERRALS
	--app-friend-referrals-card-background: var(--dark-1);
	--app-friend-referrals-offline-badge: var(--dark-3);
	--app-friend-referrals-is-friend: #ffffff66;

	// USER PROFILE
	--user-profile-rank-title: #818181;
}
