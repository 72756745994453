ion-action-sheet.my-custom-action-sheet .action-sheet-group {
	// background: var(--app-color-white);
	background: var(--app-ion-action-sheet-background);
}

ion-action-sheet.my-custom-action-sheet .action-sheet-title {
	color: var(--app-ion-action-sheet-title);
	font-size: 1.2rem;
}

ion-action-sheet.my-custom-action-sheet ion-backdrop {
	opacity: 0.9;
}
