.versus-bar {
	&-back-box {
		height: 50px;
		background-color: #463f71 !important;
		// background-image: url('../../../../../../assets/images/contour-line.png');
		border-radius: 15px;
		z-index: -1;
	}
	&-avatar {
		width: 130px;
		height: 130px;
		object-fit: cover;
	}
}