.app-background {
	background-color: var(--app-background);
}
.app-background-primary {
	background-color: var(--app-background-primary);
}
.app-background-secondary {
	background-color: var(--app-background-secondary);
}
.app-background-success {
	background-color: var(--app-background-success);
}
.app-background-danger {
	background-color: var(--app-background-danger);
}
.app-background-warning {
	background-color: var(--app-background-warning);
}
.app-background-info {
	background-color: var(--app-background-info);
}
.app-background-white {
	background-color: var(--app-background-white);
}
.app-background-black {
	background-color: var(--app-background-black);
}
.app-background-neutral {
	background-color: var(--app-background-neutral);
}
