// ION ALERT MODULE
body.dark {
	// App Variables - Alert
	--app-alert-background: var(--dark-2);
	--app-alert-title-color: var(--white);
	--app-alert-message-color: var(--white);
	--app-alert-button-border-ios: var(--primary);
	--app-alert-button-confirm-background: var(--app-color-primary);
	--app-alert-button-confirm-text: var(--black);
	--app-alert-button-danger-background: var(--app-background-danger);
}
