#stars {
	width: 1px;
	height: 1px;
	background: transparent;
	box-shadow: 1288px 1798px #fff, 752px 597px #fff, 1155px 431px #fff, 802px 5px #fff, 1059px 1104px #fff, 1720px 865px #fff, 238px 1744px #fff, 770px 517px #fff,
		1076px 997px #fff, 407px 1365px #fff, 1605px 432px #fff, 1619px 1707px #fff, 371px 1673px #fff, 214px 1121px #fff, 67px 110px #fff, 637px 946px #fff,
		1527px 564px #fff, 225px 992px #fff, 1389px 1145px #fff, 1227px 301px #fff, 421px 748px #fff, 1991px 708px #fff, 1301px 1681px #fff, 958px 1299px #fff,
		1684px 1461px #fff, 1742px 1508px #fff, 673px 952px #fff, 144px 1344px #fff, 8px 126px #fff, 1696px 1881px #fff, 744px 318px #fff, 140px 819px #fff,
		1722px 1286px #fff, 538px 959px #fff, 954px 1088px #fff, 937px 886px #fff, 1519px 1912px #fff, 39px 368px #fff, 1815px 1166px #fff, 919px 1603px #fff,
		742px 1512px #fff, 1596px 1319px #fff, 1844px 869px #fff, 1090px 1549px #fff, 1554px 1130px #fff, 1597px 1229px #fff, 203px 472px #fff, 179px 202px #fff,
		1241px 775px #fff, 1611px 1861px #fff, 1031px 1778px #fff, 1201px 633px #fff, 767px 268px #fff, 28px 1945px #fff, 809px 1594px #fff, 171px 957px #fff,
		320px 71px #fff, 279px 1143px #fff, 657px 1174px #fff, 1632px 508px #fff, 1023px 657px #fff, 231px 926px #fff, 218px 1295px #fff, 890px 1347px #fff,
		454px 1668px #fff, 142px 721px #fff, 510px 1801px #fff, 1009px 1580px #fff, 944px 1100px #fff, 160px 1545px #fff, 621px 493px #fff, 1834px 482px #fff,
		1719px 1264px #fff, 1581px 361px #fff, 1967px 1751px #fff, 164px 1136px #fff, 822px 1825px #fff, 1331px 7px #fff, 979px 742px #fff, 1714px 316px #fff,
		1214px 1769px #fff, 187px 1254px #fff, 839px 1069px #fff, 105px 1001px #fff, 1149px 1671px #fff, 125px 1845px #fff, 1659px 335px #fff, 422px 262px #fff,
		1437px 311px #fff, 777px 1786px #fff, 1530px 1997px #fff, 260px 166px #fff, 1487px 1992px #fff, 1183px 1240px #fff, 5px 42px #fff, 212px 1474px #fff,
		1073px 1853px #fff, 361px 192px #fff, 578px 923px #fff, 1115px 834px #fff, 1248px 1299px #fff, 713px 1006px #fff, 367px 673px #fff, 1768px 1237px #fff,
		1469px 187px #fff, 1309px 1929px #fff, 1461px 671px #fff, 1501px 39px #fff, 715px 1629px #fff, 902px 754px #fff, 1024px 551px #fff, 1317px 1319px #fff,
		150px 119px #fff, 1563px 729px #fff, 1812px 1370px #fff, 608px 925px #fff, 1676px 397px #fff, 931px 1263px #fff, 855px 769px #fff, 778px 477px #fff,
		1348px 873px #fff, 1678px 162px #fff, 1390px 130px #fff, 209px 379px #fff, 373px 1059px #fff, 956px 1944px #fff, 350px 1876px #fff, 1145px 645px #fff,
		108px 1440px #fff, 223px 804px #fff, 519px 905px #fff, 685px 382px #fff, 874px 1441px #fff, 1453px 289px #fff, 416px 348px #fff, 1866px 1532px #fff,
		1388px 1160px #fff, 340px 1087px #fff, 550px 326px #fff, 949px 1147px #fff, 1433px 238px #fff, 1819px 410px #fff, 1283px 1395px #fff, 1282px 1267px #fff,
		1616px 830px #fff, 419px 1785px #fff, 1633px 1484px #fff, 148px 1779px #fff, 1536px 519px #fff, 1377px 695px #fff, 640px 831px #fff, 226px 1044px #fff,
		986px 763px #fff, 849px 1090px #fff, 587px 811px #fff, 684px 599px #fff, 50px 603px #fff, 170px 383px #fff, 214px 1262px #fff, 1295px 890px #fff, 453px 115px #fff,
		725px 1806px #fff, 1609px 704px #fff, 101px 1841px #fff, 943px 762px #fff, 34px 622px #fff, 1921px 1444px #fff, 789px 348px #fff, 962px 1214px #fff,
		1654px 1466px #fff, 823px 6px #fff, 1347px 1698px #fff, 563px 1467px #fff, 138px 1913px #fff, 38px 1995px #fff, 1994px 1987px #fff, 1183px 1600px #fff,
		1109px 238px #fff, 893px 795px #fff, 776px 1611px #fff, 1439px 1267px #fff, 680px 1543px #fff, 1014px 492px #fff, 946px 1870px #fff, 1117px 1422px #fff,
		182px 1853px #fff, 1264px 1296px #fff, 1551px 1035px #fff, 1977px 367px #fff, 1884px 277px #fff, 32px 208px #fff, 910px 1617px #fff, 1326px 1556px #fff,
		1074px 1288px #fff, 777px 1876px #fff, 147px 790px #fff, 1356px 317px #fff, 776px 1006px #fff, 453px 1170px #fff, 1441px 271px #fff, 1775px 898px #fff,
		1861px 324px #fff, 636px 788px #fff, 1360px 1312px #fff, 1421px 1096px #fff, 1403px 831px #fff, 1330px 1832px #fff, 219px 1594px #fff, 1402px 689px #fff,
		1183px 918px #fff, 361px 800px #fff, 1397px 173px #fff, 445px 828px #fff, 74px 193px #fff, 1991px 426px #fff, 1416px 1371px #fff, 542px 1080px #fff,
		1455px 98px #fff, 93px 896px #fff, 1166px 1417px #fff, 432px 147px #fff, 1329px 1724px #fff, 939px 801px #fff, 1163px 592px #fff, 1027px 1241px #fff,
		1742px 616px #fff, 893px 471px #fff, 923px 136px #fff, 790px 436px #fff, 417px 299px #fff, 261px 1923px #fff, 647px 1266px #fff, 1779px 1054px #fff,
		1917px 1072px #fff, 1535px 219px #fff, 119px 1522px #fff, 821px 1338px #fff, 1735px 1869px #fff, 1759px 1732px #fff, 276px 913px #fff, 387px 1587px #fff,
		1443px 461px #fff, 505px 1195px #fff, 404px 851px #fff, 1951px 32px #fff, 554px 1320px #fff, 754px 1009px #fff, 1978px 1555px #fff, 685px 1440px #fff,
		1960px 549px #fff, 1230px 1575px #fff, 1427px 654px #fff, 131px 746px #fff, 250px 452px #fff, 543px 834px #fff, 1500px 992px #fff, 1256px 1464px #fff,
		1645px 287px #fff, 1649px 76px #fff, 1659px 1233px #fff, 1240px 1086px #fff, 270px 344px #fff, 978px 1129px #fff, 1244px 1796px #fff, 1961px 997px #fff,
		270px 412px #fff, 394px 978px #fff, 1709px 442px #fff, 59px 481px #fff, 191px 1211px #fff, 1120px 1974px #fff, 229px 1835px #fff, 1089px 1197px #fff,
		1131px 1796px #fff, 1841px 565px #fff, 80px 1275px #fff, 989px 533px #fff, 1085px 1601px #fff, 163px 1806px #fff, 953px 1558px #fff, 1893px 1360px #fff,
		1224px 439px #fff, 892px 756px #fff, 320px 1561px #fff, 1368px 858px #fff, 1410px 1518px #fff, 231px 542px #fff, 677px 795px #fff, 4px 1963px #fff, 913px 884px #fff,
		698px 10px #fff, 43px 56px #fff, 580px 1690px #fff, 1390px 581px #fff, 918px 1669px #fff, 841px 163px #fff, 1455px 1962px #fff, 61px 589px #fff, 1392px 413px #fff,
		701px 825px #fff, 1830px 1944px #fff, 1511px 1224px #fff, 1905px 191px #fff, 680px 953px #fff, 1872px 1226px #fff, 56px 478px #fff, 1131px 1297px #fff,
		1102px 343px #fff, 1287px 145px #fff, 731px 1571px #fff, 1639px 1972px #fff, 34px 790px #fff, 1091px 1972px #fff, 1953px 1936px #fff, 437px 1009px #fff,
		1978px 1533px #fff, 484px 467px #fff, 718px 54px #fff, 73px 1131px #fff, 634px 1510px #fff, 325px 650px #fff, 1921px 659px #fff, 426px 553px #fff, 758px 525px #fff,
		23px 1322px #fff, 1142px 989px #fff, 53px 1509px #fff, 348px 1208px #fff, 421px 1517px #fff, 9px 1950px #fff, 370px 1070px #fff, 1758px 1848px #fff,
		1752px 1763px #fff, 188px 152px #fff, 325px 602px #fff, 1494px 1761px #fff, 1857px 810px #fff, 718px 1853px #fff, 477px 297px #fff, 1273px 1691px #fff,
		1808px 1735px #fff, 252px 768px #fff, 810px 1640px #fff, 1171px 456px #fff, 1921px 1766px #fff, 350px 26px #fff, 898px 585px #fff, 1662px 1220px #fff,
		1219px 769px #fff, 712px 568px #fff, 1523px 23px #fff, 101px 855px #fff, 1817px 1680px #fff, 426px 1271px #fff, 1245px 658px #fff, 1299px 772px #fff,
		1533px 213px #fff, 18px 1272px #fff, 763px 92px #fff, 572px 1453px #fff, 972px 1251px #fff, 1420px 1196px #fff, 905px 900px #fff, 1548px 680px #fff,
		121px 1157px #fff, 1454px 610px #fff, 1179px 1281px #fff, 1442px 926px #fff, 1089px 494px #fff, 273px 1085px #fff, 588px 1465px #fff, 1269px 1858px #fff,
		1440px 204px #fff, 1073px 1248px #fff, 1492px 730px #fff, 1476px 1821px #fff, 467px 118px #fff, 125px 141px #fff, 259px 232px #fff, 1982px 429px #fff,
		107px 1751px #fff, 649px 1433px #fff, 1554px 950px #fff, 1530px 847px #fff, 618px 1520px #fff, 573px 225px #fff, 1987px 881px #fff, 1263px 1630px #fff,
		473px 818px #fff, 1912px 1524px #fff, 1271px 47px #fff, 1968px 1212px #fff, 811px 1111px #fff, 1058px 1643px #fff, 1146px 494px #fff, 586px 474px #fff,
		1234px 122px #fff, 127px 17px #fff, 1252px 726px #fff, 24px 664px #fff, 1295px 359px #fff, 1104px 94px #fff, 468px 1215px #fff, 1418px 339px #fff, 650px 1400px #fff,
		1803px 1161px #fff, 1495px 1137px #fff, 1785px 1729px #fff, 1172px 890px #fff, 1253px 1517px #fff, 662px 348px #fff, 1573px 1297px #fff, 547px 1790px #fff,
		373px 1146px #fff, 387px 1654px #fff, 335px 380px #fff, 1908px 567px #fff, 976px 50px #fff, 659px 315px #fff, 916px 1960px #fff, 1918px 1261px #fff,
		692px 357px #fff, 420px 1903px #fff, 5px 355px #fff, 667px 107px #fff, 1865px 245px #fff, 116px 1743px #fff, 1831px 319px #fff, 1051px 1552px #fff,
		1630px 938px #fff, 668px 1221px #fff, 263px 719px #fff, 694px 1979px #fff, 1598px 1824px #fff, 1232px 1734px #fff, 1521px 1111px #fff, 790px 349px #fff,
		1050px 766px #fff, 1971px 858px #fff, 274px 1560px #fff, 17px 817px #fff, 555px 242px #fff, 1433px 1678px #fff, 251px 1817px #fff, 124px 966px #fff,
		727px 568px #fff, 1350px 912px #fff, 1790px 407px #fff, 1309px 785px #fff, 453px 923px #fff, 1661px 459px #fff, 1445px 231px #fff, 1863px 1247px #fff,
		1964px 1681px #fff, 1698px 1660px #fff, 78px 987px #fff, 126px 887px #fff, 186px 304px #fff, 1427px 173px #fff, 1686px 1708px #fff, 1949px 1491px #fff,
		1639px 1414px #fff, 1806px 1263px #fff, 431px 974px #fff, 1950px 1734px #fff, 952px 1731px #fff, 802px 1569px #fff, 1576px 444px #fff, 940px 1902px #fff,
		17px 126px #fff, 1973px 71px #fff, 1845px 603px #fff, 1475px 1522px #fff, 1857px 1344px #fff, 1754px 770px #fff, 213px 494px #fff, 447px 1675px #fff,
		944px 611px #fff, 1232px 887px #fff, 977px 114px #fff, 1474px 1149px #fff, 1767px 847px #fff, 311px 787px #fff, 482px 613px #fff, 986px 245px #fff, 245px 9px #fff,
		1458px 1443px #fff, 292px 1910px #fff, 124px 490px #fff, 117px 890px #fff, 1159px 653px #fff, 1324px 1098px #fff, 1242px 861px #fff, 439px 1005px #fff,
		245px 1003px #fff, 1966px 1542px #fff, 1291px 1744px #fff, 1995px 179px #fff, 1193px 1522px #fff, 755px 995px #fff, 1153px 568px #fff, 1915px 1237px #fff,
		973px 313px #fff, 1473px 331px #fff, 1628px 1546px #fff, 1584px 136px #fff, 260px 598px #fff, 413px 1560px #fff, 838px 294px #fff, 108px 855px #fff,
		1989px 366px #fff, 1402px 1003px #fff, 370px 116px #fff, 866px 89px #fff, 1516px 748px #fff, 1284px 835px #fff, 253px 1892px #fff, 484px 158px #fff,
		203px 1918px #fff, 108px 1823px #fff, 310px 1632px #fff, 488px 1127px #fff, 634px 1676px #fff, 827px 898px #fff, 925px 1294px #fff, 20px 267px #fff,
		1576px 898px #fff, 491px 990px #fff, 654px 907px #fff, 497px 82px #fff, 1299px 701px #fff, 1211px 1501px #fff, 16px 1988px #fff, 1489px 1251px #fff,
		922px 114px #fff, 1983px 812px #fff, 1182px 1128px #fff, 559px 361px #fff, 1623px 659px #fff, 1960px 1867px #fff, 197px 1834px #fff, 1627px 1827px #fff,
		1370px 702px #fff, 402px 1289px #fff, 839px 1952px #fff, 1112px 1208px #fff, 1861px 1349px #fff, 1281px 493px #fff, 1864px 1368px #fff, 1954px 418px #fff,
		383px 477px #fff, 315px 1995px #fff, 935px 1333px #fff, 1455px 1456px #fff, 1253px 1533px #fff, 107px 1749px #fff, 1065px 303px #fff, 850px 544px #fff,
		301px 1063px #fff, 1698px 113px #fff, 1271px 1116px #fff, 1233px 1271px #fff, 603px 1755px #fff, 744px 122px #fff, 637px 1050px #fff, 1403px 922px #fff,
		349px 845px #fff, 1796px 830px #fff, 1935px 1254px #fff, 998px 1787px #fff, 490px 1592px #fff, 424px 62px #fff, 1494px 852px #fff, 1446px 403px #fff,
		1502px 1585px #fff, 933px 472px #fff, 109px 1979px #fff, 1725px 1421px #fff, 662px 398px #fff, 858px 42px #fff, 471px 1864px #fff, 557px 8px #fff, 405px 1577px #fff,
		941px 1666px #fff, 1942px 399px #fff, 1711px 1424px #fff, 1963px 1299px #fff, 1800px 1228px #fff, 947px 796px #fff, 1999px 1867px #fff, 777px 1690px #fff,
		209px 523px #fff, 1576px 1133px #fff, 461px 1778px #fff, 695px 1971px #fff, 518px 359px #fff, 7px 1933px #fff, 796px 1888px #fff, 1460px 1353px #fff,
		474px 171px #fff, 1529px 900px #fff, 1684px 1177px #fff, 212px 660px #fff, 751px 662px #fff, 1290px 1576px #fff, 1972px 1481px #fff, 924px 621px #fff,
		1467px 1830px #fff, 241px 282px #fff, 169px 1248px #fff, 301px 1367px #fff, 824px 1201px #fff, 1892px 1422px #fff, 1340px 1252px #fff, 1907px 1951px #fff,
		117px 1059px #fff, 65px 372px #fff, 427px 1944px #fff, 665px 1475px #fff, 680px 1959px #fff, 186px 891px #fff, 1245px 442px #fff, 744px 1585px #fff,
		1222px 1744px #fff, 1755px 1066px #fff, 531px 1611px #fff, 1188px 102px #fff, 1149px 78px #fff, 1919px 1628px #fff, 1531px 1960px #fff, 1014px 185px #fff,
		650px 840px #fff, 750px 1731px #fff, 1115px 465px #fff, 1089px 1772px #fff, 81px 1501px #fff, 1359px 1477px #fff, 1084px 1819px #fff, 1642px 1589px #fff,
		521px 1204px #fff, 823px 1774px #fff, 1278px 1930px #fff, 1821px 1387px #fff, 1869px 1653px #fff, 1246px 653px #fff, 46px 92px #fff, 1790px 453px #fff,
		1349px 150px #fff, 463px 405px #fff, 1388px 1218px #fff, 1165px 305px #fff, 19px 1303px #fff, 778px 414px #fff, 251px 1201px #fff, 452px 799px #fff,
		835px 1238px #fff, 633px 1409px #fff, 794px 1856px #fff, 1686px 991px #fff, 1425px 1738px #fff, 1301px 577px #fff, 568px 357px #fff, 883px 773px #fff,
		1848px 835px #fff, 776px 685px #fff, 562px 1738px #fff, 434px 5px #fff, 1139px 670px #fff, 730px 1188px #fff, 599px 31px #fff, 1749px 431px #fff, 83px 1450px #fff,
		801px 1763px #fff, 780px 658px #fff, 1975px 578px #fff, 755px 1710px #fff, 356px 673px #fff, 762px 895px #fff, 1383px 440px #fff, 1596px 1002px #fff,
		1226px 1209px #fff, 202px 1257px #fff, 578px 1796px #fff, 154px 116px #fff, 1330px 1985px #fff, 871px 1197px #fff, 540px 1689px #fff, 618px 237px #fff,
		1940px 1886px #fff, 440px 1796px #fff, 1098px 1890px #fff, 605px 414px #fff, 279px 308px #fff, 1669px 1181px #fff, 381px 782px #fff, 925px 1559px #fff,
		1760px 519px #fff, 406px 1866px #fff, 470px 1041px #fff, 1759px 639px #fff, 1823px 1350px #fff, 2px 1622px #fff, 893px 537px #fff, 1230px 567px #fff,
		1359px 772px #fff, 1704px 1092px #fff, 862px 285px #fff, 935px 656px #fff, 1487px 148px #fff, 1793px 1708px #fff, 976px 1800px #fff, 1059px 1401px #fff,
		816px 1232px #fff, 311px 1504px #fff, 1885px 1854px #fff, 720px 1149px #fff, 1687px 918px #fff, 1871px 1592px #fff, 1560px 768px #fff, 885px 782px #fff,
		134px 1666px #fff, 540px 950px #fff, 1794px 648px #fff, 77px 1229px #fff, 1131px 1630px #fff, 1801px 1843px #fff, 1098px 151px #fff, 240px 269px #fff,
		1803px 1087px #fff, 442px 1773px #fff, 388px 575px #fff, 94px 414px #fff, 88px 813px #fff, 906px 1283px #fff, 776px 1479px #fff, 1748px 665px #fff,
		843px 1062px #fff, 188px 823px #fff, 1866px 1566px #fff, 88px 1972px #fff, 53px 1650px #fff, 1031px 1902px #fff, 1705px 673px #fff, 588px 853px #fff,
		578px 1169px #fff, 941px 1039px #fff, 1074px 597px #fff, 1607px 278px #fff, 185px 1099px #fff, 19px 252px #fff, 826px 1026px #fff, 1794px 1564px #fff,
		322px 281px #fff, 1214px 1580px #fff, 1192px 174px #fff, 1102px 1873px #fff, 1417px 1297px #fff, 1004px 672px #fff, 46px 1724px #fff, 178px 1124px #fff,
		592px 362px #fff, 191px 311px #fff, 1929px 860px #fff, 1193px 1181px #fff, 728px 461px #fff, 1252px 1092px #fff, 270px 1642px #fff, 205px 837px #fff,
		1744px 1809px #fff, 1045px 17px #fff, 1981px 976px #fff, 669px 1934px #fff, 884px 1331px #fff, 236px 874px #fff, 1702px 1523px #fff, 1028px 1793px #fff,
		296px 985px #fff, 1496px 1508px #fff, 399px 1461px #fff, 824px 482px #fff, 929px 1633px #fff, 1681px 663px #fff, 1995px 1776px #fff, 1571px 534px #fff,
		1326px 68px #fff, 879px 728px #fff, 1497px 1433px #fff, 352px 1452px #fff, 1502px 1448px #fff, 129px 1362px #fff, 1416px 1305px #fff, 790px 227px #fff,
		1446px 1904px #fff, 1366px 591px #fff, 752px 1185px #fff, 581px 1873px #fff, 1145px 1737px #fff, 955px 1006px #fff, 753px 1462px #fff, 1849px 1645px #fff,
		912px 207px #fff, 1387px 106px #fff, 995px 1582px #fff, 1110px 1781px #fff, 1309px 1380px #fff, 138px 773px #fff, 176px 1515px #fff, 1682px 1895px #fff,
		1711px 1557px #fff, 103px 556px #fff, 1059px 1727px #fff, 1674px 53px #fff, 106px 1143px #fff, 979px 1682px #fff, 1165px 872px #fff, 1693px 325px #fff,
		1401px 1913px #fff, 380px 613px #fff, 308px 1655px #fff, 1062px 674px #fff, 961px 134px #fff, 167px 1687px #fff, 121px 1297px #fff, 739px 1214px #fff,
		1926px 181px #fff, 574px 150px #fff, 263px 1893px #fff, 1281px 1566px #fff, 322px 80px #fff, 1469px 490px #fff, 606px 1809px #fff, 1582px 1781px #fff,
		1011px 502px #fff, 764px 1444px #fff, 772px 1101px #fff, 1520px 660px #fff, 1167px 814px #fff, 854px 1181px #fff, 772px 1969px #fff, 1116px 1468px #fff,
		1325px 1476px #fff, 659px 290px #fff, 1566px 327px #fff, 340px 487px #fff, 1980px 1323px #fff, 341px 839px #fff, 1947px 1632px #fff, 826px 198px #fff,
		1292px 94px #fff, 1971px 967px #fff, 1540px 1919px #fff, 108px 914px #fff, 479px 147px #fff, 1277px 1418px #fff, 345px 784px #fff, 1699px 854px #fff,
		610px 644px #fff, 945px 1105px #fff, 591px 260px #fff, 1915px 782px #fff, 325px 1875px #fff, 318px 667px #fff, 996px 656px #fff, 129px 1188px #fff,
		1428px 1941px #fff, 786px 81px #fff, 1293px 760px #fff, 1021px 1728px #fff, 746px 1970px #fff, 1823px 475px #fff, 1199px 269px #fff, 1130px 864px #fff,
		656px 502px #fff, 1089px 1073px #fff, 307px 349px #fff, 548px 232px #fff, 1660px 1099px #fff, 1341px 274px #fff, 1366px 206px #fff, 116px 472px #fff,
		1883px 496px #fff, 771px 776px #fff, 449px 598px #fff, 1954px 779px #fff, 1599px 1407px #fff, 1101px 1984px #fff, 1892px 165px #fff, 1928px 1942px #fff,
		869px 1172px #fff, 726px 966px #fff, 655px 574px #fff, 482px 20px #fff, 420px 1788px #fff, 216px 898px #fff, 465px 925px #fff, 267px 1886px #fff, 426px 103px #fff,
		234px 1903px #fff, 1026px 1425px #fff, 1349px 74px #fff, 1328px 1098px #fff, 126px 157px #fff, 1512px 286px #fff, 1776px 243px #fff, 240px 414px #fff,
		853px 47px #fff, 1115px 1385px #fff, 1174px 851px #fff, 843px 658px #fff, 1557px 1166px #fff, 1769px 1118px #fff, 1085px 1652px #fff, 1771px 1726px #fff,
		201px 1418px #fff, 1378px 569px #fff, 359px 181px #fff, 1566px 1142px #fff, 733px 1121px #fff, 823px 116px #fff, 1134px 1837px #fff, 860px 1230px #fff,
		1565px 354px #fff, 576px 8px #fff, 114px 1423px #fff, 747px 1399px #fff, 715px 367px #fff, 631px 587px #fff, 10px 603px #fff, 1364px 1846px #fff, 966px 292px #fff,
		1443px 839px #fff, 1138px 400px #fff, 443px 1963px #fff, 1234px 1311px #fff, 1833px 153px #fff, 1833px 554px #fff, 1411px 1357px #fff, 439px 1687px #fff,
		1898px 1570px #fff, 1557px 565px #fff, 1622px 76px #fff, 1360px 1675px #fff, 915px 537px #fff, 1558px 267px #fff, 1388px 149px #fff, 1490px 985px #fff,
		849px 1356px #fff, 1335px 256px #fff, 1038px 708px #fff, 861px 1913px #fff, 63px 1876px #fff, 1743px 1309px #fff, 1875px 1199px #fff, 388px 581px #fff,
		1079px 1906px #fff, 120px 661px #fff, 91px 1190px #fff, 874px 1387px #fff, 24px 1225px #fff, 20px 1894px #fff, 1229px 534px #fff, 187px 1138px #fff,
		447px 1532px #fff, 1641px 1681px #fff, 987px 925px #fff, 1378px 669px #fff, 10px 49px #fff, 1079px 1568px #fff, 500px 1565px #fff, 676px 1166px #fff,
		1169px 530px #fff, 1194px 1559px #fff, 1983px 1692px #fff, 895px 398px #fff, 933px 1124px #fff, 544px 330px #fff, 933px 1971px #fff, 1583px 462px #fff,
		405px 677px #fff, 1944px 644px #fff, 1762px 1159px #fff, 880px 366px #fff, 1570px 776px #fff, 1714px 537px #fff, 1794px 1585px #fff, 1663px 1083px #fff,
		1711px 253px #fff, 1524px 507px #fff, 1908px 853px #fff, 549px 1626px #fff, 1151px 646px #fff, 563px 1787px #fff, 557px 330px #fff, 1747px 1717px #fff,
		1818px 1610px #fff, 1028px 1153px #fff, 147px 1186px #fff, 1966px 242px #fff, 1815px 1547px #fff, 1848px 804px #fff, 1283px 1116px #fff, 1756px 891px #fff,
		705px 1355px #fff, 916px 1817px #fff, 110px 539px #fff, 1574px 689px #fff, 1580px 887px #fff, 1369px 284px #fff, 793px 59px #fff, 1483px 1837px #fff,
		1834px 451px #fff, 675px 1285px #fff, 1816px 644px #fff, 959px 902px #fff, 1940px 1889px #fff, 1994px 292px #fff, 760px 1278px #fff, 1556px 1722px #fff,
		353px 1023px #fff, 1165px 1886px #fff, 1183px 1410px #fff, 1856px 100px #fff, 1471px 1368px #fff, 821px 611px #fff, 1741px 355px #fff, 1715px 1545px #fff,
		1406px 419px #fff, 94px 547px #fff, 32px 66px #fff, 1536px 467px #fff, 1404px 579px #fff, 1869px 1095px #fff, 347px 1258px #fff, 1450px 1066px #fff,
		1128px 172px #fff, 469px 1860px #fff, 1923px 76px #fff, 47px 424px #fff, 1909px 1933px #fff, 1485px 1592px #fff, 1837px 1113px #fff, 1096px 131px #fff,
		172px 1942px #fff, 1081px 1515px #fff, 683px 1342px #fff, 520px 95px #fff, 409px 331px #fff, 1293px 519px #fff, 1660px 1878px #fff, 1145px 1498px #fff,
		371px 627px #fff, 1417px 374px #fff, 1556px 1719px #fff, 1812px 461px #fff, 216px 683px #fff, 492px 1088px #fff, 1949px 1229px #fff, 660px 1388px #fff,
		1315px 1460px #fff, 1335px 778px #fff, 1133px 95px #fff, 424px 1535px #fff, 586px 1491px #fff, 1102px 1259px #fff, 22px 1337px #fff, 303px 1953px #fff,
		181px 23px #fff, 123px 1164px #fff, 239px 1542px #fff, 1985px 806px #fff, 1449px 1185px #fff, 1802px 529px #fff, 1762px 1933px #fff, 632px 1352px #fff,
		1247px 1154px #fff, 789px 1149px #fff, 1545px 1540px #fff, 1094px 1635px #fff, 61px 405px #fff, 1804px 663px #fff, 1280px 365px #fff, 1200px 1789px #fff,
		451px 611px #fff, 403px 761px #fff, 629px 1296px #fff, 1757px 1559px #fff, 1966px 733px #fff, 1688px 1408px #fff, 1214px 168px #fff, 828px 1396px #fff,
		689px 1159px #fff, 994px 303px #fff, 577px 114px #fff, 286px 124px #fff, 211px 1080px #fff, 625px 1199px #fff, 1876px 1562px #fff, 266px 657px #fff,
		102px 1248px #fff, 1526px 766px #fff, 193px 1128px #fff, 830px 17px #fff, 454px 1173px #fff, 1462px 490px #fff, 176px 1015px #fff, 401px 990px #fff,
		1017px 147px #fff, 1026px 947px #fff, 1899px 1394px #fff, 1936px 1252px #fff, 674px 970px #fff, 268px 793px #fff, 1015px 1574px #fff, 30px 494px #fff,
		969px 1613px #fff, 893px 878px #fff, 137px 1932px #fff, 416px 1453px #fff, 1963px 1830px #fff, 1387px 67px #fff, 1141px 187px #fff, 758px 787px #fff,
		555px 1980px #fff, 451px 1546px #fff, 1726px 1387px #fff, 806px 470px #fff, 1816px 479px #fff, 1197px 23px #fff, 1690px 512px #fff, 1638px 1351px #fff,
		211px 1680px #fff, 676px 129px #fff, 825px 690px #fff, 1920px 831px #fff, 933px 1859px #fff, 3px 366px #fff, 432px 1295px #fff, 1371px 871px #fff, 256px 1729px #fff,
		321px 517px #fff, 1256px 311px #fff, 831px 1566px #fff, 364px 1944px #fff, 227px 1584px #fff, 622px 1033px #fff, 1316px 1213px #fff, 40px 104px #fff,
		1406px 1859px #fff, 768px 811px #fff, 236px 487px #fff, 123px 706px #fff, 189px 1875px #fff, 88px 1314px #fff, 1039px 148px #fff, 1283px 1418px #fff,
		760px 18px #fff, 133px 261px #fff, 746px 757px #fff, 1502px 1900px #fff, 1610px 1962px #fff, 1430px 1570px #fff, 148px 1504px #fff, 1775px 306px #fff,
		472px 1179px #fff, 1134px 755px #fff, 1798px 1px #fff, 1080px 800px #fff, 200px 319px #fff, 127px 524px #fff, 912px 966px #fff, 55px 1514px #fff, 27px 773px #fff,
		1651px 1860px #fff, 73px 1205px #fff, 838px 1378px #fff, 1502px 60px #fff, 233px 1173px #fff, 1837px 575px #fff, 401px 809px #fff, 1393px 704px #fff,
		1230px 315px #fff, 453px 113px #fff, 496px 823px #fff, 1439px 1979px #fff, 1561px 723px #fff, 60px 1083px #fff, 1303px 917px #fff, 1452px 623px #fff,
		1635px 1061px #fff, 449px 1427px #fff, 831px 1014px #fff, 442px 814px #fff, 1344px 184px #fff, 1534px 278px #fff, 847px 1471px #fff, 1302px 312px #fff,
		605px 423px #fff, 1507px 1136px #fff, 631px 1509px #fff, 1543px 1548px #fff, 46px 545px #fff, 1217px 1988px #fff, 799px 1165px #fff, 1104px 1984px #fff,
		1240px 254px #fff, 1516px 1391px #fff, 966px 28px #fff, 854px 1458px #fff, 413px 1995px #fff, 1045px 557px #fff, 803px 1966px #fff, 958px 374px #fff,
		950px 774px #fff, 1639px 1436px #fff, 23px 974px #fff, 773px 1833px #fff, 825px 1194px #fff, 46px 144px #fff, 1803px 1116px #fff, 1788px 376px #fff,
		996px 1577px #fff, 797px 1093px #fff, 1948px 761px #fff, 449px 1269px #fff, 1633px 999px #fff, 1417px 293px #fff, 436px 1140px #fff, 658px 1294px #fff,
		1516px 1644px #fff, 1607px 1818px #fff, 164px 993px #fff, 1741px 1584px #fff, 1845px 287px #fff, 132px 1505px #fff, 54px 1105px #fff, 529px 1347px #fff,
		314px 1584px #fff, 1924px 268px #fff, 87px 1203px #fff, 28px 1552px #fff, 58px 614px #fff, 358px 1748px #fff, 1909px 1863px #fff, 1609px 548px #fff,
		1300px 1733px #fff, 1880px 1784px #fff, 1235px 345px #fff, 358px 1638px #fff, 706px 323px #fff, 1579px 1620px #fff, 1541px 1402px #fff, 1440px 266px #fff,
		285px 494px #fff, 1316px 1754px #fff, 349px 857px #fff, 469px 1407px #fff, 624px 1419px #fff, 1252px 1839px #fff, 1885px 744px #fff, 1585px 450px #fff,
		652px 711px #fff, 1662px 1642px #fff, 380px 1735px #fff, 1732px 1523px #fff, 409px 904px #fff, 268px 981px #fff, 1034px 368px #fff, 651px 4px #fff,
		1326px 400px #fff, 1690px 11px #fff, 1560px 761px #fff, 424px 235px #fff, 566px 491px #fff, 1358px 1469px #fff, 1550px 359px #fff, 931px 10px #fff, 306px 989px #fff,
		1px 447px #fff, 922px 152px #fff, 428px 1786px #fff, 1369px 635px #fff, 178px 13px #fff, 322px 1363px #fff, 431px 999px #fff, 1243px 632px #fff, 1513px 771px #fff,
		209px 1973px #fff, 267px 1780px #fff, 897px 498px #fff, 380px 134px #fff, 1169px 1476px #fff, 31px 1791px #fff, 1455px 416px #fff, 366px 1042px #fff,
		1901px 651px #fff, 1582px 1902px #fff, 1597px 1739px #fff, 367px 810px #fff, 1220px 288px #fff, 709px 1114px #fff, 1858px 94px #fff, 852px 165px #fff,
		490px 1701px #fff, 1100px 1058px #fff, 539px 885px #fff, 1670px 1267px #fff, 1346px 1765px #fff, 287px 912px #fff, 1006px 582px #fff, 534px 1893px #fff,
		316px 337px #fff, 1793px 434px #fff, 618px 166px #fff, 892px 706px #fff, 1805px 996px #fff, 1667px 566px #fff, 1969px 1129px #fff, 714px 1324px #fff,
		1546px 1775px #fff, 1629px 315px #fff, 135px 1833px #fff, 36px 1898px #fff, 396px 569px #fff, 395px 652px #fff, 1970px 1503px #fff, 947px 1485px #fff,
		1606px 1984px #fff, 38px 1923px #fff, 1488px 1999px #fff, 677px 738px #fff, 1551px 984px #fff, 1577px 510px #fff, 653px 1875px #fff, 1508px 375px #fff,
		83px 305px #fff, 301px 917px #fff, 167px 1644px #fff, 650px 823px #fff, 1994px 1302px #fff, 996px 1917px #fff, 1086px 630px #fff, 1643px 1210px #fff,
		1794px 885px #fff, 1924px 993px #fff, 367px 242px #fff, 550px 1237px #fff, 154px 130px #fff, 738px 1790px #fff, 1750px 1069px #fff, 96px 152px #fff,
		1854px 125px #fff, 1123px 30px #fff, 1016px 1347px #fff, 1696px 100px #fff, 835px 917px #fff, 649px 548px #fff, 1940px 1643px #fff, 377px 499px #fff,
		313px 348px #fff, 130px 1271px #fff, 1932px 1164px #fff, 266px 340px #fff, 1286px 1895px #fff, 1300px 497px #fff, 977px 879px #fff, 366px 57px #fff,
		1144px 1672px #fff, 1306px 1810px #fff, 571px 565px #fff, 773px 164px #fff, 1318px 240px #fff, 801px 23px #fff, 178px 195px #fff, 1041px 739px #fff,
		322px 323px #fff, 1186px 1928px #fff, 154px 969px #fff, 774px 1066px #fff, 1021px 532px #fff, 72px 1442px #fff, 124px 1022px #fff, 1445px 1300px #fff,
		1964px 404px #fff, 466px 55px #fff, 212px 370px #fff, 85px 967px #fff, 978px 715px #fff, 23px 72px #fff, 1024px 958px #fff, 463px 1041px #fff, 1008px 1228px #fff,
		1661px 1027px #fff, 626px 1633px #fff, 1368px 703px #fff, 10px 1158px #fff, 297px 599px #fff, 1307px 904px #fff, 1466px 558px #fff, 838px 92px #fff,
		903px 1104px #fff, 1149px 1986px #fff, 1287px 1579px #fff, 1129px 412px #fff, 869px 1824px #fff, 348px 97px #fff, 1282px 1282px #fff, 1234px 585px #fff,
		839px 2000px #fff, 1271px 1437px #fff, 190px 1444px #fff, 1588px 1717px #fff, 1623px 1941px #fff, 1697px 960px #fff, 354px 1751px #fff, 1086px 1163px #fff,
		495px 1943px #fff, 692px 561px #fff, 393px 281px #fff, 1439px 1914px #fff, 615px 1594px #fff, 95px 1258px #fff, 411px 3px #fff, 742px 562px #fff, 1643px 1106px #fff,
		616px 382px #fff, 674px 1568px #fff, 308px 143px #fff, 388px 1031px #fff, 873px 1029px #fff, 1859px 489px #fff, 1043px 490px #fff, 1916px 20px #fff,
		1304px 1859px #fff, 1986px 188px #fff, 736px 75px #fff, 805px 1974px #fff, 777px 1626px #fff, 384px 1859px #fff, 1857px 1012px #fff, 18px 687px #fff,
		1071px 914px #fff, 826px 240px #fff, 1263px 579px #fff, 1887px 1337px #fff, 317px 1022px #fff, 1275px 1093px #fff, 997px 1693px #fff, 1269px 296px #fff,
		1087px 1515px #fff, 446px 1428px #fff, 94px 583px #fff, 1318px 100px #fff, 1819px 914px #fff, 129px 1271px #fff, 497px 713px #fff, 1427px 1754px #fff,
		1417px 813px #fff, 93px 1168px #fff, 483px 1226px #fff, 287px 1919px #fff, 272px 1571px #fff, 1159px 1534px #fff, 1991px 1108px #fff, 1751px 1813px #fff,
		359px 1891px #fff, 308px 1728px #fff, 1735px 404px #fff, 456px 920px #fff, 1462px 1839px #fff, 119px 93px #fff, 1348px 1351px #fff, 404px 1248px #fff,
		880px 1501px #fff, 136px 197px #fff, 1729px 1253px #fff, 1634px 869px #fff, 839px 394px #fff, 1444px 1892px #fff, 347px 120px #fff, 316px 1685px #fff,
		1414px 1891px #fff, 1516px 975px #fff, 1297px 285px #fff, 1481px 1339px #fff, 747px 727px #fff, 1682px 1407px #fff, 1625px 1194px #fff, 1487px 1619px #fff,
		1531px 1522px #fff, 994px 919px #fff, 1029px 351px #fff, 504px 1803px #fff, 753px 1597px #fff, 467px 1233px #fff, 1014px 1903px #fff, 394px 678px #fff,
		830px 599px #fff, 1229px 441px #fff, 599px 257px #fff, 285px 1913px #fff, 801px 1428px #fff, 1552px 140px #fff, 120px 440px #fff, 689px 97px #fff, 896px 1861px #fff,
		374px 419px #fff, 1854px 1987px #fff, 467px 1823px #fff, 596px 70px #fff, 1709px 424px #fff, 1779px 389px #fff, 616px 50px #fff, 1635px 1440px #fff,
		278px 1727px #fff, 938px 617px #fff, 625px 363px #fff, 1259px 1781px #fff, 1928px 1445px #fff, 1061px 300px #fff, 387px 1715px #fff, 1570px 511px #fff,
		1987px 1226px #fff, 1907px 1045px #fff, 741px 1314px #fff, 714px 1067px #fff, 1315px 1474px #fff, 1139px 944px #fff, 1584px 1990px #fff, 1657px 125px #fff,
		1598px 846px #fff, 769px 1024px #fff, 1527px 454px #fff, 1637px 163px #fff, 53px 294px #fff, 1121px 1985px #fff, 1071px 1851px #fff, 445px 969px #fff,
		542px 546px #fff, 461px 351px #fff, 815px 669px #fff, 1705px 1124px #fff, 1542px 1907px #fff, 180px 329px #fff, 127px 906px #fff, 1420px 1374px #fff,
		626px 764px #fff, 763px 542px #fff, 1386px 589px #fff, 510px 1811px #fff, 446px 969px #fff, 207px 1561px #fff, 1139px 1089px #fff, 38px 119px #fff,
		1355px 1311px #fff, 530px 1102px #fff, 1908px 562px #fff, 634px 1264px #fff, 1818px 1830px #fff, 454px 1202px #fff, 802px 514px #fff, 290px 664px #fff,
		184px 1390px #fff, 188px 1369px #fff, 498px 1081px #fff, 1626px 1976px #fff, 1595px 1564px #fff, 1364px 1863px #fff, 1748px 133px #fff, 1702px 1833px #fff,
		1112px 771px #fff, 352px 346px #fff, 147px 1127px #fff, 1583px 96px #fff, 949px 1552px #fff, 1378px 1698px #fff, 344px 1718px #fff, 281px 1519px #fff,
		519px 477px #fff, 1834px 1423px #fff, 633px 1841px #fff, 904px 1108px #fff, 1943px 204px #fff, 206px 929px #fff, 1368px 940px #fff, 957px 184px #fff,
		1277px 1197px #fff, 1440px 976px #fff, 1095px 203px #fff, 734px 1659px #fff, 170px 18px #fff, 382px 165px #fff, 186px 600px #fff, 914px 110px #fff, 1613px 43px #fff,
		1250px 265px #fff, 1350px 1633px #fff, 1344px 38px #fff, 572px 109px #fff, 1890px 977px #fff, 369px 330px #fff, 1005px 1251px #fff, 1348px 96px #fff,
		1828px 1954px #fff, 1866px 1506px #fff, 118px 50px #fff, 1999px 1531px #fff, 865px 262px #fff, 1727px 1109px #fff, 1898px 760px #fff, 477px 1349px #fff,
		1780px 536px #fff, 454px 1464px #fff, 382px 1020px #fff, 1423px 802px #fff, 217px 1821px #fff, 142px 1545px #fff, 532px 81px #fff, 1182px 546px #fff,
		1478px 737px #fff, 1554px 1273px #fff, 1254px 1959px #fff, 1329px 1595px #fff, 1301px 1945px #fff, 1444px 586px #fff, 1760px 1882px #fff, 1810px 1548px #fff,
		1421px 1245px #fff, 819px 1637px #fff, 43px 1652px #fff, 1792px 81px #fff, 1842px 1395px #fff, 463px 785px #fff, 818px 1357px #fff, 78px 1274px #fff,
		509px 71px #fff, 1802px 253px #fff, 773px 184px #fff, 287px 709px #fff, 747px 708px #fff, 348px 425px #fff, 182px 281px #fff, 1652px 587px #fff, 391px 16px #fff,
		1419px 1688px #fff, 239px 842px #fff, 1662px 959px #fff, 242px 937px #fff, 75px 1966px #fff, 1891px 1910px #fff, 1477px 1791px #fff, 745px 799px #fff,
		995px 77px #fff, 465px 636px #fff, 491px 1869px #fff, 1875px 1088px #fff, 416px 1365px #fff, 30px 569px #fff, 1420px 99px #fff, 1804px 1574px #fff,
		392px 1961px #fff, 1402px 1384px #fff, 1030px 1121px #fff, 1541px 251px #fff, 1738px 964px #fff, 677px 728px #fff, 891px 1134px #fff, 1439px 77px #fff,
		1731px 1637px #fff, 83px 1606px #fff, 288px 515px #fff, 1964px 1092px #fff, 1149px 1495px #fff, 626px 453px #fff, 1706px 1534px #fff, 277px 814px #fff,
		1459px 1550px #fff, 1887px 1268px #fff, 637px 167px #fff, 1549px 927px #fff, 1978px 1029px #fff, 695px 1978px #fff, 1600px 1043px #fff, 755px 1134px #fff,
		1059px 307px #fff, 125px 70px #fff, 1522px 128px #fff, 817px 558px #fff, 30px 936px #fff, 1454px 889px #fff, 1012px 516px #fff, 790px 1380px #fff, 130px 1403px #fff,
		1566px 433px #fff, 237px 163px #fff, 21px 594px #fff, 1414px 1452px #fff, 149px 1837px #fff, 733px 947px #fff, 480px 769px #fff, 1316px 1115px #fff;
	border-radius: 50%;
	animation: animStar 150s linear infinite;
}
#stars:after {
	content: ' ';
	position: absolute;
	top: 2000px;
	width: 1px;
	height: 1px;
	background: transparent;
	box-shadow: 1288px 1798px #fff, 752px 597px #fff, 1155px 431px #fff, 802px 5px #fff, 1059px 1104px #fff, 1720px 865px #fff, 238px 1744px #fff, 770px 517px #fff,
		1076px 997px #fff, 407px 1365px #fff, 1605px 432px #fff, 1619px 1707px #fff, 371px 1673px #fff, 214px 1121px #fff, 67px 110px #fff, 637px 946px #fff,
		1527px 564px #fff, 225px 992px #fff, 1389px 1145px #fff, 1227px 301px #fff, 421px 748px #fff, 1991px 708px #fff, 1301px 1681px #fff, 958px 1299px #fff,
		1684px 1461px #fff, 1742px 1508px #fff, 673px 952px #fff, 144px 1344px #fff, 8px 126px #fff, 1696px 1881px #fff, 744px 318px #fff, 140px 819px #fff,
		1722px 1286px #fff, 538px 959px #fff, 954px 1088px #fff, 937px 886px #fff, 1519px 1912px #fff, 39px 368px #fff, 1815px 1166px #fff, 919px 1603px #fff,
		742px 1512px #fff, 1596px 1319px #fff, 1844px 869px #fff, 1090px 1549px #fff, 1554px 1130px #fff, 1597px 1229px #fff, 203px 472px #fff, 179px 202px #fff,
		1241px 775px #fff, 1611px 1861px #fff, 1031px 1778px #fff, 1201px 633px #fff, 767px 268px #fff, 28px 1945px #fff, 809px 1594px #fff, 171px 957px #fff,
		320px 71px #fff, 279px 1143px #fff, 657px 1174px #fff, 1632px 508px #fff, 1023px 657px #fff, 231px 926px #fff, 218px 1295px #fff, 890px 1347px #fff,
		454px 1668px #fff, 142px 721px #fff, 510px 1801px #fff, 1009px 1580px #fff, 944px 1100px #fff, 160px 1545px #fff, 621px 493px #fff, 1834px 482px #fff,
		1719px 1264px #fff, 1581px 361px #fff, 1967px 1751px #fff, 164px 1136px #fff, 822px 1825px #fff, 1331px 7px #fff, 979px 742px #fff, 1714px 316px #fff,
		1214px 1769px #fff, 187px 1254px #fff, 839px 1069px #fff, 105px 1001px #fff, 1149px 1671px #fff, 125px 1845px #fff, 1659px 335px #fff, 422px 262px #fff,
		1437px 311px #fff, 777px 1786px #fff, 1530px 1997px #fff, 260px 166px #fff, 1487px 1992px #fff, 1183px 1240px #fff, 5px 42px #fff, 212px 1474px #fff,
		1073px 1853px #fff, 361px 192px #fff, 578px 923px #fff, 1115px 834px #fff, 1248px 1299px #fff, 713px 1006px #fff, 367px 673px #fff, 1768px 1237px #fff,
		1469px 187px #fff, 1309px 1929px #fff, 1461px 671px #fff, 1501px 39px #fff, 715px 1629px #fff, 902px 754px #fff, 1024px 551px #fff, 1317px 1319px #fff,
		150px 119px #fff, 1563px 729px #fff, 1812px 1370px #fff, 608px 925px #fff, 1676px 397px #fff, 931px 1263px #fff, 855px 769px #fff, 778px 477px #fff,
		1348px 873px #fff, 1678px 162px #fff, 1390px 130px #fff, 209px 379px #fff, 373px 1059px #fff, 956px 1944px #fff, 350px 1876px #fff, 1145px 645px #fff,
		108px 1440px #fff, 223px 804px #fff, 519px 905px #fff, 685px 382px #fff, 874px 1441px #fff, 1453px 289px #fff, 416px 348px #fff, 1866px 1532px #fff,
		1388px 1160px #fff, 340px 1087px #fff, 550px 326px #fff, 949px 1147px #fff, 1433px 238px #fff, 1819px 410px #fff, 1283px 1395px #fff, 1282px 1267px #fff,
		1616px 830px #fff, 419px 1785px #fff, 1633px 1484px #fff, 148px 1779px #fff, 1536px 519px #fff, 1377px 695px #fff, 640px 831px #fff, 226px 1044px #fff,
		986px 763px #fff, 849px 1090px #fff, 587px 811px #fff, 684px 599px #fff, 50px 603px #fff, 170px 383px #fff, 214px 1262px #fff, 1295px 890px #fff, 453px 115px #fff,
		725px 1806px #fff, 1609px 704px #fff, 101px 1841px #fff, 943px 762px #fff, 34px 622px #fff, 1921px 1444px #fff, 789px 348px #fff, 962px 1214px #fff,
		1654px 1466px #fff, 823px 6px #fff, 1347px 1698px #fff, 563px 1467px #fff, 138px 1913px #fff, 38px 1995px #fff, 1994px 1987px #fff, 1183px 1600px #fff,
		1109px 238px #fff, 893px 795px #fff, 776px 1611px #fff, 1439px 1267px #fff, 680px 1543px #fff, 1014px 492px #fff, 946px 1870px #fff, 1117px 1422px #fff,
		182px 1853px #fff, 1264px 1296px #fff, 1551px 1035px #fff, 1977px 367px #fff, 1884px 277px #fff, 32px 208px #fff, 910px 1617px #fff, 1326px 1556px #fff,
		1074px 1288px #fff, 777px 1876px #fff, 147px 790px #fff, 1356px 317px #fff, 776px 1006px #fff, 453px 1170px #fff, 1441px 271px #fff, 1775px 898px #fff,
		1861px 324px #fff, 636px 788px #fff, 1360px 1312px #fff, 1421px 1096px #fff, 1403px 831px #fff, 1330px 1832px #fff, 219px 1594px #fff, 1402px 689px #fff,
		1183px 918px #fff, 361px 800px #fff, 1397px 173px #fff, 445px 828px #fff, 74px 193px #fff, 1991px 426px #fff, 1416px 1371px #fff, 542px 1080px #fff,
		1455px 98px #fff, 93px 896px #fff, 1166px 1417px #fff, 432px 147px #fff, 1329px 1724px #fff, 939px 801px #fff, 1163px 592px #fff, 1027px 1241px #fff,
		1742px 616px #fff, 893px 471px #fff, 923px 136px #fff, 790px 436px #fff, 417px 299px #fff, 261px 1923px #fff, 647px 1266px #fff, 1779px 1054px #fff,
		1917px 1072px #fff, 1535px 219px #fff, 119px 1522px #fff, 821px 1338px #fff, 1735px 1869px #fff, 1759px 1732px #fff, 276px 913px #fff, 387px 1587px #fff,
		1443px 461px #fff, 505px 1195px #fff, 404px 851px #fff, 1951px 32px #fff, 554px 1320px #fff, 754px 1009px #fff, 1978px 1555px #fff, 685px 1440px #fff,
		1960px 549px #fff, 1230px 1575px #fff, 1427px 654px #fff, 131px 746px #fff, 250px 452px #fff, 543px 834px #fff, 1500px 992px #fff, 1256px 1464px #fff,
		1645px 287px #fff, 1649px 76px #fff, 1659px 1233px #fff, 1240px 1086px #fff, 270px 344px #fff, 978px 1129px #fff, 1244px 1796px #fff, 1961px 997px #fff,
		270px 412px #fff, 394px 978px #fff, 1709px 442px #fff, 59px 481px #fff, 191px 1211px #fff, 1120px 1974px #fff, 229px 1835px #fff, 1089px 1197px #fff,
		1131px 1796px #fff, 1841px 565px #fff, 80px 1275px #fff, 989px 533px #fff, 1085px 1601px #fff, 163px 1806px #fff, 953px 1558px #fff, 1893px 1360px #fff,
		1224px 439px #fff, 892px 756px #fff, 320px 1561px #fff, 1368px 858px #fff, 1410px 1518px #fff, 231px 542px #fff, 677px 795px #fff, 4px 1963px #fff, 913px 884px #fff,
		698px 10px #fff, 43px 56px #fff, 580px 1690px #fff, 1390px 581px #fff, 918px 1669px #fff, 841px 163px #fff, 1455px 1962px #fff, 61px 589px #fff, 1392px 413px #fff,
		701px 825px #fff, 1830px 1944px #fff, 1511px 1224px #fff, 1905px 191px #fff, 680px 953px #fff, 1872px 1226px #fff, 56px 478px #fff, 1131px 1297px #fff,
		1102px 343px #fff, 1287px 145px #fff, 731px 1571px #fff, 1639px 1972px #fff, 34px 790px #fff, 1091px 1972px #fff, 1953px 1936px #fff, 437px 1009px #fff,
		1978px 1533px #fff, 484px 467px #fff, 718px 54px #fff, 73px 1131px #fff, 634px 1510px #fff, 325px 650px #fff, 1921px 659px #fff, 426px 553px #fff, 758px 525px #fff,
		23px 1322px #fff, 1142px 989px #fff, 53px 1509px #fff, 348px 1208px #fff, 421px 1517px #fff, 9px 1950px #fff, 370px 1070px #fff, 1758px 1848px #fff,
		1752px 1763px #fff, 188px 152px #fff, 325px 602px #fff, 1494px 1761px #fff, 1857px 810px #fff, 718px 1853px #fff, 477px 297px #fff, 1273px 1691px #fff,
		1808px 1735px #fff, 252px 768px #fff, 810px 1640px #fff, 1171px 456px #fff, 1921px 1766px #fff, 350px 26px #fff, 898px 585px #fff, 1662px 1220px #fff,
		1219px 769px #fff, 712px 568px #fff, 1523px 23px #fff, 101px 855px #fff, 1817px 1680px #fff, 426px 1271px #fff, 1245px 658px #fff, 1299px 772px #fff,
		1533px 213px #fff, 18px 1272px #fff, 763px 92px #fff, 572px 1453px #fff, 972px 1251px #fff, 1420px 1196px #fff, 905px 900px #fff, 1548px 680px #fff,
		121px 1157px #fff, 1454px 610px #fff, 1179px 1281px #fff, 1442px 926px #fff, 1089px 494px #fff, 273px 1085px #fff, 588px 1465px #fff, 1269px 1858px #fff,
		1440px 204px #fff, 1073px 1248px #fff, 1492px 730px #fff, 1476px 1821px #fff, 467px 118px #fff, 125px 141px #fff, 259px 232px #fff, 1982px 429px #fff,
		107px 1751px #fff, 649px 1433px #fff, 1554px 950px #fff, 1530px 847px #fff, 618px 1520px #fff, 573px 225px #fff, 1987px 881px #fff, 1263px 1630px #fff,
		473px 818px #fff, 1912px 1524px #fff, 1271px 47px #fff, 1968px 1212px #fff, 811px 1111px #fff, 1058px 1643px #fff, 1146px 494px #fff, 586px 474px #fff,
		1234px 122px #fff, 127px 17px #fff, 1252px 726px #fff, 24px 664px #fff, 1295px 359px #fff, 1104px 94px #fff, 468px 1215px #fff, 1418px 339px #fff, 650px 1400px #fff,
		1803px 1161px #fff, 1495px 1137px #fff, 1785px 1729px #fff, 1172px 890px #fff, 1253px 1517px #fff, 662px 348px #fff, 1573px 1297px #fff, 547px 1790px #fff,
		373px 1146px #fff, 387px 1654px #fff, 335px 380px #fff, 1908px 567px #fff, 976px 50px #fff, 659px 315px #fff, 916px 1960px #fff, 1918px 1261px #fff,
		692px 357px #fff, 420px 1903px #fff, 5px 355px #fff, 667px 107px #fff, 1865px 245px #fff, 116px 1743px #fff, 1831px 319px #fff, 1051px 1552px #fff,
		1630px 938px #fff, 668px 1221px #fff, 263px 719px #fff, 694px 1979px #fff, 1598px 1824px #fff, 1232px 1734px #fff, 1521px 1111px #fff, 790px 349px #fff,
		1050px 766px #fff, 1971px 858px #fff, 274px 1560px #fff, 17px 817px #fff, 555px 242px #fff, 1433px 1678px #fff, 251px 1817px #fff, 124px 966px #fff,
		727px 568px #fff, 1350px 912px #fff, 1790px 407px #fff, 1309px 785px #fff, 453px 923px #fff, 1661px 459px #fff, 1445px 231px #fff, 1863px 1247px #fff,
		1964px 1681px #fff, 1698px 1660px #fff, 78px 987px #fff, 126px 887px #fff, 186px 304px #fff, 1427px 173px #fff, 1686px 1708px #fff, 1949px 1491px #fff,
		1639px 1414px #fff, 1806px 1263px #fff, 431px 974px #fff, 1950px 1734px #fff, 952px 1731px #fff, 802px 1569px #fff, 1576px 444px #fff, 940px 1902px #fff,
		17px 126px #fff, 1973px 71px #fff, 1845px 603px #fff, 1475px 1522px #fff, 1857px 1344px #fff, 1754px 770px #fff, 213px 494px #fff, 447px 1675px #fff,
		944px 611px #fff, 1232px 887px #fff, 977px 114px #fff, 1474px 1149px #fff, 1767px 847px #fff, 311px 787px #fff, 482px 613px #fff, 986px 245px #fff, 245px 9px #fff,
		1458px 1443px #fff, 292px 1910px #fff, 124px 490px #fff, 117px 890px #fff, 1159px 653px #fff, 1324px 1098px #fff, 1242px 861px #fff, 439px 1005px #fff,
		245px 1003px #fff, 1966px 1542px #fff, 1291px 1744px #fff, 1995px 179px #fff, 1193px 1522px #fff, 755px 995px #fff, 1153px 568px #fff, 1915px 1237px #fff,
		973px 313px #fff, 1473px 331px #fff, 1628px 1546px #fff, 1584px 136px #fff, 260px 598px #fff, 413px 1560px #fff, 838px 294px #fff, 108px 855px #fff,
		1989px 366px #fff, 1402px 1003px #fff, 370px 116px #fff, 866px 89px #fff, 1516px 748px #fff, 1284px 835px #fff, 253px 1892px #fff, 484px 158px #fff,
		203px 1918px #fff, 108px 1823px #fff, 310px 1632px #fff, 488px 1127px #fff, 634px 1676px #fff, 827px 898px #fff, 925px 1294px #fff, 20px 267px #fff,
		1576px 898px #fff, 491px 990px #fff, 654px 907px #fff, 497px 82px #fff, 1299px 701px #fff, 1211px 1501px #fff, 16px 1988px #fff, 1489px 1251px #fff,
		922px 114px #fff, 1983px 812px #fff, 1182px 1128px #fff, 559px 361px #fff, 1623px 659px #fff, 1960px 1867px #fff, 197px 1834px #fff, 1627px 1827px #fff,
		1370px 702px #fff, 402px 1289px #fff, 839px 1952px #fff, 1112px 1208px #fff, 1861px 1349px #fff, 1281px 493px #fff, 1864px 1368px #fff, 1954px 418px #fff,
		383px 477px #fff, 315px 1995px #fff, 935px 1333px #fff, 1455px 1456px #fff, 1253px 1533px #fff, 107px 1749px #fff, 1065px 303px #fff, 850px 544px #fff,
		301px 1063px #fff, 1698px 113px #fff, 1271px 1116px #fff, 1233px 1271px #fff, 603px 1755px #fff, 744px 122px #fff, 637px 1050px #fff, 1403px 922px #fff,
		349px 845px #fff, 1796px 830px #fff, 1935px 1254px #fff, 998px 1787px #fff, 490px 1592px #fff, 424px 62px #fff, 1494px 852px #fff, 1446px 403px #fff,
		1502px 1585px #fff, 933px 472px #fff, 109px 1979px #fff, 1725px 1421px #fff, 662px 398px #fff, 858px 42px #fff, 471px 1864px #fff, 557px 8px #fff, 405px 1577px #fff,
		941px 1666px #fff, 1942px 399px #fff, 1711px 1424px #fff, 1963px 1299px #fff, 1800px 1228px #fff, 947px 796px #fff, 1999px 1867px #fff, 777px 1690px #fff,
		209px 523px #fff, 1576px 1133px #fff, 461px 1778px #fff, 695px 1971px #fff, 518px 359px #fff, 7px 1933px #fff, 796px 1888px #fff, 1460px 1353px #fff,
		474px 171px #fff, 1529px 900px #fff, 1684px 1177px #fff, 212px 660px #fff, 751px 662px #fff, 1290px 1576px #fff, 1972px 1481px #fff, 924px 621px #fff,
		1467px 1830px #fff, 241px 282px #fff, 169px 1248px #fff, 301px 1367px #fff, 824px 1201px #fff, 1892px 1422px #fff, 1340px 1252px #fff, 1907px 1951px #fff,
		117px 1059px #fff, 65px 372px #fff, 427px 1944px #fff, 665px 1475px #fff, 680px 1959px #fff, 186px 891px #fff, 1245px 442px #fff, 744px 1585px #fff,
		1222px 1744px #fff, 1755px 1066px #fff, 531px 1611px #fff, 1188px 102px #fff, 1149px 78px #fff, 1919px 1628px #fff, 1531px 1960px #fff, 1014px 185px #fff,
		650px 840px #fff, 750px 1731px #fff, 1115px 465px #fff, 1089px 1772px #fff, 81px 1501px #fff, 1359px 1477px #fff, 1084px 1819px #fff, 1642px 1589px #fff,
		521px 1204px #fff, 823px 1774px #fff, 1278px 1930px #fff, 1821px 1387px #fff, 1869px 1653px #fff, 1246px 653px #fff, 46px 92px #fff, 1790px 453px #fff,
		1349px 150px #fff, 463px 405px #fff, 1388px 1218px #fff, 1165px 305px #fff, 19px 1303px #fff, 778px 414px #fff, 251px 1201px #fff, 452px 799px #fff,
		835px 1238px #fff, 633px 1409px #fff, 794px 1856px #fff, 1686px 991px #fff, 1425px 1738px #fff, 1301px 577px #fff, 568px 357px #fff, 883px 773px #fff,
		1848px 835px #fff, 776px 685px #fff, 562px 1738px #fff, 434px 5px #fff, 1139px 670px #fff, 730px 1188px #fff, 599px 31px #fff, 1749px 431px #fff, 83px 1450px #fff,
		801px 1763px #fff, 780px 658px #fff, 1975px 578px #fff, 755px 1710px #fff, 356px 673px #fff, 762px 895px #fff, 1383px 440px #fff, 1596px 1002px #fff,
		1226px 1209px #fff, 202px 1257px #fff, 578px 1796px #fff, 154px 116px #fff, 1330px 1985px #fff, 871px 1197px #fff, 540px 1689px #fff, 618px 237px #fff,
		1940px 1886px #fff, 440px 1796px #fff, 1098px 1890px #fff, 605px 414px #fff, 279px 308px #fff, 1669px 1181px #fff, 381px 782px #fff, 925px 1559px #fff,
		1760px 519px #fff, 406px 1866px #fff, 470px 1041px #fff, 1759px 639px #fff, 1823px 1350px #fff, 2px 1622px #fff, 893px 537px #fff, 1230px 567px #fff,
		1359px 772px #fff, 1704px 1092px #fff, 862px 285px #fff, 935px 656px #fff, 1487px 148px #fff, 1793px 1708px #fff, 976px 1800px #fff, 1059px 1401px #fff,
		816px 1232px #fff, 311px 1504px #fff, 1885px 1854px #fff, 720px 1149px #fff, 1687px 918px #fff, 1871px 1592px #fff, 1560px 768px #fff, 885px 782px #fff,
		134px 1666px #fff, 540px 950px #fff, 1794px 648px #fff, 77px 1229px #fff, 1131px 1630px #fff, 1801px 1843px #fff, 1098px 151px #fff, 240px 269px #fff,
		1803px 1087px #fff, 442px 1773px #fff, 388px 575px #fff, 94px 414px #fff, 88px 813px #fff, 906px 1283px #fff, 776px 1479px #fff, 1748px 665px #fff,
		843px 1062px #fff, 188px 823px #fff, 1866px 1566px #fff, 88px 1972px #fff, 53px 1650px #fff, 1031px 1902px #fff, 1705px 673px #fff, 588px 853px #fff,
		578px 1169px #fff, 941px 1039px #fff, 1074px 597px #fff, 1607px 278px #fff, 185px 1099px #fff, 19px 252px #fff, 826px 1026px #fff, 1794px 1564px #fff,
		322px 281px #fff, 1214px 1580px #fff, 1192px 174px #fff, 1102px 1873px #fff, 1417px 1297px #fff, 1004px 672px #fff, 46px 1724px #fff, 178px 1124px #fff,
		592px 362px #fff, 191px 311px #fff, 1929px 860px #fff, 1193px 1181px #fff, 728px 461px #fff, 1252px 1092px #fff, 270px 1642px #fff, 205px 837px #fff,
		1744px 1809px #fff, 1045px 17px #fff, 1981px 976px #fff, 669px 1934px #fff, 884px 1331px #fff, 236px 874px #fff, 1702px 1523px #fff, 1028px 1793px #fff,
		296px 985px #fff, 1496px 1508px #fff, 399px 1461px #fff, 824px 482px #fff, 929px 1633px #fff, 1681px 663px #fff, 1995px 1776px #fff, 1571px 534px #fff,
		1326px 68px #fff, 879px 728px #fff, 1497px 1433px #fff, 352px 1452px #fff, 1502px 1448px #fff, 129px 1362px #fff, 1416px 1305px #fff, 790px 227px #fff,
		1446px 1904px #fff, 1366px 591px #fff, 752px 1185px #fff, 581px 1873px #fff, 1145px 1737px #fff, 955px 1006px #fff, 753px 1462px #fff, 1849px 1645px #fff,
		912px 207px #fff, 1387px 106px #fff, 995px 1582px #fff, 1110px 1781px #fff, 1309px 1380px #fff, 138px 773px #fff, 176px 1515px #fff, 1682px 1895px #fff,
		1711px 1557px #fff, 103px 556px #fff, 1059px 1727px #fff, 1674px 53px #fff, 106px 1143px #fff, 979px 1682px #fff, 1165px 872px #fff, 1693px 325px #fff,
		1401px 1913px #fff, 380px 613px #fff, 308px 1655px #fff, 1062px 674px #fff, 961px 134px #fff, 167px 1687px #fff, 121px 1297px #fff, 739px 1214px #fff,
		1926px 181px #fff, 574px 150px #fff, 263px 1893px #fff, 1281px 1566px #fff, 322px 80px #fff, 1469px 490px #fff, 606px 1809px #fff, 1582px 1781px #fff,
		1011px 502px #fff, 764px 1444px #fff, 772px 1101px #fff, 1520px 660px #fff, 1167px 814px #fff, 854px 1181px #fff, 772px 1969px #fff, 1116px 1468px #fff,
		1325px 1476px #fff, 659px 290px #fff, 1566px 327px #fff, 340px 487px #fff, 1980px 1323px #fff, 341px 839px #fff, 1947px 1632px #fff, 826px 198px #fff,
		1292px 94px #fff, 1971px 967px #fff, 1540px 1919px #fff, 108px 914px #fff, 479px 147px #fff, 1277px 1418px #fff, 345px 784px #fff, 1699px 854px #fff,
		610px 644px #fff, 945px 1105px #fff, 591px 260px #fff, 1915px 782px #fff, 325px 1875px #fff, 318px 667px #fff, 996px 656px #fff, 129px 1188px #fff,
		1428px 1941px #fff, 786px 81px #fff, 1293px 760px #fff, 1021px 1728px #fff, 746px 1970px #fff, 1823px 475px #fff, 1199px 269px #fff, 1130px 864px #fff,
		656px 502px #fff, 1089px 1073px #fff, 307px 349px #fff, 548px 232px #fff, 1660px 1099px #fff, 1341px 274px #fff, 1366px 206px #fff, 116px 472px #fff,
		1883px 496px #fff, 771px 776px #fff, 449px 598px #fff, 1954px 779px #fff, 1599px 1407px #fff, 1101px 1984px #fff, 1892px 165px #fff, 1928px 1942px #fff,
		869px 1172px #fff, 726px 966px #fff, 655px 574px #fff, 482px 20px #fff, 420px 1788px #fff, 216px 898px #fff, 465px 925px #fff, 267px 1886px #fff, 426px 103px #fff,
		234px 1903px #fff, 1026px 1425px #fff, 1349px 74px #fff, 1328px 1098px #fff, 126px 157px #fff, 1512px 286px #fff, 1776px 243px #fff, 240px 414px #fff,
		853px 47px #fff, 1115px 1385px #fff, 1174px 851px #fff, 843px 658px #fff, 1557px 1166px #fff, 1769px 1118px #fff, 1085px 1652px #fff, 1771px 1726px #fff,
		201px 1418px #fff, 1378px 569px #fff, 359px 181px #fff, 1566px 1142px #fff, 733px 1121px #fff, 823px 116px #fff, 1134px 1837px #fff, 860px 1230px #fff,
		1565px 354px #fff, 576px 8px #fff, 114px 1423px #fff, 747px 1399px #fff, 715px 367px #fff, 631px 587px #fff, 10px 603px #fff, 1364px 1846px #fff, 966px 292px #fff,
		1443px 839px #fff, 1138px 400px #fff, 443px 1963px #fff, 1234px 1311px #fff, 1833px 153px #fff, 1833px 554px #fff, 1411px 1357px #fff, 439px 1687px #fff,
		1898px 1570px #fff, 1557px 565px #fff, 1622px 76px #fff, 1360px 1675px #fff, 915px 537px #fff, 1558px 267px #fff, 1388px 149px #fff, 1490px 985px #fff,
		849px 1356px #fff, 1335px 256px #fff, 1038px 708px #fff, 861px 1913px #fff, 63px 1876px #fff, 1743px 1309px #fff, 1875px 1199px #fff, 388px 581px #fff,
		1079px 1906px #fff, 120px 661px #fff, 91px 1190px #fff, 874px 1387px #fff, 24px 1225px #fff, 20px 1894px #fff, 1229px 534px #fff, 187px 1138px #fff,
		447px 1532px #fff, 1641px 1681px #fff, 987px 925px #fff, 1378px 669px #fff, 10px 49px #fff, 1079px 1568px #fff, 500px 1565px #fff, 676px 1166px #fff,
		1169px 530px #fff, 1194px 1559px #fff, 1983px 1692px #fff, 895px 398px #fff, 933px 1124px #fff, 544px 330px #fff, 933px 1971px #fff, 1583px 462px #fff,
		405px 677px #fff, 1944px 644px #fff, 1762px 1159px #fff, 880px 366px #fff, 1570px 776px #fff, 1714px 537px #fff, 1794px 1585px #fff, 1663px 1083px #fff,
		1711px 253px #fff, 1524px 507px #fff, 1908px 853px #fff, 549px 1626px #fff, 1151px 646px #fff, 563px 1787px #fff, 557px 330px #fff, 1747px 1717px #fff,
		1818px 1610px #fff, 1028px 1153px #fff, 147px 1186px #fff, 1966px 242px #fff, 1815px 1547px #fff, 1848px 804px #fff, 1283px 1116px #fff, 1756px 891px #fff,
		705px 1355px #fff, 916px 1817px #fff, 110px 539px #fff, 1574px 689px #fff, 1580px 887px #fff, 1369px 284px #fff, 793px 59px #fff, 1483px 1837px #fff,
		1834px 451px #fff, 675px 1285px #fff, 1816px 644px #fff, 959px 902px #fff, 1940px 1889px #fff, 1994px 292px #fff, 760px 1278px #fff, 1556px 1722px #fff,
		353px 1023px #fff, 1165px 1886px #fff, 1183px 1410px #fff, 1856px 100px #fff, 1471px 1368px #fff, 821px 611px #fff, 1741px 355px #fff, 1715px 1545px #fff,
		1406px 419px #fff, 94px 547px #fff, 32px 66px #fff, 1536px 467px #fff, 1404px 579px #fff, 1869px 1095px #fff, 347px 1258px #fff, 1450px 1066px #fff,
		1128px 172px #fff, 469px 1860px #fff, 1923px 76px #fff, 47px 424px #fff, 1909px 1933px #fff, 1485px 1592px #fff, 1837px 1113px #fff, 1096px 131px #fff,
		172px 1942px #fff, 1081px 1515px #fff, 683px 1342px #fff, 520px 95px #fff, 409px 331px #fff, 1293px 519px #fff, 1660px 1878px #fff, 1145px 1498px #fff,
		371px 627px #fff, 1417px 374px #fff, 1556px 1719px #fff, 1812px 461px #fff, 216px 683px #fff, 492px 1088px #fff, 1949px 1229px #fff, 660px 1388px #fff,
		1315px 1460px #fff, 1335px 778px #fff, 1133px 95px #fff, 424px 1535px #fff, 586px 1491px #fff, 1102px 1259px #fff, 22px 1337px #fff, 303px 1953px #fff,
		181px 23px #fff, 123px 1164px #fff, 239px 1542px #fff, 1985px 806px #fff, 1449px 1185px #fff, 1802px 529px #fff, 1762px 1933px #fff, 632px 1352px #fff,
		1247px 1154px #fff, 789px 1149px #fff, 1545px 1540px #fff, 1094px 1635px #fff, 61px 405px #fff, 1804px 663px #fff, 1280px 365px #fff, 1200px 1789px #fff,
		451px 611px #fff, 403px 761px #fff, 629px 1296px #fff, 1757px 1559px #fff, 1966px 733px #fff, 1688px 1408px #fff, 1214px 168px #fff, 828px 1396px #fff,
		689px 1159px #fff, 994px 303px #fff, 577px 114px #fff, 286px 124px #fff, 211px 1080px #fff, 625px 1199px #fff, 1876px 1562px #fff, 266px 657px #fff,
		102px 1248px #fff, 1526px 766px #fff, 193px 1128px #fff, 830px 17px #fff, 454px 1173px #fff, 1462px 490px #fff, 176px 1015px #fff, 401px 990px #fff,
		1017px 147px #fff, 1026px 947px #fff, 1899px 1394px #fff, 1936px 1252px #fff, 674px 970px #fff, 268px 793px #fff, 1015px 1574px #fff, 30px 494px #fff,
		969px 1613px #fff, 893px 878px #fff, 137px 1932px #fff, 416px 1453px #fff, 1963px 1830px #fff, 1387px 67px #fff, 1141px 187px #fff, 758px 787px #fff,
		555px 1980px #fff, 451px 1546px #fff, 1726px 1387px #fff, 806px 470px #fff, 1816px 479px #fff, 1197px 23px #fff, 1690px 512px #fff, 1638px 1351px #fff,
		211px 1680px #fff, 676px 129px #fff, 825px 690px #fff, 1920px 831px #fff, 933px 1859px #fff, 3px 366px #fff, 432px 1295px #fff, 1371px 871px #fff, 256px 1729px #fff,
		321px 517px #fff, 1256px 311px #fff, 831px 1566px #fff, 364px 1944px #fff, 227px 1584px #fff, 622px 1033px #fff, 1316px 1213px #fff, 40px 104px #fff,
		1406px 1859px #fff, 768px 811px #fff, 236px 487px #fff, 123px 706px #fff, 189px 1875px #fff, 88px 1314px #fff, 1039px 148px #fff, 1283px 1418px #fff,
		760px 18px #fff, 133px 261px #fff, 746px 757px #fff, 1502px 1900px #fff, 1610px 1962px #fff, 1430px 1570px #fff, 148px 1504px #fff, 1775px 306px #fff,
		472px 1179px #fff, 1134px 755px #fff, 1798px 1px #fff, 1080px 800px #fff, 200px 319px #fff, 127px 524px #fff, 912px 966px #fff, 55px 1514px #fff, 27px 773px #fff,
		1651px 1860px #fff, 73px 1205px #fff, 838px 1378px #fff, 1502px 60px #fff, 233px 1173px #fff, 1837px 575px #fff, 401px 809px #fff, 1393px 704px #fff,
		1230px 315px #fff, 453px 113px #fff, 496px 823px #fff, 1439px 1979px #fff, 1561px 723px #fff, 60px 1083px #fff, 1303px 917px #fff, 1452px 623px #fff,
		1635px 1061px #fff, 449px 1427px #fff, 831px 1014px #fff, 442px 814px #fff, 1344px 184px #fff, 1534px 278px #fff, 847px 1471px #fff, 1302px 312px #fff,
		605px 423px #fff, 1507px 1136px #fff, 631px 1509px #fff, 1543px 1548px #fff, 46px 545px #fff, 1217px 1988px #fff, 799px 1165px #fff, 1104px 1984px #fff,
		1240px 254px #fff, 1516px 1391px #fff, 966px 28px #fff, 854px 1458px #fff, 413px 1995px #fff, 1045px 557px #fff, 803px 1966px #fff, 958px 374px #fff,
		950px 774px #fff, 1639px 1436px #fff, 23px 974px #fff, 773px 1833px #fff, 825px 1194px #fff, 46px 144px #fff, 1803px 1116px #fff, 1788px 376px #fff,
		996px 1577px #fff, 797px 1093px #fff, 1948px 761px #fff, 449px 1269px #fff, 1633px 999px #fff, 1417px 293px #fff, 436px 1140px #fff, 658px 1294px #fff,
		1516px 1644px #fff, 1607px 1818px #fff, 164px 993px #fff, 1741px 1584px #fff, 1845px 287px #fff, 132px 1505px #fff, 54px 1105px #fff, 529px 1347px #fff,
		314px 1584px #fff, 1924px 268px #fff, 87px 1203px #fff, 28px 1552px #fff, 58px 614px #fff, 358px 1748px #fff, 1909px 1863px #fff, 1609px 548px #fff,
		1300px 1733px #fff, 1880px 1784px #fff, 1235px 345px #fff, 358px 1638px #fff, 706px 323px #fff, 1579px 1620px #fff, 1541px 1402px #fff, 1440px 266px #fff,
		285px 494px #fff, 1316px 1754px #fff, 349px 857px #fff, 469px 1407px #fff, 624px 1419px #fff, 1252px 1839px #fff, 1885px 744px #fff, 1585px 450px #fff,
		652px 711px #fff, 1662px 1642px #fff, 380px 1735px #fff, 1732px 1523px #fff, 409px 904px #fff, 268px 981px #fff, 1034px 368px #fff, 651px 4px #fff,
		1326px 400px #fff, 1690px 11px #fff, 1560px 761px #fff, 424px 235px #fff, 566px 491px #fff, 1358px 1469px #fff, 1550px 359px #fff, 931px 10px #fff, 306px 989px #fff,
		1px 447px #fff, 922px 152px #fff, 428px 1786px #fff, 1369px 635px #fff, 178px 13px #fff, 322px 1363px #fff, 431px 999px #fff, 1243px 632px #fff, 1513px 771px #fff,
		209px 1973px #fff, 267px 1780px #fff, 897px 498px #fff, 380px 134px #fff, 1169px 1476px #fff, 31px 1791px #fff, 1455px 416px #fff, 366px 1042px #fff,
		1901px 651px #fff, 1582px 1902px #fff, 1597px 1739px #fff, 367px 810px #fff, 1220px 288px #fff, 709px 1114px #fff, 1858px 94px #fff, 852px 165px #fff,
		490px 1701px #fff, 1100px 1058px #fff, 539px 885px #fff, 1670px 1267px #fff, 1346px 1765px #fff, 287px 912px #fff, 1006px 582px #fff, 534px 1893px #fff,
		316px 337px #fff, 1793px 434px #fff, 618px 166px #fff, 892px 706px #fff, 1805px 996px #fff, 1667px 566px #fff, 1969px 1129px #fff, 714px 1324px #fff,
		1546px 1775px #fff, 1629px 315px #fff, 135px 1833px #fff, 36px 1898px #fff, 396px 569px #fff, 395px 652px #fff, 1970px 1503px #fff, 947px 1485px #fff,
		1606px 1984px #fff, 38px 1923px #fff, 1488px 1999px #fff, 677px 738px #fff, 1551px 984px #fff, 1577px 510px #fff, 653px 1875px #fff, 1508px 375px #fff,
		83px 305px #fff, 301px 917px #fff, 167px 1644px #fff, 650px 823px #fff, 1994px 1302px #fff, 996px 1917px #fff, 1086px 630px #fff, 1643px 1210px #fff,
		1794px 885px #fff, 1924px 993px #fff, 367px 242px #fff, 550px 1237px #fff, 154px 130px #fff, 738px 1790px #fff, 1750px 1069px #fff, 96px 152px #fff,
		1854px 125px #fff, 1123px 30px #fff, 1016px 1347px #fff, 1696px 100px #fff, 835px 917px #fff, 649px 548px #fff, 1940px 1643px #fff, 377px 499px #fff,
		313px 348px #fff, 130px 1271px #fff, 1932px 1164px #fff, 266px 340px #fff, 1286px 1895px #fff, 1300px 497px #fff, 977px 879px #fff, 366px 57px #fff,
		1144px 1672px #fff, 1306px 1810px #fff, 571px 565px #fff, 773px 164px #fff, 1318px 240px #fff, 801px 23px #fff, 178px 195px #fff, 1041px 739px #fff,
		322px 323px #fff, 1186px 1928px #fff, 154px 969px #fff, 774px 1066px #fff, 1021px 532px #fff, 72px 1442px #fff, 124px 1022px #fff, 1445px 1300px #fff,
		1964px 404px #fff, 466px 55px #fff, 212px 370px #fff, 85px 967px #fff, 978px 715px #fff, 23px 72px #fff, 1024px 958px #fff, 463px 1041px #fff, 1008px 1228px #fff,
		1661px 1027px #fff, 626px 1633px #fff, 1368px 703px #fff, 10px 1158px #fff, 297px 599px #fff, 1307px 904px #fff, 1466px 558px #fff, 838px 92px #fff,
		903px 1104px #fff, 1149px 1986px #fff, 1287px 1579px #fff, 1129px 412px #fff, 869px 1824px #fff, 348px 97px #fff, 1282px 1282px #fff, 1234px 585px #fff,
		839px 2000px #fff, 1271px 1437px #fff, 190px 1444px #fff, 1588px 1717px #fff, 1623px 1941px #fff, 1697px 960px #fff, 354px 1751px #fff, 1086px 1163px #fff,
		495px 1943px #fff, 692px 561px #fff, 393px 281px #fff, 1439px 1914px #fff, 615px 1594px #fff, 95px 1258px #fff, 411px 3px #fff, 742px 562px #fff, 1643px 1106px #fff,
		616px 382px #fff, 674px 1568px #fff, 308px 143px #fff, 388px 1031px #fff, 873px 1029px #fff, 1859px 489px #fff, 1043px 490px #fff, 1916px 20px #fff,
		1304px 1859px #fff, 1986px 188px #fff, 736px 75px #fff, 805px 1974px #fff, 777px 1626px #fff, 384px 1859px #fff, 1857px 1012px #fff, 18px 687px #fff,
		1071px 914px #fff, 826px 240px #fff, 1263px 579px #fff, 1887px 1337px #fff, 317px 1022px #fff, 1275px 1093px #fff, 997px 1693px #fff, 1269px 296px #fff,
		1087px 1515px #fff, 446px 1428px #fff, 94px 583px #fff, 1318px 100px #fff, 1819px 914px #fff, 129px 1271px #fff, 497px 713px #fff, 1427px 1754px #fff,
		1417px 813px #fff, 93px 1168px #fff, 483px 1226px #fff, 287px 1919px #fff, 272px 1571px #fff, 1159px 1534px #fff, 1991px 1108px #fff, 1751px 1813px #fff,
		359px 1891px #fff, 308px 1728px #fff, 1735px 404px #fff, 456px 920px #fff, 1462px 1839px #fff, 119px 93px #fff, 1348px 1351px #fff, 404px 1248px #fff,
		880px 1501px #fff, 136px 197px #fff, 1729px 1253px #fff, 1634px 869px #fff, 839px 394px #fff, 1444px 1892px #fff, 347px 120px #fff, 316px 1685px #fff,
		1414px 1891px #fff, 1516px 975px #fff, 1297px 285px #fff, 1481px 1339px #fff, 747px 727px #fff, 1682px 1407px #fff, 1625px 1194px #fff, 1487px 1619px #fff,
		1531px 1522px #fff, 994px 919px #fff, 1029px 351px #fff, 504px 1803px #fff, 753px 1597px #fff, 467px 1233px #fff, 1014px 1903px #fff, 394px 678px #fff,
		830px 599px #fff, 1229px 441px #fff, 599px 257px #fff, 285px 1913px #fff, 801px 1428px #fff, 1552px 140px #fff, 120px 440px #fff, 689px 97px #fff, 896px 1861px #fff,
		374px 419px #fff, 1854px 1987px #fff, 467px 1823px #fff, 596px 70px #fff, 1709px 424px #fff, 1779px 389px #fff, 616px 50px #fff, 1635px 1440px #fff,
		278px 1727px #fff, 938px 617px #fff, 625px 363px #fff, 1259px 1781px #fff, 1928px 1445px #fff, 1061px 300px #fff, 387px 1715px #fff, 1570px 511px #fff,
		1987px 1226px #fff, 1907px 1045px #fff, 741px 1314px #fff, 714px 1067px #fff, 1315px 1474px #fff, 1139px 944px #fff, 1584px 1990px #fff, 1657px 125px #fff,
		1598px 846px #fff, 769px 1024px #fff, 1527px 454px #fff, 1637px 163px #fff, 53px 294px #fff, 1121px 1985px #fff, 1071px 1851px #fff, 445px 969px #fff,
		542px 546px #fff, 461px 351px #fff, 815px 669px #fff, 1705px 1124px #fff, 1542px 1907px #fff, 180px 329px #fff, 127px 906px #fff, 1420px 1374px #fff,
		626px 764px #fff, 763px 542px #fff, 1386px 589px #fff, 510px 1811px #fff, 446px 969px #fff, 207px 1561px #fff, 1139px 1089px #fff, 38px 119px #fff,
		1355px 1311px #fff, 530px 1102px #fff, 1908px 562px #fff, 634px 1264px #fff, 1818px 1830px #fff, 454px 1202px #fff, 802px 514px #fff, 290px 664px #fff,
		184px 1390px #fff, 188px 1369px #fff, 498px 1081px #fff, 1626px 1976px #fff, 1595px 1564px #fff, 1364px 1863px #fff, 1748px 133px #fff, 1702px 1833px #fff,
		1112px 771px #fff, 352px 346px #fff, 147px 1127px #fff, 1583px 96px #fff, 949px 1552px #fff, 1378px 1698px #fff, 344px 1718px #fff, 281px 1519px #fff,
		519px 477px #fff, 1834px 1423px #fff, 633px 1841px #fff, 904px 1108px #fff, 1943px 204px #fff, 206px 929px #fff, 1368px 940px #fff, 957px 184px #fff,
		1277px 1197px #fff, 1440px 976px #fff, 1095px 203px #fff, 734px 1659px #fff, 170px 18px #fff, 382px 165px #fff, 186px 600px #fff, 914px 110px #fff, 1613px 43px #fff,
		1250px 265px #fff, 1350px 1633px #fff, 1344px 38px #fff, 572px 109px #fff, 1890px 977px #fff, 369px 330px #fff, 1005px 1251px #fff, 1348px 96px #fff,
		1828px 1954px #fff, 1866px 1506px #fff, 118px 50px #fff, 1999px 1531px #fff, 865px 262px #fff, 1727px 1109px #fff, 1898px 760px #fff, 477px 1349px #fff,
		1780px 536px #fff, 454px 1464px #fff, 382px 1020px #fff, 1423px 802px #fff, 217px 1821px #fff, 142px 1545px #fff, 532px 81px #fff, 1182px 546px #fff,
		1478px 737px #fff, 1554px 1273px #fff, 1254px 1959px #fff, 1329px 1595px #fff, 1301px 1945px #fff, 1444px 586px #fff, 1760px 1882px #fff, 1810px 1548px #fff,
		1421px 1245px #fff, 819px 1637px #fff, 43px 1652px #fff, 1792px 81px #fff, 1842px 1395px #fff, 463px 785px #fff, 818px 1357px #fff, 78px 1274px #fff,
		509px 71px #fff, 1802px 253px #fff, 773px 184px #fff, 287px 709px #fff, 747px 708px #fff, 348px 425px #fff, 182px 281px #fff, 1652px 587px #fff, 391px 16px #fff,
		1419px 1688px #fff, 239px 842px #fff, 1662px 959px #fff, 242px 937px #fff, 75px 1966px #fff, 1891px 1910px #fff, 1477px 1791px #fff, 745px 799px #fff,
		995px 77px #fff, 465px 636px #fff, 491px 1869px #fff, 1875px 1088px #fff, 416px 1365px #fff, 30px 569px #fff, 1420px 99px #fff, 1804px 1574px #fff,
		392px 1961px #fff, 1402px 1384px #fff, 1030px 1121px #fff, 1541px 251px #fff, 1738px 964px #fff, 677px 728px #fff, 891px 1134px #fff, 1439px 77px #fff,
		1731px 1637px #fff, 83px 1606px #fff, 288px 515px #fff, 1964px 1092px #fff, 1149px 1495px #fff, 626px 453px #fff, 1706px 1534px #fff, 277px 814px #fff,
		1459px 1550px #fff, 1887px 1268px #fff, 637px 167px #fff, 1549px 927px #fff, 1978px 1029px #fff, 695px 1978px #fff, 1600px 1043px #fff, 755px 1134px #fff,
		1059px 307px #fff, 125px 70px #fff, 1522px 128px #fff, 817px 558px #fff, 30px 936px #fff, 1454px 889px #fff, 1012px 516px #fff, 790px 1380px #fff, 130px 1403px #fff,
		1566px 433px #fff, 237px 163px #fff, 21px 594px #fff, 1414px 1452px #fff, 149px 1837px #fff, 733px 947px #fff, 480px 769px #fff, 1316px 1115px #fff;
}

#stars2 {
	width: 2px;
	height: 2px;
	background: transparent;
	box-shadow: 1134px 1304px #fff, 1819px 1805px #fff, 1261px 97px #fff, 977px 144px #fff, 1388px 1791px #fff, 894px 599px #fff, 711px 1664px #fff, 309px 750px #fff,
		354px 688px #fff, 1509px 911px #fff, 869px 123px #fff, 728px 580px #fff, 1386px 1874px #fff, 985px 1513px #fff, 1155px 1526px #fff, 1420px 1133px #fff,
		1811px 1982px #fff, 1550px 810px #fff, 905px 1133px #fff, 593px 469px #fff, 995px 1849px #fff, 954px 1px #fff, 1638px 1508px #fff, 1579px 1737px #fff,
		1055px 1397px #fff, 512px 1452px #fff, 678px 761px #fff, 135px 652px #fff, 1512px 1934px #fff, 1481px 782px #fff, 1003px 1906px #fff, 437px 1383px #fff,
		374px 1700px #fff, 1265px 596px #fff, 318px 304px #fff, 288px 1368px #fff, 1820px 348px #fff, 1040px 1223px #fff, 1991px 1905px #fff, 1651px 759px #fff,
		1891px 122px #fff, 939px 1107px #fff, 1813px 1931px #fff, 1795px 1554px #fff, 1951px 615px #fff, 214px 1049px #fff, 811px 374px #fff, 1827px 1348px #fff,
		1298px 986px #fff, 1743px 545px #fff, 257px 1927px #fff, 1858px 1287px #fff, 1467px 629px #fff, 1199px 1582px #fff, 1094px 19px #fff, 1045px 1737px #fff,
		1378px 1917px #fff, 471px 465px #fff, 909px 1013px #fff, 723px 137px #fff, 1990px 130px #fff, 900px 1994px #fff, 1742px 670px #fff, 351px 1194px #fff,
		895px 1268px #fff, 482px 20px #fff, 1288px 1939px #fff, 492px 766px #fff, 249px 50px #fff, 814px 620px #fff, 516px 475px #fff, 1970px 1706px #fff, 1714px 115px #fff,
		331px 567px #fff, 1026px 1173px #fff, 882px 1049px #fff, 1570px 354px #fff, 1519px 1519px #fff, 727px 847px #fff, 1536px 929px #fff, 1657px 439px #fff,
		1963px 830px #fff, 305px 1957px #fff, 1253px 1240px #fff, 1284px 1800px #fff, 648px 1737px #fff, 1521px 433px #fff, 548px 1782px #fff, 321px 889px #fff,
		167px 1565px #fff, 299px 1406px #fff, 673px 1612px #fff, 1361px 1345px #fff, 714px 1276px #fff, 1871px 1248px #fff, 923px 1942px #fff, 1280px 1758px #fff,
		1422px 1291px #fff, 972px 869px #fff, 953px 1666px #fff, 704px 1319px #fff, 1974px 1229px #fff, 1971px 1299px #fff, 1077px 1006px #fff, 1687px 520px #fff,
		34px 471px #fff, 472px 1162px #fff, 1059px 1384px #fff, 1942px 927px #fff, 1294px 1176px #fff, 1489px 669px #fff, 486px 116px #fff, 1295px 718px #fff,
		1981px 201px #fff, 116px 142px #fff, 389px 1837px #fff, 473px 1018px #fff, 1075px 218px #fff, 556px 512px #fff, 1553px 1969px #fff, 1110px 1338px #fff,
		403px 1300px #fff, 908px 359px #fff, 1324px 1320px #fff, 706px 221px #fff, 1423px 238px #fff, 54px 831px #fff, 237px 609px #fff, 824px 137px #fff, 142px 821px #fff,
		185px 886px #fff, 1591px 1520px #fff, 1267px 1141px #fff, 1928px 1439px #fff, 1000px 1892px #fff, 1512px 515px #fff, 1383px 1477px #fff, 1656px 948px #fff,
		271px 1282px #fff, 1552px 16px #fff, 1761px 68px #fff, 1859px 1903px #fff, 396px 799px #fff, 1314px 946px #fff, 140px 1371px #fff, 624px 566px #fff,
		1531px 301px #fff, 1283px 1840px #fff, 408px 1653px #fff, 1444px 1109px #fff, 1292px 1309px #fff, 555px 898px #fff, 736px 1668px #fff, 968px 929px #fff,
		249px 1518px #fff, 355px 1339px #fff, 1747px 957px #fff, 1167px 466px #fff, 560px 1297px #fff, 1678px 1502px #fff, 542px 496px #fff, 1392px 1838px #fff,
		586px 465px #fff, 395px 1437px #fff, 843px 1969px #fff, 1201px 480px #fff, 1109px 553px #fff, 269px 1866px #fff, 139px 121px #fff, 1066px 572px #fff,
		832px 1118px #fff, 156px 1187px #fff, 173px 1363px #fff, 204px 1130px #fff, 53px 1509px #fff, 1020px 1537px #fff, 857px 11px #fff, 684px 306px #fff,
		999px 1493px #fff, 992px 1017px #fff, 47px 569px #fff, 587px 1984px #fff, 397px 713px #fff, 969px 1025px #fff, 171px 1705px #fff, 1115px 882px #fff,
		1399px 749px #fff, 1359px 158px #fff, 189px 226px #fff, 1304px 320px #fff, 1069px 270px #fff, 579px 1749px #fff, 751px 1031px #fff, 925px 1622px #fff,
		1233px 652px #fff, 47px 634px #fff, 975px 778px #fff, 1317px 1888px #fff, 307px 463px #fff, 1913px 1381px #fff, 1141px 739px #fff, 163px 497px #fff,
		1586px 274px #fff, 234px 40px #fff, 673px 669px #fff, 503px 1455px #fff, 1586px 155px #fff, 1169px 713px #fff, 1993px 1994px #fff, 1861px 488px #fff,
		1464px 1030px #fff, 1030px 1597px #fff, 1262px 841px #fff, 1790px 1126px #fff, 1101px 1946px #fff, 1024px 1900px #fff, 275px 848px #fff, 1610px 1287px #fff,
		918px 1687px #fff, 1919px 1314px #fff, 1444px 863px #fff, 304px 542px #fff, 991px 1200px #fff, 995px 441px #fff, 805px 1410px #fff, 1189px 1045px #fff,
		986px 64px #fff, 529px 1366px #fff, 1322px 998px #fff, 1476px 57px #fff, 67px 418px #fff, 1789px 223px #fff, 1589px 1077px #fff, 1679px 442px #fff,
		297px 1518px #fff, 896px 570px #fff, 1580px 1853px #fff, 1108px 1056px #fff, 815px 889px #fff, 1806px 1532px #fff, 1469px 1497px #fff, 345px 714px #fff,
		1063px 205px #fff, 1864px 1231px #fff, 497px 1562px #fff, 1294px 236px #fff, 1623px 736px #fff, 399px 662px #fff, 1793px 1112px #fff, 796px 1211px #fff,
		73px 859px #fff, 772px 454px #fff, 185px 148px #fff, 839px 1271px #fff, 1524px 660px #fff, 583px 126px #fff, 65px 1966px #fff, 1975px 1847px #fff,
		1344px 1268px #fff, 495px 562px #fff, 766px 480px #fff, 1674px 638px #fff, 10px 263px #fff, 643px 1846px #fff, 1954px 1725px #fff, 1041px 958px #fff,
		502px 278px #fff, 934px 60px #fff, 805px 479px #fff, 1307px 277px #fff, 34px 794px #fff, 65px 135px #fff, 766px 631px #fff, 181px 1151px #fff, 1755px 862px #fff,
		800px 95px #fff, 1061px 1922px #fff, 100px 1691px #fff, 289px 1984px #fff, 310px 1086px #fff, 647px 982px #fff, 514px 825px #fff, 650px 1910px #fff,
		742px 1116px #fff, 1476px 299px #fff, 938px 986px #fff, 328px 654px #fff, 1592px 831px #fff, 122px 375px #fff, 1723px 1651px #fff, 412px 1424px #fff,
		340px 1209px #fff, 1219px 719px #fff, 1290px 380px #fff, 328px 1170px #fff, 785px 570px #fff, 375px 1334px #fff, 1414px 574px #fff, 1902px 1662px #fff,
		914px 931px #fff, 389px 417px #fff, 322px 862px #fff, 48px 1174px #fff, 938px 636px #fff, 164px 740px #fff, 416px 907px #fff, 929px 771px #fff, 1041px 970px #fff,
		1717px 1848px #fff, 13px 1502px #fff, 1068px 498px #fff, 74px 546px #fff, 486px 1727px #fff, 1746px 1626px #fff, 828px 747px #fff, 1848px 1996px #fff,
		1003px 1293px #fff, 551px 1275px #fff, 652px 854px #fff, 1501px 1752px #fff, 203px 445px #fff, 1763px 926px #fff, 1500px 520px #fff, 1179px 933px #fff,
		1312px 1798px #fff, 1946px 233px #fff, 630px 1717px #fff, 364px 502px #fff, 1900px 830px #fff, 659px 82px #fff, 13px 823px #fff, 448px 1082px #fff,
		1655px 594px #fff, 642px 1998px #fff, 231px 1646px #fff, 369px 106px #fff, 172px 139px #fff, 1382px 1792px #fff, 134px 1993px #fff, 795px 681px #fff,
		166px 1080px #fff, 351px 1630px #fff, 1663px 1874px #fff, 5px 382px #fff, 483px 1671px #fff, 95px 985px #fff, 591px 453px #fff, 1844px 527px #fff, 1980px 61px #fff,
		755px 319px #fff, 1977px 828px #fff, 281px 1766px #fff, 1035px 828px #fff, 1350px 566px #fff, 297px 227px #fff, 572px 340px #fff, 657px 1816px #fff,
		293px 1753px #fff, 1893px 1700px #fff, 1699px 1136px #fff, 1794px 1015px #fff, 749px 1550px #fff, 548px 333px #fff, 494px 1878px #fff, 946px 941px #fff,
		1804px 1205px #fff, 1693px 766px #fff, 520px 1543px #fff, 63px 541px #fff, 1099px 40px #fff, 1520px 861px #fff, 1512px 534px #fff, 1288px 706px #fff,
		1636px 1813px #fff, 1572px 1295px #fff, 1901px 835px #fff, 1843px 930px #fff, 1228px 1268px #fff, 1049px 1861px #fff, 629px 1668px #fff, 1554px 1817px #fff,
		1767px 1007px #fff, 1572px 1945px #fff, 891px 1584px #fff, 1915px 632px #fff, 1679px 1263px #fff, 1834px 806px #fff, 1061px 1403px #fff, 1430px 1693px #fff,
		1312px 616px #fff, 1747px 52px #fff, 649px 1036px #fff, 721px 1434px #fff, 989px 744px #fff, 138px 366px #fff, 1566px 759px #fff, 1290px 1350px #fff,
		1739px 665px #fff, 1550px 179px #fff, 212px 1941px #fff, 163px 496px #fff, 1930px 594px #fff, 990px 1543px #fff, 1847px 189px #fff, 220px 1936px #fff,
		274px 982px #fff, 111px 805px #fff, 848px 354px #fff, 585px 1161px #fff, 1879px 777px #fff, 1643px 729px #fff, 1037px 226px #fff, 263px 714px #fff,
		1145px 1957px #fff, 1431px 1574px #fff, 817px 1609px #fff, 1267px 1434px #fff, 289px 39px #fff, 831px 125px #fff, 1765px 84px #fff, 225px 836px #fff,
		1537px 10px #fff, 1076px 1067px #fff, 122px 580px #fff, 1080px 1102px #fff, 1786px 1020px #fff, 119px 1845px #fff, 1054px 80px #fff, 1164px 158px #fff,
		1916px 82px #fff, 1490px 1735px #fff, 955px 468px #fff, 865px 22px #fff, 49px 1046px #fff, 1289px 725px #fff, 651px 522px #fff, 244px 1985px #fff,
		1944px 1837px #fff, 1688px 503px #fff, 1093px 1769px #fff, 1439px 1584px #fff, 1266px 1868px #fff, 1491px 789px #fff, 140px 778px #fff, 1216px 994px #fff,
		1851px 1326px #fff, 823px 660px #fff, 430px 1336px #fff, 133px 1960px #fff, 1434px 687px #fff, 203px 1753px #fff, 405px 1301px #fff, 396px 512px #fff,
		1013px 863px #fff, 217px 163px #fff, 1097px 969px #fff, 1887px 199px #fff, 591px 1551px #fff, 1736px 56px #fff, 310px 1861px #fff, 1612px 1564px #fff,
		614px 5px #fff, 1298px 1876px #fff, 313px 1951px #fff, 793px 1147px #fff, 869px 1502px #fff, 747px 1147px #fff, 1980px 1463px #fff, 1323px 708px #fff,
		1848px 1184px #fff, 1133px 1650px #fff, 1688px 1393px #fff, 1428px 1722px #fff, 1333px 620px #fff, 1879px 1545px #fff, 1600px 1158px #fff, 1739px 1081px #fff,
		132px 364px #fff, 1114px 137px #fff, 165px 1079px #fff, 1945px 178px #fff, 250px 616px #fff, 861px 1342px #fff, 1464px 191px #fff, 716px 1334px #fff,
		1905px 459px #fff, 190px 595px #fff, 1239px 1100px #fff, 1119px 403px #fff, 352px 834px #fff, 442px 1461px #fff, 1849px 1588px #fff, 1278px 335px #fff,
		25px 968px #fff, 191px 562px #fff, 1999px 1719px #fff, 1876px 752px #fff, 1673px 133px #fff, 1029px 419px #fff, 691px 1424px #fff;
	border-radius: 50%;
	animation: animStar 100s linear infinite;
}
#stars2:after {
	content: ' ';
	position: absolute;
	top: 2000px;
	width: 2px;
	height: 2px;
	background: transparent;
	box-shadow: 1134px 1304px #fff, 1819px 1805px #fff, 1261px 97px #fff, 977px 144px #fff, 1388px 1791px #fff, 894px 599px #fff, 711px 1664px #fff, 309px 750px #fff,
		354px 688px #fff, 1509px 911px #fff, 869px 123px #fff, 728px 580px #fff, 1386px 1874px #fff, 985px 1513px #fff, 1155px 1526px #fff, 1420px 1133px #fff,
		1811px 1982px #fff, 1550px 810px #fff, 905px 1133px #fff, 593px 469px #fff, 995px 1849px #fff, 954px 1px #fff, 1638px 1508px #fff, 1579px 1737px #fff,
		1055px 1397px #fff, 512px 1452px #fff, 678px 761px #fff, 135px 652px #fff, 1512px 1934px #fff, 1481px 782px #fff, 1003px 1906px #fff, 437px 1383px #fff,
		374px 1700px #fff, 1265px 596px #fff, 318px 304px #fff, 288px 1368px #fff, 1820px 348px #fff, 1040px 1223px #fff, 1991px 1905px #fff, 1651px 759px #fff,
		1891px 122px #fff, 939px 1107px #fff, 1813px 1931px #fff, 1795px 1554px #fff, 1951px 615px #fff, 214px 1049px #fff, 811px 374px #fff, 1827px 1348px #fff,
		1298px 986px #fff, 1743px 545px #fff, 257px 1927px #fff, 1858px 1287px #fff, 1467px 629px #fff, 1199px 1582px #fff, 1094px 19px #fff, 1045px 1737px #fff,
		1378px 1917px #fff, 471px 465px #fff, 909px 1013px #fff, 723px 137px #fff, 1990px 130px #fff, 900px 1994px #fff, 1742px 670px #fff, 351px 1194px #fff,
		895px 1268px #fff, 482px 20px #fff, 1288px 1939px #fff, 492px 766px #fff, 249px 50px #fff, 814px 620px #fff, 516px 475px #fff, 1970px 1706px #fff, 1714px 115px #fff,
		331px 567px #fff, 1026px 1173px #fff, 882px 1049px #fff, 1570px 354px #fff, 1519px 1519px #fff, 727px 847px #fff, 1536px 929px #fff, 1657px 439px #fff,
		1963px 830px #fff, 305px 1957px #fff, 1253px 1240px #fff, 1284px 1800px #fff, 648px 1737px #fff, 1521px 433px #fff, 548px 1782px #fff, 321px 889px #fff,
		167px 1565px #fff, 299px 1406px #fff, 673px 1612px #fff, 1361px 1345px #fff, 714px 1276px #fff, 1871px 1248px #fff, 923px 1942px #fff, 1280px 1758px #fff,
		1422px 1291px #fff, 972px 869px #fff, 953px 1666px #fff, 704px 1319px #fff, 1974px 1229px #fff, 1971px 1299px #fff, 1077px 1006px #fff, 1687px 520px #fff,
		34px 471px #fff, 472px 1162px #fff, 1059px 1384px #fff, 1942px 927px #fff, 1294px 1176px #fff, 1489px 669px #fff, 486px 116px #fff, 1295px 718px #fff,
		1981px 201px #fff, 116px 142px #fff, 389px 1837px #fff, 473px 1018px #fff, 1075px 218px #fff, 556px 512px #fff, 1553px 1969px #fff, 1110px 1338px #fff,
		403px 1300px #fff, 908px 359px #fff, 1324px 1320px #fff, 706px 221px #fff, 1423px 238px #fff, 54px 831px #fff, 237px 609px #fff, 824px 137px #fff, 142px 821px #fff,
		185px 886px #fff, 1591px 1520px #fff, 1267px 1141px #fff, 1928px 1439px #fff, 1000px 1892px #fff, 1512px 515px #fff, 1383px 1477px #fff, 1656px 948px #fff,
		271px 1282px #fff, 1552px 16px #fff, 1761px 68px #fff, 1859px 1903px #fff, 396px 799px #fff, 1314px 946px #fff, 140px 1371px #fff, 624px 566px #fff,
		1531px 301px #fff, 1283px 1840px #fff, 408px 1653px #fff, 1444px 1109px #fff, 1292px 1309px #fff, 555px 898px #fff, 736px 1668px #fff, 968px 929px #fff,
		249px 1518px #fff, 355px 1339px #fff, 1747px 957px #fff, 1167px 466px #fff, 560px 1297px #fff, 1678px 1502px #fff, 542px 496px #fff, 1392px 1838px #fff,
		586px 465px #fff, 395px 1437px #fff, 843px 1969px #fff, 1201px 480px #fff, 1109px 553px #fff, 269px 1866px #fff, 139px 121px #fff, 1066px 572px #fff,
		832px 1118px #fff, 156px 1187px #fff, 173px 1363px #fff, 204px 1130px #fff, 53px 1509px #fff, 1020px 1537px #fff, 857px 11px #fff, 684px 306px #fff,
		999px 1493px #fff, 992px 1017px #fff, 47px 569px #fff, 587px 1984px #fff, 397px 713px #fff, 969px 1025px #fff, 171px 1705px #fff, 1115px 882px #fff,
		1399px 749px #fff, 1359px 158px #fff, 189px 226px #fff, 1304px 320px #fff, 1069px 270px #fff, 579px 1749px #fff, 751px 1031px #fff, 925px 1622px #fff,
		1233px 652px #fff, 47px 634px #fff, 975px 778px #fff, 1317px 1888px #fff, 307px 463px #fff, 1913px 1381px #fff, 1141px 739px #fff, 163px 497px #fff,
		1586px 274px #fff, 234px 40px #fff, 673px 669px #fff, 503px 1455px #fff, 1586px 155px #fff, 1169px 713px #fff, 1993px 1994px #fff, 1861px 488px #fff,
		1464px 1030px #fff, 1030px 1597px #fff, 1262px 841px #fff, 1790px 1126px #fff, 1101px 1946px #fff, 1024px 1900px #fff, 275px 848px #fff, 1610px 1287px #fff,
		918px 1687px #fff, 1919px 1314px #fff, 1444px 863px #fff, 304px 542px #fff, 991px 1200px #fff, 995px 441px #fff, 805px 1410px #fff, 1189px 1045px #fff,
		986px 64px #fff, 529px 1366px #fff, 1322px 998px #fff, 1476px 57px #fff, 67px 418px #fff, 1789px 223px #fff, 1589px 1077px #fff, 1679px 442px #fff,
		297px 1518px #fff, 896px 570px #fff, 1580px 1853px #fff, 1108px 1056px #fff, 815px 889px #fff, 1806px 1532px #fff, 1469px 1497px #fff, 345px 714px #fff,
		1063px 205px #fff, 1864px 1231px #fff, 497px 1562px #fff, 1294px 236px #fff, 1623px 736px #fff, 399px 662px #fff, 1793px 1112px #fff, 796px 1211px #fff,
		73px 859px #fff, 772px 454px #fff, 185px 148px #fff, 839px 1271px #fff, 1524px 660px #fff, 583px 126px #fff, 65px 1966px #fff, 1975px 1847px #fff,
		1344px 1268px #fff, 495px 562px #fff, 766px 480px #fff, 1674px 638px #fff, 10px 263px #fff, 643px 1846px #fff, 1954px 1725px #fff, 1041px 958px #fff,
		502px 278px #fff, 934px 60px #fff, 805px 479px #fff, 1307px 277px #fff, 34px 794px #fff, 65px 135px #fff, 766px 631px #fff, 181px 1151px #fff, 1755px 862px #fff,
		800px 95px #fff, 1061px 1922px #fff, 100px 1691px #fff, 289px 1984px #fff, 310px 1086px #fff, 647px 982px #fff, 514px 825px #fff, 650px 1910px #fff,
		742px 1116px #fff, 1476px 299px #fff, 938px 986px #fff, 328px 654px #fff, 1592px 831px #fff, 122px 375px #fff, 1723px 1651px #fff, 412px 1424px #fff,
		340px 1209px #fff, 1219px 719px #fff, 1290px 380px #fff, 328px 1170px #fff, 785px 570px #fff, 375px 1334px #fff, 1414px 574px #fff, 1902px 1662px #fff,
		914px 931px #fff, 389px 417px #fff, 322px 862px #fff, 48px 1174px #fff, 938px 636px #fff, 164px 740px #fff, 416px 907px #fff, 929px 771px #fff, 1041px 970px #fff,
		1717px 1848px #fff, 13px 1502px #fff, 1068px 498px #fff, 74px 546px #fff, 486px 1727px #fff, 1746px 1626px #fff, 828px 747px #fff, 1848px 1996px #fff,
		1003px 1293px #fff, 551px 1275px #fff, 652px 854px #fff, 1501px 1752px #fff, 203px 445px #fff, 1763px 926px #fff, 1500px 520px #fff, 1179px 933px #fff,
		1312px 1798px #fff, 1946px 233px #fff, 630px 1717px #fff, 364px 502px #fff, 1900px 830px #fff, 659px 82px #fff, 13px 823px #fff, 448px 1082px #fff,
		1655px 594px #fff, 642px 1998px #fff, 231px 1646px #fff, 369px 106px #fff, 172px 139px #fff, 1382px 1792px #fff, 134px 1993px #fff, 795px 681px #fff,
		166px 1080px #fff, 351px 1630px #fff, 1663px 1874px #fff, 5px 382px #fff, 483px 1671px #fff, 95px 985px #fff, 591px 453px #fff, 1844px 527px #fff, 1980px 61px #fff,
		755px 319px #fff, 1977px 828px #fff, 281px 1766px #fff, 1035px 828px #fff, 1350px 566px #fff, 297px 227px #fff, 572px 340px #fff, 657px 1816px #fff,
		293px 1753px #fff, 1893px 1700px #fff, 1699px 1136px #fff, 1794px 1015px #fff, 749px 1550px #fff, 548px 333px #fff, 494px 1878px #fff, 946px 941px #fff,
		1804px 1205px #fff, 1693px 766px #fff, 520px 1543px #fff, 63px 541px #fff, 1099px 40px #fff, 1520px 861px #fff, 1512px 534px #fff, 1288px 706px #fff,
		1636px 1813px #fff, 1572px 1295px #fff, 1901px 835px #fff, 1843px 930px #fff, 1228px 1268px #fff, 1049px 1861px #fff, 629px 1668px #fff, 1554px 1817px #fff,
		1767px 1007px #fff, 1572px 1945px #fff, 891px 1584px #fff, 1915px 632px #fff, 1679px 1263px #fff, 1834px 806px #fff, 1061px 1403px #fff, 1430px 1693px #fff,
		1312px 616px #fff, 1747px 52px #fff, 649px 1036px #fff, 721px 1434px #fff, 989px 744px #fff, 138px 366px #fff, 1566px 759px #fff, 1290px 1350px #fff,
		1739px 665px #fff, 1550px 179px #fff, 212px 1941px #fff, 163px 496px #fff, 1930px 594px #fff, 990px 1543px #fff, 1847px 189px #fff, 220px 1936px #fff,
		274px 982px #fff, 111px 805px #fff, 848px 354px #fff, 585px 1161px #fff, 1879px 777px #fff, 1643px 729px #fff, 1037px 226px #fff, 263px 714px #fff,
		1145px 1957px #fff, 1431px 1574px #fff, 817px 1609px #fff, 1267px 1434px #fff, 289px 39px #fff, 831px 125px #fff, 1765px 84px #fff, 225px 836px #fff,
		1537px 10px #fff, 1076px 1067px #fff, 122px 580px #fff, 1080px 1102px #fff, 1786px 1020px #fff, 119px 1845px #fff, 1054px 80px #fff, 1164px 158px #fff,
		1916px 82px #fff, 1490px 1735px #fff, 955px 468px #fff, 865px 22px #fff, 49px 1046px #fff, 1289px 725px #fff, 651px 522px #fff, 244px 1985px #fff,
		1944px 1837px #fff, 1688px 503px #fff, 1093px 1769px #fff, 1439px 1584px #fff, 1266px 1868px #fff, 1491px 789px #fff, 140px 778px #fff, 1216px 994px #fff,
		1851px 1326px #fff, 823px 660px #fff, 430px 1336px #fff, 133px 1960px #fff, 1434px 687px #fff, 203px 1753px #fff, 405px 1301px #fff, 396px 512px #fff,
		1013px 863px #fff, 217px 163px #fff, 1097px 969px #fff, 1887px 199px #fff, 591px 1551px #fff, 1736px 56px #fff, 310px 1861px #fff, 1612px 1564px #fff,
		614px 5px #fff, 1298px 1876px #fff, 313px 1951px #fff, 793px 1147px #fff, 869px 1502px #fff, 747px 1147px #fff, 1980px 1463px #fff, 1323px 708px #fff,
		1848px 1184px #fff, 1133px 1650px #fff, 1688px 1393px #fff, 1428px 1722px #fff, 1333px 620px #fff, 1879px 1545px #fff, 1600px 1158px #fff, 1739px 1081px #fff,
		132px 364px #fff, 1114px 137px #fff, 165px 1079px #fff, 1945px 178px #fff, 250px 616px #fff, 861px 1342px #fff, 1464px 191px #fff, 716px 1334px #fff,
		1905px 459px #fff, 190px 595px #fff, 1239px 1100px #fff, 1119px 403px #fff, 352px 834px #fff, 442px 1461px #fff, 1849px 1588px #fff, 1278px 335px #fff,
		25px 968px #fff, 191px 562px #fff, 1999px 1719px #fff, 1876px 752px #fff, 1673px 133px #fff, 1029px 419px #fff, 691px 1424px #fff;
}

#stars3 {
	width: 3px;
	height: 3px;
	background: transparent;
	box-shadow: 1231px 185px #fff, 1380px 69px #fff, 1510px 1533px #fff, 1681px 1488px #fff, 402px 270px #fff, 1687px 1711px #fff, 1924px 999px #fff, 544px 497px #fff,
		1038px 1403px #fff, 1404px 720px #fff, 1611px 1367px #fff, 1393px 1929px #fff, 1792px 834px #fff, 239px 1813px #fff, 751px 888px #fff, 796px 421px #fff,
		1034px 1112px #fff, 865px 1322px #fff, 104px 1584px #fff, 1363px 1415px #fff, 412px 1893px #fff, 649px 301px #fff, 39px 1041px #fff, 1815px 862px #fff,
		119px 1350px #fff, 1528px 1768px #fff, 625px 485px #fff, 1087px 1254px #fff, 1033px 1087px #fff, 523px 1522px #fff, 63px 291px #fff, 968px 1860px #fff,
		416px 1873px #fff, 136px 985px #fff, 322px 726px #fff, 1142px 58px #fff, 1360px 749px #fff, 1555px 1218px #fff, 759px 1889px #fff, 1634px 237px #fff,
		1304px 541px #fff, 457px 1080px #fff, 1788px 1735px #fff, 295px 711px #fff, 1679px 1921px #fff, 1796px 1259px #fff, 494px 1914px #fff, 540px 401px #fff,
		1543px 763px #fff, 367px 1255px #fff, 1193px 1376px #fff, 1212px 521px #fff, 1146px 306px #fff, 520px 494px #fff, 178px 316px #fff, 1554px 1367px #fff,
		1823px 1827px #fff, 683px 521px #fff, 1533px 629px #fff, 1940px 1183px #fff, 1178px 33px #fff, 1140px 46px #fff, 910px 1223px #fff, 61px 1541px #fff,
		650px 1973px #fff, 843px 1084px #fff, 1241px 1863px #fff, 723px 372px #fff, 1397px 109px #fff, 308px 1685px #fff, 1382px 1427px #fff, 1926px 554px #fff,
		1230px 774px #fff, 1143px 339px #fff, 717px 1052px #fff, 454px 1973px #fff, 744px 1219px #fff, 99px 1870px #fff, 1145px 109px #fff, 1782px 1571px #fff,
		1789px 535px #fff, 1508px 301px #fff, 1743px 185px #fff, 1659px 1369px #fff, 1178px 1966px #fff, 1442px 1082px #fff, 80px 1482px #fff, 809px 1758px #fff,
		1172px 1435px #fff, 1660px 480px #fff, 1902px 846px #fff, 720px 242px #fff, 161px 1168px #fff, 1506px 1228px #fff, 1836px 111px #fff, 81px 1584px #fff,
		1590px 316px #fff, 349px 580px #fff, 1613px 1939px #fff, 455px 370px #fff, 934px 767px #fff, 1279px 506px #fff, 1102px 374px #fff, 1839px 98px #fff,
		1540px 1486px #fff, 1058px 506px #fff, 1400px 1535px #fff, 602px 645px #fff, 607px 352px #fff, 1414px 1260px #fff, 1934px 844px #fff, 1354px 678px #fff,
		705px 1122px #fff, 1454px 294px #fff, 971px 1224px #fff, 1268px 505px #fff, 1628px 259px #fff, 142px 717px #fff, 970px 121px #fff, 1838px 765px #fff,
		706px 719px #fff, 708px 419px #fff, 614px 85px #fff, 327px 258px #fff, 964px 1145px #fff, 580px 1987px #fff, 373px 990px #fff, 1142px 321px #fff, 1204px 660px #fff,
		1758px 327px #fff, 1349px 1762px #fff, 60px 383px #fff, 1346px 1616px #fff, 28px 781px #fff, 1768px 309px #fff, 1395px 659px #fff, 1131px 461px #fff,
		1721px 748px #fff, 412px 1291px #fff, 104px 128px #fff, 607px 404px #fff, 1803px 291px #fff, 10px 363px #fff, 428px 681px #fff, 694px 1010px #fff,
		1909px 1697px #fff, 970px 1434px #fff, 1042px 743px #fff, 214px 1550px #fff, 229px 799px #fff, 31px 1781px #fff, 1835px 1446px #fff, 1502px 1347px #fff,
		1130px 1565px #fff, 866px 205px #fff, 769px 753px #fff, 997px 1885px #fff, 1178px 109px #fff, 1369px 580px #fff, 1159px 1792px #fff, 1374px 1863px #fff,
		1047px 549px #fff, 527px 990px #fff, 1433px 44px #fff, 1180px 1135px #fff, 985px 88px #fff, 837px 955px #fff, 1927px 120px #fff, 1426px 1860px #fff,
		601px 1070px #fff, 1027px 1957px #fff, 68px 304px #fff, 1695px 62px #fff, 1196px 1830px #fff, 86px 1375px #fff, 611px 25px #fff, 459px 616px #fff, 94px 62px #fff,
		603px 1986px #fff, 1098px 1796px #fff, 1078px 1705px #fff, 90px 101px #fff, 908px 1965px #fff, 150px 6px #fff, 270px 695px #fff, 1975px 1198px #fff,
		385px 914px #fff, 633px 879px #fff, 1710px 911px #fff, 1076px 1713px #fff, 1219px 1571px #fff, 1420px 801px #fff, 708px 673px #fff, 17px 923px #fff,
		48px 1011px #fff, 351px 905px #fff, 675px 5px #fff, 844px 263px #fff, 1000px 1339px #fff, 1495px 880px #fff;
	border-radius: 50%;
	animation: animStar 50s linear infinite;
}
#stars3:after {
	content: ' ';
	position: absolute;
	top: 2000px;
	width: 3px;
	height: 3px;
	background: transparent;
	box-shadow: 1231px 185px #fff, 1380px 69px #fff, 1510px 1533px #fff, 1681px 1488px #fff, 402px 270px #fff, 1687px 1711px #fff, 1924px 999px #fff, 544px 497px #fff,
		1038px 1403px #fff, 1404px 720px #fff, 1611px 1367px #fff, 1393px 1929px #fff, 1792px 834px #fff, 239px 1813px #fff, 751px 888px #fff, 796px 421px #fff,
		1034px 1112px #fff, 865px 1322px #fff, 104px 1584px #fff, 1363px 1415px #fff, 412px 1893px #fff, 649px 301px #fff, 39px 1041px #fff, 1815px 862px #fff,
		119px 1350px #fff, 1528px 1768px #fff, 625px 485px #fff, 1087px 1254px #fff, 1033px 1087px #fff, 523px 1522px #fff, 63px 291px #fff, 968px 1860px #fff,
		416px 1873px #fff, 136px 985px #fff, 322px 726px #fff, 1142px 58px #fff, 1360px 749px #fff, 1555px 1218px #fff, 759px 1889px #fff, 1634px 237px #fff,
		1304px 541px #fff, 457px 1080px #fff, 1788px 1735px #fff, 295px 711px #fff, 1679px 1921px #fff, 1796px 1259px #fff, 494px 1914px #fff, 540px 401px #fff,
		1543px 763px #fff, 367px 1255px #fff, 1193px 1376px #fff, 1212px 521px #fff, 1146px 306px #fff, 520px 494px #fff, 178px 316px #fff, 1554px 1367px #fff,
		1823px 1827px #fff, 683px 521px #fff, 1533px 629px #fff, 1940px 1183px #fff, 1178px 33px #fff, 1140px 46px #fff, 910px 1223px #fff, 61px 1541px #fff,
		650px 1973px #fff, 843px 1084px #fff, 1241px 1863px #fff, 723px 372px #fff, 1397px 109px #fff, 308px 1685px #fff, 1382px 1427px #fff, 1926px 554px #fff,
		1230px 774px #fff, 1143px 339px #fff, 717px 1052px #fff, 454px 1973px #fff, 744px 1219px #fff, 99px 1870px #fff, 1145px 109px #fff, 1782px 1571px #fff,
		1789px 535px #fff, 1508px 301px #fff, 1743px 185px #fff, 1659px 1369px #fff, 1178px 1966px #fff, 1442px 1082px #fff, 80px 1482px #fff, 809px 1758px #fff,
		1172px 1435px #fff, 1660px 480px #fff, 1902px 846px #fff, 720px 242px #fff, 161px 1168px #fff, 1506px 1228px #fff, 1836px 111px #fff, 81px 1584px #fff,
		1590px 316px #fff, 349px 580px #fff, 1613px 1939px #fff, 455px 370px #fff, 934px 767px #fff, 1279px 506px #fff, 1102px 374px #fff, 1839px 98px #fff,
		1540px 1486px #fff, 1058px 506px #fff, 1400px 1535px #fff, 602px 645px #fff, 607px 352px #fff, 1414px 1260px #fff, 1934px 844px #fff, 1354px 678px #fff,
		705px 1122px #fff, 1454px 294px #fff, 971px 1224px #fff, 1268px 505px #fff, 1628px 259px #fff, 142px 717px #fff, 970px 121px #fff, 1838px 765px #fff,
		706px 719px #fff, 708px 419px #fff, 614px 85px #fff, 327px 258px #fff, 964px 1145px #fff, 580px 1987px #fff, 373px 990px #fff, 1142px 321px #fff, 1204px 660px #fff,
		1758px 327px #fff, 1349px 1762px #fff, 60px 383px #fff, 1346px 1616px #fff, 28px 781px #fff, 1768px 309px #fff, 1395px 659px #fff, 1131px 461px #fff,
		1721px 748px #fff, 412px 1291px #fff, 104px 128px #fff, 607px 404px #fff, 1803px 291px #fff, 10px 363px #fff, 428px 681px #fff, 694px 1010px #fff,
		1909px 1697px #fff, 970px 1434px #fff, 1042px 743px #fff, 214px 1550px #fff, 229px 799px #fff, 31px 1781px #fff, 1835px 1446px #fff, 1502px 1347px #fff,
		1130px 1565px #fff, 866px 205px #fff, 769px 753px #fff, 997px 1885px #fff, 1178px 109px #fff, 1369px 580px #fff, 1159px 1792px #fff, 1374px 1863px #fff,
		1047px 549px #fff, 527px 990px #fff, 1433px 44px #fff, 1180px 1135px #fff, 985px 88px #fff, 837px 955px #fff, 1927px 120px #fff, 1426px 1860px #fff,
		601px 1070px #fff, 1027px 1957px #fff, 68px 304px #fff, 1695px 62px #fff, 1196px 1830px #fff, 86px 1375px #fff, 611px 25px #fff, 459px 616px #fff, 94px 62px #fff,
		603px 1986px #fff, 1098px 1796px #fff, 1078px 1705px #fff, 90px 101px #fff, 908px 1965px #fff, 150px 6px #fff, 270px 695px #fff, 1975px 1198px #fff,
		385px 914px #fff, 633px 879px #fff, 1710px 911px #fff, 1076px 1713px #fff, 1219px 1571px #fff, 1420px 801px #fff, 708px 673px #fff, 17px 923px #fff,
		48px 1011px #fff, 351px 905px #fff, 675px 5px #fff, 844px 263px #fff, 1000px 1339px #fff, 1495px 880px #fff;
}

//   html {
//     position: fixed;
//     height: 100%;
//     width: 100%;
//     font-family: "lato", sans-serif;
//     color: #FFF;
//     background: radial-gradient(ellipse at bottom, #0c1116 0%, #090a0f 100%);
//   }

#horizon {
	position: absolute;
	width: 160%;
	height: 70%;
	border-radius: 100%/100%;
	// background: #038bff;
	// background: var(--color-P5);
	background: var(--app-profile-gravity-horizon-background);
	// background: #007cff;
	-webkit-filter: blur(30px);
	left: 50%;
	bottom: -20%;
	margin-left: -80%;
}
#horizon:before {
	content: ' ';
	position: absolute;
	width: 81.25%;
	height: 70%;
	border-radius: 100%/100%;
	// background: #51afff;
	// background: var(--color-P5);
	background: var(--color-V4);
	// background: #007cff;
	-webkit-filter: blur(30px);
	opacity: 0.6;
	margin-left: 9.375%;
}
#horizon:after {
	content: ' ';
	position: absolute;
	width: 32%;
	height: 20%;
	border-radius: 650px/350px;
	// background: #b0daff;
	// background: var(--color-P5);
	background: var(--color-V4);
	// background: #007cff;
	-webkit-filter: blur(30px);
	opacity: 0.5;
	margin-left: 34%;
}
#horizon .glow {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 100%/100%;
	// background: #215496;
	-webkit-filter: blur(200px);
	opacity: 0.7;
	top: -10%;
}

#earth {
	position: absolute;
	width: 200%;
	height: 100%;
	background: var(--app-profile-gravity-earth-background);
	border-radius: 100%/100%;
	left: 50%;
	bottom: -50%;
	margin-left: -100%;
}
#earth:before {
	content: ' ';
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 100%/100%;
	// box-shadow: inset 0px 0px 62px 20px rgba(60, 105, 138, 0.85);
	box-shadow: inset 0px 0px 62px 20px #39159dc2;
}
#earth:after {
	// *zoom: 1;
	filter: progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#FF000000', endColorstr='#FF000000');
	// background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
	// background-size: 100%;
	// background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #000000), color-stop(50%, rgba(0, 0, 0, 0)), color-stop(100%, #000000));
	// background-image: -moz-linear-gradient(left, #000000 0%, rgba(0, 0, 0, 0) 50%, #000000 100%);
	// background-image: -webkit-linear-gradient(left, #000000 0%, rgba(0, 0, 0, 0) 50%, #000000 100%);
	// background-image: linear-gradient(to right, #000000 0%, rgba(0, 0, 0, 0) 50%, #000000 100%);
	content: ' ';
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 100%/100%;
}

#title {
	position: absolute;
	font-weight: 300;
	top: 36%;
	left: 0;
	right: 0;
	margin-top: -80px;
	font-size: 130px;
	text-align: center;
	letter-spacing: 20px;
	padding-left: 20px;
	background: -webkit-linear-gradient(var(--app-color-white), #dbdde0, #38495a);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation-duration: 6s;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-name: animGravity;
}

#subtitle {
	position: absolute;
	font-weight: 300;
	top: 70%;
	left: 0;
	right: 0;
	font-size: 25px;
	text-align: center;
	letter-spacing: 6px;
}
#subtitle span {
	color: #d8d8d8;
	animation-duration: 6s;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
}
#subtitle span:nth-child(1) {
	animation-name: animDont;
}
#subtitle span:nth-child(2) {
	animation-name: animLet;
}
#subtitle span:nth-child(3) {
	animation-name: animGo;
}

@keyframes animStar {
	from {
		transform: translateY(0px);
	}
	to {
		transform: translateY(-2000px);
	}
}
@keyframes animGravity {
	0% {
		transform: translateY(-26px);
		opacity: 0;
	}
	30%,
	80% {
		letter-spacing: 40px;
		padding-left: 40px;
		transform: translateY(0px);
		opacity: 1;
	}
	92%,
	100% {
		letter-spacing: 35px;
		padding-left: 35px;
		transform: translateY(-4px);
		opacity: 0;
	}
}
@keyframes animDont {
	0%,
	15% {
		transform: translateY(-26px);
		opacity: 0;
	}
	35%,
	80% {
		transform: translateY(0px);
		opacity: 1;
	}
	92%,
	100% {
		transform: translateY(-4px);
		opacity: 0;
	}
}
@keyframes animLet {
	0%,
	25% {
		transform: translateY(-26px);
		opacity: 0;
	}
	45%,
	80% {
		transform: translateY(0px);
		opacity: 1;
	}
	92%,
	100% {
		transform: translateY(-4px);
		opacity: 0;
	}
}
@keyframes animGo {
	0%,
	35% {
		transform: translateY(-26px);
		opacity: 0;
	}
	55%,
	80% {
		transform: translateY(0px);
		opacity: 1;
	}
	92%,
	100% {
		transform: translateY(-4px);
		opacity: 0;
	}
}
