/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import '@ionic/angular/css/palettes/dark.system.css';

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';

@import 'theme/animations';
@import 'theme/keyframes.scss';
@import 'assets/fonts/fonts.scss';
@import 'theme/components.scss';

// tbt-icon
@import 'assets/scss/tbt-icon.scss';

// Themes
@import 'theme/baby-trader-theme';
@import 'theme/king-trader-theme';
@import 'theme/variables.scss';
@import 'theme/dark/theme-dark.scss';
@import 'theme/light/theme-light.scss';
// @import 'theme/variables.scss';

html {
	scrollbar-width: thin; /* Options: auto, thin, none */
	scrollbar-color: #0a0b1c transparent; /* thumb color, track color */
}

:root hr {
	--hr-line-color: var(--app-hr-color);
	display: grid;
	width: 100%;
	place-items: center;
	border-image-slice: 1;
	border-image-source: linear-gradient(
		to right,
		transparent,
		var(--hr-line-color) calc(50% - var(--app-hr-line-offset)),
		var(--hr-line-color) calc(50% - var(--app-hr-text-offset)),
		transparent calc(50% - var(--app-hr-text-offset)),
		transparent calc(50% + var(--app-hr-text-offset)),
		var(--hr-line-color) calc(50% + var(--app-hr-text-offset)),
		var(--hr-line-color) calc(50% + var(--app-hr-line-offset)),
		transparent
	);
}
:root hr::after {
	content: 'OR';
	font-weight: bold;
	color: var(--app-hr-text-color);
	position: absolute;
	translate: 0 calc(var(--app-hr-thickness) / -2);
}
// TODO: delete
// #region app-flicker-animation
.app-flicker-animation {
	-webkit-animation: app-flicker 2s ease-in-out infinite alternate;
	animation: app-flicker 2s ease-in-out infinite alternate;
}
// #endregion
// TODO: delete
// #region Flicker Animation
@-webkit-keyframes app-flicker {
	0% {
		opacity: 1;
	}
	5% {
		opacity: 1;
	}
	5.5% {
		opacity: 0;
	}
	6% {
		opacity: 1;
	}
	6.5% {
		opacity: 1;
	}
	7% {
		opacity: 0;
	}
	8% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
@keyframes app-flicker {
	0% {
		opacity: 1;
	}
	5% {
		opacity: 1;
	}
	5.5% {
		opacity: 0;
	}
	6% {
		opacity: 1;
	}
	6.5% {
		opacity: 1;
	}
	7% {
		opacity: 0;
	}
	8% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
// #endregion

.app-card-background {
	background-color: var(--app-card-background) !important;
}

.round-progress-svg-gradient {
	position: absolute !important;
	z-index: -999 !important;
}

ion-menu,
ion-list,
ion-item {
	--background: none;
}

.list-ios {
	background: none;
}

// #region Circle Wrapper
// .circle-wrapper {
// 	width: 100px;
// 	height: 100px;

// 	.icon {
// 		font-size: 55px;
// 	}
// }

// .circle {
// 	width: 100%;
// 	height: 100%;
// 	padding: 2.5px;
// 	background-clip: content-box;
// 	animation: spin 10s linear infinite;
// }

// .circle-wrapper:active .circle {
// 	animation: spin 2s linear infinite;
// }

// .success {
// 	background-color: #4bb543;
// 	border: 2.5px dashed #4bb543;
// }

// .error {
// 	background-color: #ca0b00;
// 	border: 2.5px dashed #ca0b00;
// }

// .warning {
// 	background-color: #f0d500;
// 	border: 2.5px dashed #f0d500;
// }

countdown {
	font-size: 64px;
}

.app-white-border {
	&_1px,
	&_2px,
	&_3px,
	&_4px,
	&_5px {
		border-color: var(--app-color-white);
		border-style: solid;
	}

	&_1px {
		border-width: 1px;
	}
	&_2px {
		border-width: 2px;
	}
	&_3px {
		border-width: 3px;
	}
	&_4px {
		border-width: 4px;
	}
	&_5px {
		border-width: 5px;
	}
}
// #region App Three Dots Loading
.app-three-dots-loading {
	&-primary {
		width: 56px;
		height: 13.4px;
		background: radial-gradient(circle closest-side, var(--app-color-primary) 90%, transparent) 0% 50%,
			radial-gradient(circle closest-side, var(--app-color-primary) 90%, transparent) 50% 50%,
			radial-gradient(circle closest-side, var(--app-color-primary) 90%, transparent) 100% 50%;
		background-size: calc(100% / 3) 100%;
		background-repeat: no-repeat;
		animation: app-three-dots-loading 1s infinite linear;
	}
	&-secondary {
		width: 56px;
		height: 13.4px;
		background: radial-gradient(circle closest-side, var(--app-color-secondary) 90%, transparent) 0% 50%,
			radial-gradient(circle closest-side, var(--app-color-secondary) 90%, transparent) 50% 50%,
			radial-gradient(circle closest-side, var(--app-color-secondary) 90%, transparent) 100% 50%;
		background-size: calc(100% / 3) 100%;
		background-repeat: no-repeat;
		animation: app-three-dots-loading 1s infinite linear;
	}
	&-success {
		width: 56px;
		height: 13.4px;
		background: radial-gradient(circle closest-side, var(--app-color-success) 90%, transparent) 0% 50%,
			radial-gradient(circle closest-side, var(--app-color-success) 90%, transparent) 50% 50%,
			radial-gradient(circle closest-side, var(--app-color-success) 90%, transparent) 100% 50%;
		background-size: calc(100% / 3) 100%;
		background-repeat: no-repeat;
		animation: app-three-dots-loading 1s infinite linear;
	}
	&-danger {
		width: 56px;
		height: 13.4px;
		background: radial-gradient(circle closest-side, var(--app-color-danger) 90%, transparent) 0% 50%,
			radial-gradient(circle closest-side, var(--app-color-danger) 90%, transparent) 50% 50%,
			radial-gradient(circle closest-side, var(--app-color-danger) 90%, transparent) 100% 50%;
		background-size: calc(100% / 3) 100%;
		background-repeat: no-repeat;
		animation: app-three-dots-loading 1s infinite linear;
	}
	&-warning {
		width: 56px;
		height: 13.4px;
		background: radial-gradient(circle closest-side, var(--app-color-warning) 90%, transparent) 0% 50%,
			radial-gradient(circle closest-side, var(--app-color-warning) 90%, transparent) 50% 50%,
			radial-gradient(circle closest-side, var(--app-color-warning) 90%, transparent) 100% 50%;
		background-size: calc(100% / 3) 100%;
		background-repeat: no-repeat;
		animation: app-three-dots-loading 1s infinite linear;
	}
	&-info {
		width: 56px;
		height: 13.4px;
		background: radial-gradient(circle closest-side, var(--app-color-info) 90%, transparent) 0% 50%,
			radial-gradient(circle closest-side, var(--app-color-info) 90%, transparent) 50% 50%,
			radial-gradient(circle closest-side, var(--app-color-info) 90%, transparent) 100% 50%;
		background-size: calc(100% / 3) 100%;
		background-repeat: no-repeat;
		animation: app-three-dots-loading 1s infinite linear;
	}
}
// #endregion

// Letter Spacing
.ls-1 {
	letter-spacing: 1px;
}
.ls-2 {
	letter-spacing: 2px;
}
.ls-3 {
	letter-spacing: 3px;
}

.cursor-pointer {
	cursor: pointer;
}

// #region App Font Size
.app-fs-14 {
	font-size: 14px;
}
.app-fs-15 {
	font-size: 15px;
}
.app-fs-16 {
	font-size: 16px;
}
.app-fs-17 {
	font-size: 17px;
}
.app-fs-18 {
	font-size: 18px;
}
.app-fs-19 {
	font-size: 19px;
}
.app-fs-20 {
	font-size: 20px;
}
// #endregion

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	appearance: none;
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}

.app-transition-all-250ms {
	-webkit-transition: all 250ms ease;
	-moz-transition: all 250ms ease;
	-o-transition: all 250ms ease;
	-ms-transition: all 250ms ease;
	transition: all 250ms ease;
}

// Chat
.online {
	color: var(--online-status-text-color);
}
.offline {
	color: var(--offline-status-text-color);
}

// #region App Borders
// App Rounded Borders
.app-rounded {
	@include border-radius(var(--app-border-radius));
}
.app-rounded-top {
	@include border-top-radius(var(--app-border-radius));
}
.app-rounded-end {
	@include border-end-radius(var(--app-border-radius));
}
.app-rounded-bottom {
	@include border-bottom-radius(var(--app-border-radius));
}
.app-rounded-start {
	@include border-start-radius(var(--app-border-radius));
}
// #endregion

swiper-container::part(bullet) {
	background-color: #ffffff !important; /* Change to the desired color */
}
swiper-container::part(bullet-active) {
	background-color: var(--app-color-primary) !important; /* Change to the desired color */
}

// Image Tag Cover
.app-img-object-fit-cover {
	object-fit: cover;
}

// App Search Input
.app-search-form-control {
	background-color: #ffffff14 !important;
	border: none !important;
	padding-right: 2.2rem;
	color: var(--app-color-white) !important;
	// border-radius: 1rem;
	box-shadow: none !important;
	border-radius: 1rem;
	&::placeholder {
		color: #ffffff70 !important;
	}
}

.app-sub-header-toolbar {
	z-index: 1000 !important;
	// top: -1px !important;

	ion-toolbar {
		--background: var(--app-sub-header-toolbar-background);
		--color: white;
		backdrop-filter: blur(var(--app-sub-header-toolbar-backdrop-filter));

		// --border-color: #f24aec;
		// --border-width: 4px 0;
		// --border-style: double;

		// --min-height: 80px;
		// --padding-top: 20px;
		// --padding-bottom: 20px;
	}

	ion-toolbar.ios {
		ion-button {
			width: 40px;
			height: 40px;
		}
	}
}

.padding-top-after-sub-header-toolbar {
	padding-top: var(--app-padding-top-after-sub-header-toolbar) !important;
}

.app-backdrop-filter-blur {
	backdrop-filter: blur(var(--app-backdrop-filter-blur-10));
}

.app-pill {
	padding: 0.25rem 1rem !important;
	border-radius: 100px;
	background-color: var(--app-pill-background);
	backdrop-filter: blur(var(--app-pill-backdrop-filter));
}
