.app-circle-16 {
	border-radius: var(--app-border-circle);
	width: 16px;
	height: 16px;
}
.app-circle-22 {
	border-radius: var(--app-border-circle);
	width: 22px;
	height: 22px;
}
.app-circle-32 {
	border-radius: var(--app-border-circle);
	width: 32px;
	height: 32px;
}
.app-circle-50 {
	border-radius: var(--app-border-circle);
	width: 50px;
	height: 50px;
}
.app-circle-64 {
	border-radius: var(--app-border-circle);
	width: 64px;
	height: 64px;
}
.app-circle-80 {
	border-radius: var(--app-border-circle);
	width: 80px;
	height: 80px;
}
.app-circle-100 {
	border-radius: var(--app-border-circle);
	width: 100px;
	height: 100px;
}
.app-circle-128 {
	border-radius: var(--app-border-circle);
	width: 128px;
	height: 128px;
}
.app-circle-150 {
	border-radius: var(--app-border-circle);
	width: 150px;
	height: 150px;
}
.app-circle-200 {
	border-radius: var(--app-border-circle);
	width: 200px;
	height: 200px;
}
