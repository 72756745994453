// TABS MODULE
body.dark {
	// layout
	// main layout
	--ion-font-family: 'Manrope' !important;
	--ion-default-font: 'Manrope' !important;
	--ion-text-color: var(--app-color-white);
	--ion-background-color: var(--background);
	--ion-tab-background-color: var(--background);
	--ion-tab-icon: var(--dark-3);
	--ion-tab-icon-select: var(--white);
	--ion-header-background-color: transparent;

	// MENU
	--menu-text-color: var(--white);
	--menu-item-color: var(--app-color-blue);
	--menu-item-icon-color: #ffffffe6;
	--menu-item-selected-color: var(--primary);
	--menu-background-color: var(--background);

	// header
	--header-popover-background: var(--dark-1);
	--header-popover-backdrop: #00000000;
	--header-popover-item-border-bottom: #ffffff1a;
	--header-popover-item-label: #ffffff;
	--header-popover-item-value: #8cff8c;
	--header-balance-label: #ffffff80;
	--header-balance-label-arrow-background: #ffffff29;

	// quick access
	--quick-access-modal-background: #1a1b1c;
	--quick-access-icon-color: #ffffffd6;
	--quick-access-avatar-border: #2a2b2c;
	--quick-access-rank-title: #ffffff6b;
	--quick-access-ion-list-background: rgba(255, 255, 255, 0.07);
	--quick-access-ion-item-border: #ffffff22;
	--quick-access-ion-item-icon: #ffffff80;
	--quick-access-logout-color: #ff3800;
}
