.tournament {
	&-timeline {
		position: relative;
		margin-left: 2rem;
		// padding-right: 1rem;
		list-style-type: none;

		&:before {
			content: ' ';
			background: var(--tournament-timeline-vertical-line-background);
			display: inline-block;
			position: absolute;
			left: 29px;
			width: 2px;
			height: 100%;
			z-index: 400;
		}

		& > li {
			margin: 20px 0;
			padding-left: 20px;
		}

		& > li:before {
			content: ' ';
			background: var(--tournament-timeline-item-circle-background);
			display: inline-block;
			position: absolute;
			border-radius: 50%;
			border: 3px solid var(--tournament-timeline-item-circle-border-color);
			left: 20px;
			width: 20px;
			height: 20px;
			z-index: 400;
		}

		&-datetime {
			left: -2rem;
			margin-top: -25px;
			line-height: 1.2;
			color: var(--primary);

			&-day {
				font-size: 25px;
			}
		}

		&-item {
			// background-position: center;
			// background-size: cover;

			&-cover {
				width: 100%;
				height: 100%;

				&-container {
					width: 100%;
					height: 100%;

					&::after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: linear-gradient(to bottom, var(--app-color-black-transparent-30), var(--color-B3-transparent-90));
						/* Adjust gradient colors as needed */
						// pointer-events: none; /* Allows clicks to pass through */
					}
				}
				&-image {
					width: 100%; /* Adjust as needed */
					height: 100%; /* Maintains aspect ratio */
					filter: blur(10px); /* Adjust the blur amount */
					transition: filter 0.3s ease; /* Smooth transition on hover */
				}
			}

			&-blurred {
				width: 100%;
				height: 100%;
				backdrop-filter: blur(10px) brightness(0.6);
			}

			&-icon {
				width: 50px;
				height: 50px;
				border-radius: var(--tournamentItem-icon-radius);

				img {
					border-radius: var(--tournamentItem-icon-radius);
				}
			}

			&-detail-icon {
				font-size: 20px;
			}

			&-description {
				display: -webkit-box;
				font-size: 14px;
				// max-width: 200px;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
	}
}
