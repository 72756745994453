@import 'colors';

.baby-trader-theme {
	--app-color-primary: #{$color-DP1};
	--app-color-secondary: #{$color-BC1};
	--app-color-success: #{$color-G1};
	--app-color-danger: #{$color-R1};
	--app-color-warning: #{$color-O1};
	--app-color-info: #{$color-PB};
}
