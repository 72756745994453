@font-face {
	font-family: 'Manrope';
	src: url('./Manrope/Manrope-ExtraLight.ttf') format('truetype');
	font-weight: 200;
}
@font-face {
	font-family: 'Manrope';
	src: url('./Manrope/Manrope-Light.ttf') format('truetype');
	font-weight: 300;
}
@font-face {
	font-family: 'Manrope';
	src: url('./Manrope/Manrope-Regular.ttf') format('truetype');
	font-weight: 400;
}
@font-face {
	font-family: 'Manrope';
	src: url('./Manrope/Manrope-Medium.ttf') format('truetype');
	font-weight: 500;
}
@font-face {
	font-family: 'Manrope';
	src: url('./Manrope/Manrope-SemiBold.ttf') format('truetype');
	font-weight: 600;
}
@font-face {
	font-family: 'Manrope';
	src: url('./Manrope/Manrope-Bold.ttf') format('truetype');
	font-weight: 700;
}
@font-face {
	font-family: 'Manrope';
	src: url('./Manrope/Manrope-ExtraBold.ttf') format('truetype');
	font-weight: 800;
}
@font-face {
	font-family: 'Liberty';
	src: url('./liberty/liberty.otf') format('opentype');
	font-weight: 100;
}

// liberty font
@font-face {
	font-family: 'Liberty';
	src: url('./liberty/liberty.otf') format('opentype');
	font-weight: 100;
}
@font-face {
	font-family: 'tbt';
	src: url('./tbt.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.app-font-thin {
	font-weight: 100;
}
.app-font-extra-light {
	font-weight: 200;
}
.app-font-light {
	font-weight: 300;
}
.app-font-regular {
	font-weight: 400;
}
.app-font-medium {
	font-weight: 500;
}
.app-font-semi-bold {
	font-weight: 600;
}
.app-font-bold {
	font-weight: 700;
}
.app-font-extra-bold {
	font-weight: 800;
}
.app-font-black {
	font-weight: 900;
}
