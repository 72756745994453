.app-text-primary {
	color: var(--app-text-primary);
}
.app-text-secondary {
	color: var(--app-text-secondary);
}
.app-text-silk {
	color: var(--app-text-silk);
}
.app-text-success {
	color: var(--app-color-success);
}
.app-text-warning {
	color: var(--app-color-warning);
}
.app-text-danger {
	color: var(--app-color-danger);
}
.app-text-info {
	color: var(--app-color-info);
}
