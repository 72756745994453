// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

@import '../backdrop-filters';
@import '../colors';
@import '../borders';
@import '../animations';
@import '../waved.scss';
@import 'theme-dark-accounting.scss';
@import 'theme-dark-ion-action-sheet.scss';
@import 'theme-dark-ion-alert.scss';
@import 'theme-dark-ion-modal.scss';
@import 'theme-dark-ion-segment.scss';
@import 'theme-dark-leaderboard.scss';
@import 'theme-dark-profile.scss';
@import 'theme-dark-quest.scss';
@import 'theme-dark-layout.scss';

body.dark {
	--primary-dark: #2e4345;
	--primary: #49dee2;
	--primary-light: #49dee2;
	--background: #131313;
	--dark-1: #222324;
	--dark-2: #2a2b2c;
	--dark-3: #424344;
	--white: #ffffff;
	--black: #000000;
	--app-text-color: #ffffff;
	--app-color-success: #{$color-G1};
	--app-color-danger: #{$color-R1};
	--app-color-info: #{$color-PB};
	--app-color-warning: #{$color-O1};

	// --ion-header-background-color: var(--background);

	// app button
	--app-button-text-color: var(--black);

	// App Variables - General
	--app-background: var(--background);
	--app-card-background: var(--dark-1);

	--app-background-primary: var(--app-color-primary);
	--app-background-secondary: var(--app-color-secondary);
	--app-background-success: var(--app-color-success);
	--app-background-danger: var(--app-color-danger);
	--app-background-warning: var(--app-color-warning);
	--app-background-info: var(--app-color-info);
	--app-background-white: var(--app-color-white);
	--app-background-black: var(--app-color-black);
	--app-background-neutral: #{$neutral};
	--app-transparent-primary-10: #{$transparent-primary-10};

	--app-text-primary: var(--app-color-primary);
	--app-text-secondary: #{$secondary};
	--app-text-color: var(--app-color-white);
	--app-text-silk: #{$silk};

	--app-padding-top-after-sub-header-toolbar: 46px;

	// App Variables - General - Loading /////////////////////////////////////////////
	--app-loading-background: #{$black-transparent-50};
	--app-loading-spinner-color: var(--app-color-primary);
	--app-loading-text-color: var(--app-color-primary-shade-20);
	// App Variables - General - Borders /////////////////////////////////////////////
	--app-border-radius: #{$app-border-radius};
	--app-border-circle: #{$app-border-circle};
	--app-rounded-square-32-radius: #{$app-rounded-square-32-radius};
	--app-rounded-square-50-radius: #{$app-rounded-square-50-radius};
	--app-rounded-square-64-radius: #{$app-rounded-square-64-radius};
	--app-rounded-square-80-radius: #{$app-rounded-square-80-radius};
	--app-rounded-square-100-radius: #{$app-rounded-square-100-radius};
	--app-rounded-square-128-radius: #{$app-rounded-square-128-radius};
	--app-rounded-square-150-radius: #{$app-rounded-square-150-radius};
	// App Variables - General - hr line /////////////////////////////////////////////
	--app-hr-line-offset: 25%;
	--app-hr-color: #{$silk};
	--app-hr-text-color: #{$silk};
	--app-hr-text-offset: 2ch;
	--app-hr-thickness: 4px;
	// 2053EA
	--tab-icon: #5b5a7c;
	--tab-icon-select: #d1d1dd;

	// App Variables - General - Colors
	// White
	--app-color-white: #{$white};

	// Gray
	--app-color-gray: #{$neutral};
	--app-color-gray-shade-40: #{$neutral-shade-40};

	// Black
	--app-color-black: #{$black};

	// Backgrounds
	--color-B2: #{$color-B2};
	--color-B3: #{$color-B3};
	--color-B4: #{$color-B4};
	--color-B5: #{$color-B5};
	--color-B6: #{$color-B6};
	--color-B7: #{$color-B7};

	// Primary
	--app-color-primary-tint-90: #{$color-DP1-tint-90};
	--app-color-primary-tint-80: #{$color-DP1-tint-80};
	--app-color-primary-tint-70: #{$color-DP1-tint-70};
	--app-color-primary-tint-60: #{$color-DP1-tint-60};
	--app-color-primary-tint-50: #{$color-DP1-tint-50};
	--app-color-primary-tint-40: #{$color-DP1-tint-40};
	--app-color-primary-tint-30: #{$color-DP1-tint-30};
	--app-color-primary-tint-20: #{$color-DP1-tint-20};
	--app-color-primary-tint-10: #{$color-DP1-tint-10};
	// --app-color-primary: #0fd2ea;
	--app-color-primary: var(--primary);
	--app-color-primary-shade-10: #{$color-DP1-shade-10};
	--app-color-primary-shade-20: #{$color-DP1-shade-20};
	--app-color-primary-shade-30: #{$color-DP1-shade-30};
	--app-color-primary-shade-40: #{$color-DP1-shade-40};
	--app-color-primary-shade-50: #{$color-DP1-shade-50};
	--app-color-primary-shade-60: #{$color-DP1-shade-60};
	--app-color-primary-shade-70: #{$color-DP1-shade-70};
	--app-color-primary-shade-80: #{$color-DP1-shade-80};
	--app-color-primary-shade-90: #{$color-DP1-shade-90};

	// Secondary
	--app-color-secondary-tint-90: #{$color-BC1-tint-90};
	--app-color-secondary-tint-80: #{$color-BC1-tint-80};
	--app-color-secondary-tint-70: #{$color-BC1-tint-70};
	--app-color-secondary-tint-60: #{$color-BC1-tint-60};
	--app-color-secondary-tint-50: #{$color-BC1-tint-50};
	--app-color-secondary-tint-40: #{$color-BC1-tint-40};
	--app-color-secondary-tint-30: #{$color-BC1-tint-30};
	--app-color-secondary-tint-20: #{$color-BC1-tint-20};
	--app-color-secondary-tint-10: #{$color-BC1-tint-10};
	--app-color-secondary: #{$color-BC1};
	--app-color-secondary-shade-10: #{$color-BC1-shade-10};
	--app-color-secondary-shade-20: #{$color-BC1-shade-20};
	--app-color-secondary-shade-30: #{$color-BC1-shade-30};
	--app-color-secondary-shade-40: #{$color-BC1-shade-40};
	--app-color-secondary-shade-50: #{$color-BC1-shade-50};
	--app-color-secondary-shade-60: #{$color-BC1-shade-60};
	--app-color-secondary-shade-70: #{$color-BC1-shade-70};
	--app-color-secondary-shade-80: #{$color-BC1-shade-80};
	--app-color-secondary-shade-90: #{$color-BC1-shade-90};

	// Success
	--app-color-success-tint-90: #{$color-G1-tint-90};
	--app-color-success-tint-80: #{$color-G1-tint-80};
	--app-color-success-tint-70: #{$color-G1-tint-70};
	--app-color-success-tint-60: #{$color-G1-tint-60};
	--app-color-success-tint-50: #{$color-G1-tint-50};
	--app-color-success-tint-40: #{$color-G1-tint-40};
	--app-color-success-tint-30: #{$color-G1-tint-30};
	--app-color-success-tint-20: #{$color-G1-tint-20};
	--app-color-success-tint-10: #{$color-G1-tint-10};
	--app-color-success: #{$color-G1};
	--app-color-success-shade-10: #{$color-G1-shade-10};
	--app-color-success-shade-20: #{$color-G1-shade-20};
	--app-color-success-shade-30: #{$color-G1-shade-30};
	--app-color-success-shade-40: #{$color-G1-shade-40};
	--app-color-success-shade-50: #{$color-G1-shade-50};
	--app-color-success-shade-60: #{$color-G1-shade-60};
	--app-color-success-shade-70: #{$color-G1-shade-70};
	--app-color-success-shade-80: #{$color-G1-shade-80};
	--app-color-success-shade-90: #{$color-G1-shade-90};

	// Danger
	--app-color-danger-tint-90: #{$color-R1-tint-90};
	--app-color-danger-tint-80: #{$color-R1-tint-80};
	--app-color-danger-tint-70: #{$color-R1-tint-70};
	--app-color-danger-tint-60: #{$color-R1-tint-60};
	--app-color-danger-tint-50: #{$color-R1-tint-50};
	--app-color-danger-tint-40: #{$color-R1-tint-40};
	--app-color-danger-tint-30: #{$color-R1-tint-30};
	--app-color-danger-tint-20: #{$color-R1-tint-20};
	--app-color-danger-tint-10: #{$color-R1-tint-10};
	--app-color-danger: #{$color-R1};
	--app-color-danger-shade-10: #{$color-R1-shade-10};
	--app-color-danger-shade-20: #{$color-R1-shade-20};
	--app-color-danger-shade-30: #{$color-R1-shade-30};
	--app-color-danger-shade-40: #{$color-R1-shade-40};
	--app-color-danger-shade-50: #{$color-R1-shade-50};
	--app-color-danger-shade-60: #{$color-R1-shade-60};
	--app-color-danger-shade-70: #{$color-R1-shade-70};
	--app-color-danger-shade-80: #{$color-R1-shade-80};
	--app-color-danger-shade-90: #{$color-R1-shade-90};

	// Warning
	--app-color-warning-tint-90: #{$color-O1-tint-90};
	--app-color-warning-tint-80: #{$color-O1-tint-80};
	--app-color-warning-tint-70: #{$color-O1-tint-70};
	--app-color-warning-tint-60: #{$color-O1-tint-60};
	--app-color-warning-tint-50: #{$color-O1-tint-50};
	--app-color-warning-tint-40: #{$color-O1-tint-40};
	--app-color-warning-tint-30: #{$color-O1-tint-30};
	--app-color-warning-tint-20: #{$color-O1-tint-20};
	--app-color-warning-tint-10: #{$color-O1-tint-10};
	--app-color-warning: #{$color-O1};
	--app-color-warning-shade-10: #{$color-O1-shade-10};
	--app-color-warning-shade-20: #{$color-O1-shade-20};
	--app-color-warning-shade-30: #{$color-O1-shade-30};
	--app-color-warning-shade-40: #{$color-O1-shade-40};
	--app-color-warning-shade-50: #{$color-O1-shade-50};
	--app-color-warning-shade-60: #{$color-O1-shade-60};
	--app-color-warning-shade-70: #{$color-O1-shade-70};
	--app-color-warning-shade-80: #{$color-O1-shade-80};
	--app-color-warning-shade-90: #{$color-O1-shade-90};

	// Info
	--app-color-info-tint-90: #{$color-PB-tint-90};
	--app-color-info-tint-80: #{$color-PB-tint-80};
	--app-color-info-tint-70: #{$color-PB-tint-70};
	--app-color-info-tint-60: #{$color-PB-tint-60};
	--app-color-info-tint-50: #{$color-PB-tint-50};
	--app-color-info-tint-40: #{$color-PB-tint-40};
	--app-color-info-tint-30: #{$color-PB-tint-30};
	--app-color-info-tint-20: #{$color-PB-tint-20};
	--app-color-info-tint-10: #{$color-PB-tint-10};
	--app-color-info: #{$color-PB};
	--app-color-info-shade-10: #{$color-PB-shade-10};
	--app-color-info-shade-20: #{$color-PB-shade-20};
	--app-color-info-shade-30: #{$color-PB-shade-30};
	--app-color-info-shade-40: #{$color-PB-shade-40};
	--app-color-info-shade-50: #{$color-PB-shade-50};
	--app-color-info-shade-60: #{$color-PB-shade-60};
	--app-color-info-shade-70: #{$color-PB-shade-70};
	--app-color-info-shade-80: #{$color-PB-shade-80};
	--app-color-info-shade-90: #{$color-PB-shade-90};

	// Red
	--app-color-red: #{$ruby-red};

	// Green
	--app-color-green: #{$tree-green};

	// Blue
	--app-color-blue: #{$palatinate-blue};

	// Violet
	--color-V1: #{$color-V1};
	--color-V2: #{$color-V2};
	--color-V3: #{$color-V3};
	--color-V4: #{$color-V4};
	--color-V5: #{$color-V5};

	// Pink
	--color-P1: #{$color-P1};
	--color-P2: #{$color-P2};
	--color-P3: #{$color-P3};
	--color-P4: #{$color-P4};
	--color-P5: #{$color-P5};

	// Socials
	--app-color-telegram: #{$color-telegram};
	--app-color-instagram: #{$color-instagram};
	--app-color-twitter-x: #{$color-twitter-x};
	--app-color-youtube: #{$color-youtube};

	// Transparents
	// White - Transparent
	--app-color-white-transparent-10: #{$white-transparent-10};
	--app-color-white-transparent-50: #{$white-transparent-50};
	// Black - Transparent
	--app-color-black-transparent-10: #{$black-transparent-10};
	--app-color-black-transparent-20: #{$black-transparent-20};
	--app-color-black-transparent-30: #{$black-transparent-30};
	--app-color-black-transparent-40: #{$black-transparent-40};
	--app-color-black-transparent-50: #{$black-transparent-50};
	--app-color-black-transparent-60: #{$black-transparent-60};
	--app-color-black-transparent-70: #{$black-transparent-70};
	--app-color-black-transparent-80: #{$black-transparent-80};
	--app-color-black-transparent-90: #{$black-transparent-90};
	// color-B2 - Transparent
	--color-B2-transparent-90: #{$color-B2-transparent-90};
	--color-B2-transparent-80: #{$color-B2-transparent-80};
	--color-B2-transparent-70: #{$color-B2-transparent-70};
	--color-B2-transparent-60: #{$color-B2-transparent-60};
	--color-B2-transparent-50: #{$color-B2-transparent-50};
	--color-B2-transparent-40: #{$color-B2-transparent-40};
	--color-B2-transparent-30: #{$color-B2-transparent-30};
	--color-B2-transparent-20: #{$color-B2-transparent-20};
	--color-B2-transparent-10: #{$color-B2-transparent-10};
	// color-B3 - Transparent
	--color-B3-transparent-90: #{$color-B3-transparent-90};
	--color-B3-transparent-80: #{$color-B3-transparent-80};
	--color-B3-transparent-70: #{$color-B3-transparent-70};
	--color-B3-transparent-60: #{$color-B3-transparent-60};
	--color-B3-transparent-50: #{$color-B3-transparent-50};
	--color-B3-transparent-40: #{$color-B3-transparent-40};
	--color-B3-transparent-30: #{$color-B3-transparent-30};
	--color-B3-transparent-20: #{$color-B3-transparent-20};
	--color-B3-transparent-10: #{$color-B3-transparent-10};
	// color-B4 - Transparent
	--color-B4-transparent-90: #{$color-B4-transparent-90};
	--color-B4-transparent-80: #{$color-B4-transparent-80};
	--color-B4-transparent-70: #{$color-B4-transparent-70};
	--color-B4-transparent-60: #{$color-B4-transparent-60};
	--color-B4-transparent-50: #{$color-B4-transparent-50};
	--color-B4-transparent-40: #{$color-B4-transparent-40};
	--color-B4-transparent-30: #{$color-B4-transparent-30};
	--color-B4-transparent-20: #{$color-B4-transparent-20};
	--color-B4-transparent-10: #{$color-B4-transparent-10};
	// color-B5 - Transparent
	--color-B5-transparent-90: #{$color-B5-transparent-90};
	--color-B5-transparent-80: #{$color-B5-transparent-80};
	--color-B5-transparent-70: #{$color-B5-transparent-70};
	--color-B5-transparent-60: #{$color-B5-transparent-60};
	--color-B5-transparent-50: #{$color-B5-transparent-50};
	--color-B5-transparent-40: #{$color-B5-transparent-40};
	--color-B5-transparent-30: #{$color-B5-transparent-30};
	--color-B5-transparent-20: #{$color-B5-transparent-20};
	--color-B5-transparent-10: #{$color-B5-transparent-10};

	// Transparents
	// Primary - Transparent
	--app-color-primary-transparent-90: #{$color-DP1-transparent-90};
	--app-color-primary-transparent-80: #{$color-DP1-transparent-80};
	--app-color-primary-transparent-70: #{$color-DP1-transparent-70};
	--app-color-primary-transparent-60: #{$color-DP1-transparent-60};
	--app-color-primary-transparent-50: #{$color-DP1-transparent-50};
	--app-color-primary-transparent-40: #{$color-DP1-transparent-40};
	--app-color-primary-transparent-30: #{$color-DP1-transparent-30};
	--app-color-primary-transparent-20: #{$color-DP1-transparent-20};
	--app-color-primary-transparent-10: #{$color-DP1-transparent-10};
	--app-color-primary-transparent-0: #{$color-DP1-transparent-0};

	// Secondary - Transparent
	--app-color-secondary-transparent-90: #{$color-BC1-transparent-90};
	--app-color-secondary-transparent-80: #{$color-BC1-transparent-80};
	--app-color-secondary-transparent-70: #{$color-BC1-transparent-70};
	--app-color-secondary-transparent-60: #{$color-BC1-transparent-60};
	--app-color-secondary-transparent-50: #{$color-BC1-transparent-50};
	--app-color-secondary-transparent-40: #{$color-BC1-transparent-40};
	--app-color-secondary-transparent-30: #{$color-BC1-transparent-30};
	--app-color-secondary-transparent-20: #{$color-BC1-transparent-20};
	--app-color-secondary-transparent-10: #{$color-BC1-transparent-10};
	--app-color-secondary-transparent-0: #{$color-BC1-transparent-0};

	// Success - Transparent
	--app-color-success-transparent-90: #{$color-G1-transparent-90};
	--app-color-success-transparent-80: #{$color-G1-transparent-80};
	--app-color-success-transparent-70: #{$color-G1-transparent-70};
	--app-color-success-transparent-60: #{$color-G1-transparent-60};
	--app-color-success-transparent-50: #{$color-G1-transparent-50};
	--app-color-success-transparent-40: #{$color-G1-transparent-40};
	--app-color-success-transparent-30: #{$color-G1-transparent-30};
	--app-color-success-transparent-20: #{$color-G1-transparent-20};
	--app-color-success-transparent-10: #{$color-G1-transparent-10};
	--app-color-success-transparent-0: #{$color-G1-transparent-0};

	// Danger - Transparent
	--app-color-danger-transparent-90: #{$color-R1-transparent-90};
	--app-color-danger-transparent-80: #{$color-R1-transparent-80};
	--app-color-danger-transparent-70: #{$color-R1-transparent-70};
	--app-color-danger-transparent-60: #{$color-R1-transparent-60};
	--app-color-danger-transparent-50: #{$color-R1-transparent-50};
	--app-color-danger-transparent-40: #{$color-R1-transparent-40};
	--app-color-danger-transparent-30: #{$color-R1-transparent-30};
	--app-color-danger-transparent-20: #{$color-R1-transparent-20};
	--app-color-danger-transparent-10: #{$color-R1-transparent-10};
	--app-color-danger-transparent-0: #{$color-R1-transparent-0};

	// Warning - Transparent
	--app-color-warning-transparent-90: #{$color-O1-transparent-90};
	--app-color-warning-transparent-80: #{$color-O1-transparent-80};
	--app-color-warning-transparent-70: #{$color-O1-transparent-70};
	--app-color-warning-transparent-60: #{$color-O1-transparent-60};
	--app-color-warning-transparent-50: #{$color-O1-transparent-50};
	--app-color-warning-transparent-40: #{$color-O1-transparent-40};
	--app-color-warning-transparent-30: #{$color-O1-transparent-30};
	--app-color-warning-transparent-20: #{$color-O1-transparent-20};
	--app-color-warning-transparent-10: #{$color-O1-transparent-10};
	--app-color-warning-transparent-0: #{$color-O1-transparent-0};

	// Info - Transparent
	--app-color-info-transparent-90: #{$color-PB-transparent-90};
	--app-color-info-transparent-80: #{$color-PB-transparent-80};
	--app-color-info-transparent-70: #{$color-PB-transparent-70};
	--app-color-info-transparent-60: #{$color-PB-transparent-60};
	--app-color-info-transparent-50: #{$color-PB-transparent-50};
	--app-color-info-transparent-40: #{$color-PB-transparent-40};
	--app-color-info-transparent-30: #{$color-PB-transparent-30};
	--app-color-info-transparent-20: #{$color-PB-transparent-20};
	--app-color-info-transparent-10: #{$color-PB-transparent-10};
	--app-color-info-transparent-0: #{$color-PB-transparent-0};

	--ion-color-primary: var(--app-color-primary) !important;
	--ion-color-primary-rgb: 255, 20, 147 !important;
	--ion-color-primary-contrast: var(--app-color-white) !important;
	--ion-color-primary-contrast-rgb: 255, 255, 255 !important;
	--ion-color-primary-shade: var(--app-color-primary-shade-10) !important;
	--ion-color-primary-tint: var(--app-color-primary-tint-10) !important;

	--ion-color-success: var(--app-color-success) !important;
	--ion-color-success-rgb: 106, 103, 244 !important;
	--ion-color-success-contrast: var(--app-color-white) !important;
	--ion-color-success-contrast-rgb: 255, 255, 255 !important;
	--ion-color-success-shade: var(--app-color-success-shade-10) !important;
	--ion-color-success-tint: var(--app-color-success-tint-10) !important;

	--ion-tab-bar-background: var(--app-color-white);

	// App Variables - set to ionic components
	// --app-ion-app-background: #{$haiti};
	// --app-ion-app-background: var(--color-B7);

	// --app-ion-segment-background: #{$neutral-transparent-4-4};
	// --app-ion-segment-indicator-background: var(--app-color-primary);
	// --app-ion-segment-color: var(--app-color-white);
	// --app-ion-modal-header: #{$transparent-purple-haze-50};
	// --app-ion-modal-content: #{$transparent-purple-haze-50};

	// --app-ion-tab-bar-background: #{$transparent-purple-haze-80};
	// --app-ion-tab-bar-background: var(--color-B7);
	// --app-ion-tab-button-color-selected: #{$secondary};
	// --app-ion-tab-button-color: #{$silk};

	// App Variables - Fonts
	// --app-font: #{$app-font};
	// --app-font-thin: #{$app-font-thin};
	// --app-font-extra-light: #{$app-font-extra-light};
	// --app-font-light: #{$app-font-light};
	// --app-font-medium: #{$app-font-medium};
	// --app-font-semi-bold: #{$app-font-semi-bold};
	// --app-font-bold: #{$app-font-bold};
	// --app-font-extra-bold: #{$app-font-extra-bold};
	// --app-font-black: #{$app-font-black};

	// App Variables - Header
	--header-button-background: #{$neutral-transparent-4-8};

	// App Variables - Notification
	--notification-card-user-status-circle-background: var(--app-color-green);
	--notification-card-user-status-color: var(--app-color-green);
	--notification-card-time-color: #{$regent-grey};
	--notification-card-content-color: #{$regent-grey};

	// App Variables - Game Card Thumbnail Component
	--game-card-radius: #{$app-border-radius};

	// App Variables - Chat Module
	--chat-online-status-color: var(--app-color-success);
	--chat-offline-status-color: #{$neutral};
	--fab-button-plus-background: var(--app-color-blue);
	--chat-bubble-background: #{$blue-eyes};
	--online-status-text-color: var(--app-color-success);
	--offline-status-text-color: #{$neutral};

	// App Variables - UIXNinja uixninja-game-card
	--uixninja-game-card-radius: 22px;
	--uixninja-game-card-gradient-color: #{$transparent-haiti-80};
	--uixninja-game-card-header-button-background: #{$transparent-iris-70};
	--uixninja-game-card-header-users-background: var(--app-color-black-transparent-40); // #591d81
	--uixninja-game-card-header-users-icon-color: var(--app-color-primary-shade-30);
	--uixninja-game-card-header-icon-radius: 19px;
	--uixninja-game-card-footer-background: var(--app-color-black-transparent-40);
	--uixninja-game-card-footer-icon-radius: 19px;

	// App Variables - UIXNinja uixninja-post-card
	--uixninja-post-card-background: #{$purple-haze};
	--uixninja-post-card-header-icon-radius: 19px;
	--uixninja-post-card-ion-button-background-activated: #{$cloud-burst};
	--uixninja-post-card-ion-button-background-focused: #{$cloud-burst};

	// App Variables - UIXNinja uixninja-square-card
	--uixninja-square-card-overlay-color: #{$transparent-purple-haze-80};
	--uixninja-square-card-header-icon-radius: 19px;

	// App Variables - UIXNinja uixninja-landscape-card
	--uixninja-landscape-card-background: #{$purple-haze-shade};
	--uixninja-landscape-card-round-progress-gradient-color-1: #{$raspberry-pink};
	--uixninja-landscape-card-round-progress-gradient-color-2: #{$salmon};
	--uixninja-landscape-card-radius: 10px;

	// App Variables - UIXNinja uixninja-vertical-card
	--uixninja-vertical-card-background: #{$purple-haze};
	--uixninja-vertical-card-radius: 10px;

	// App Variables - UIXNinja uixninja-balance-line-chart-card

	// App Variables - UIXNinja uixninja-balance-twin-card
	--uixninja-balance-twin-card-radius: 10px;

	// App Variables - UIXNinja uixninja-info-card
	--uixninja-info-card-primary-background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%236967FF' fill-opacity='1' d='M0,224L16,240C32,256,64,288,96,272C128,256,160,192,192,192C224,192,256,256,288,250.7C320,245,352,171,384,165.3C416,160,448,224,480,208C512,192,544,96,576,53.3C608,11,640,21,672,74.7C704,128,736,224,768,256C800,288,832,256,864,245.3C896,235,928,245,960,250.7C992,256,1024,256,1056,245.3C1088,235,1120,213,1152,208C1184,203,1216,213,1248,213.3C1280,213,1312,203,1344,208C1376,213,1408,235,1424,245.3L1440,256L1440,320L1424,320C1408,320,1376,320,1344,320C1312,320,1280,320,1248,320C1216,320,1184,320,1152,320C1120,320,1088,320,1056,320C1024,320,992,320,960,320C928,320,896,320,864,320C832,320,800,320,768,320C736,320,704,320,672,320C640,320,608,320,576,320C544,320,512,320,480,320C448,320,416,320,384,320C352,320,320,320,288,320C256,320,224,320,192,320C160,320,128,320,96,320C64,320,32,320,16,320L0,320Z'%3E%3C/path%3E%3C/svg%3E"),
		linear-gradient(to top, var(--app-color-primary-tint-40) 0%, var(--app-color-primary) 100%);
	--uixninja-info-card-cornflower-blue-background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23448DF8' fill-opacity='1' d='M0,224L16,240C32,256,64,288,96,272C128,256,160,192,192,192C224,192,256,256,288,250.7C320,245,352,171,384,165.3C416,160,448,224,480,208C512,192,544,96,576,53.3C608,11,640,21,672,74.7C704,128,736,224,768,256C800,288,832,256,864,245.3C896,235,928,245,960,250.7C992,256,1024,256,1056,245.3C1088,235,1120,213,1152,208C1184,203,1216,213,1248,213.3C1280,213,1312,203,1344,208C1376,213,1408,235,1424,245.3L1440,256L1440,320L1424,320C1408,320,1376,320,1344,320C1312,320,1280,320,1248,320C1216,320,1184,320,1152,320C1120,320,1088,320,1056,320C1024,320,992,320,960,320C928,320,896,320,864,320C832,320,800,320,768,320C736,320,704,320,672,320C640,320,608,320,576,320C544,320,512,320,480,320C448,320,416,320,384,320C352,320,320,320,288,320C256,320,224,320,192,320C160,320,128,320,96,320C64,320,32,320,16,320L0,320Z'%3E%3C/path%3E%3C/svg%3E"),
		linear-gradient(to top, #{$cornflower-blue-tint-40} 0%, #{$cornflower-blue} 100%);
	--uixninja-info-card-dark-pink-background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23DE4D81' fill-opacity='1' d='M0,224L16,240C32,256,64,288,96,272C128,256,160,192,192,192C224,192,256,256,288,250.7C320,245,352,171,384,165.3C416,160,448,224,480,208C512,192,544,96,576,53.3C608,11,640,21,672,74.7C704,128,736,224,768,256C800,288,832,256,864,245.3C896,235,928,245,960,250.7C992,256,1024,256,1056,245.3C1088,235,1120,213,1152,208C1184,203,1216,213,1248,213.3C1280,213,1312,203,1344,208C1376,213,1408,235,1424,245.3L1440,256L1440,320L1424,320C1408,320,1376,320,1344,320C1312,320,1280,320,1248,320C1216,320,1184,320,1152,320C1120,320,1088,320,1056,320C1024,320,992,320,960,320C928,320,896,320,864,320C832,320,800,320,768,320C736,320,704,320,672,320C640,320,608,320,576,320C544,320,512,320,480,320C448,320,416,320,384,320C352,320,320,320,288,320C256,320,224,320,192,320C160,320,128,320,96,320C64,320,32,320,16,320L0,320Z'%3E%3C/path%3E%3C/svg%3E"),
		linear-gradient(to top, #{$dark-pink-tint-40} 0%, #{$dark-pink} 100%);
	--uixninja-info-card-dusk-background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23333D60' fill-opacity='1' d='M0,224L16,240C32,256,64,288,96,272C128,256,160,192,192,192C224,192,256,256,288,250.7C320,245,352,171,384,165.3C416,160,448,224,480,208C512,192,544,96,576,53.3C608,11,640,21,672,74.7C704,128,736,224,768,256C800,288,832,256,864,245.3C896,235,928,245,960,250.7C992,256,1024,256,1056,245.3C1088,235,1120,213,1152,208C1184,203,1216,213,1248,213.3C1280,213,1312,203,1344,208C1376,213,1408,235,1424,245.3L1440,256L1440,320L1424,320C1408,320,1376,320,1344,320C1312,320,1280,320,1248,320C1216,320,1184,320,1152,320C1120,320,1088,320,1056,320C1024,320,992,320,960,320C928,320,896,320,864,320C832,320,800,320,768,320C736,320,704,320,672,320C640,320,608,320,576,320C544,320,512,320,480,320C448,320,416,320,384,320C352,320,320,320,288,320C256,320,224,320,192,320C160,320,128,320,96,320C64,320,32,320,16,320L0,320Z'%3E%3C/path%3E%3C/svg%3E"),
		linear-gradient(to top, #{$dusk-tint-20} 0%, #{$dusk} 100%);
	--uixninja-info-card-radius: 20px;

	// App Variables - Games - Landing
	--game-landing-cover-radius: 35px;
	--game-landing-icon-radius: 19px;

	// App Variables - Games - Lobby Making
	--select-deposit-box-usdt-background: var(--app-color-success-shade-30); // #26a17b
	--select-deposit-range-usdt-bar-background-active: var(--app-color-success-shade-60);
	--select-deposit-range-usdt-knob-background: var(--app-color-success-shade-50);

	--select-deposit-box-tbt-background: #{$color-Y1-shade-10}; // #f8bf46
	--select-deposit-range-tbt-bar-background-active: #{$color-Y1-shade-60};
	--select-deposit-range-tbt-knob-background: #{$color-Y1-shade-40};

	// App Variables - Active Games Card
	--active-game-card-overlay-color: #{$transparent-purple-haze-80};
	--active-game-card-header-icon-radius: 19px;

	// App Variables - IOS App Store Cards
	--ios-app-store-today-card-radius: #{$app-border-radius};
	--ios-app-store-today-card-footer-background: #{$black-transparent-30};
	--ios-app-store-today-card-footer-backdrop-filter: #{$app-backdrop-filter-blur-50};
	--ios-app-store-today-card-footer-icon-radius: #{$app-border-radius};

	// App Variables - Profile
	--profile-tab-top-section-gradient-color: #{$haiti};
	--profile-tab-content-background: #{$transparent-haiti-50};

	// App Variables - App List Item Component
	--app-list-item-icon-background: var(--app-color-white);
	--app-list-item-icon-radius: #{$app-border-radius};

	// App Variables - Animations
	--button-shine-animation-duration: #{$button-shine-animation-duration};
	--button-shine-animation-speed: #{$button-shine-animation-speed};
	--app-skeleton-shine-animation-duration: #{$app-skeleton-shine-animation-duration};

	// App Variables - Tournaments
	--tournament-timeline-vertical-line-background: var(--dark-3);
	--tournament-timeline-item-circle-background: var(--primary);
	--tournament-timeline-item-circle-border-color: var(--dark-3);
	--tournament-timeline-item-disabled-background: var(--app-background-neutral);
	--tournament-timeline-item-disabled-color: var(--app-color-black);
	--tournament-timeline-item-current-background: var(--app-color-primary-shade-30);
	--tournament-timeline-item-next-background: var(--app-color-secondary-shade-30);
	// App Variables - Tournament Item
	--tournamentItem-timeline-vertical-line-background: var(--color-B2);
	--tournamentItem-timeline-battle-circle-background: var(--app-color-white);
	--tournamentItem-timeline-battle-circle-border-color: var(--color-B2);
	--tournamentItem-timeline-battle-disabled-color: var(--app-color-white);
	--tournamentItem-cover-radius: 15px;
	--tournamentItem-icon-radius: 19px;

	// App Variables - Quest
	--claimed-color: var(--app-color-success); //#0ceb0c

	// App Variables - Skeleton
	--app-skeleton-color: var(--color-B5);

	// App Variables - Backdrop Filters
	--app-backdrop-filter-blur-2: #{$app-backdrop-filter-blur-2};
	--app-backdrop-filter-blur-5: #{$app-backdrop-filter-blur-5};
	--app-backdrop-filter-blur-10: #{$app-backdrop-filter-blur-10};
	--app-backdrop-filter-blur-50: #{$app-backdrop-filter-blur-50};

	// App Variables - Pill
	--app-pill-background: var(--app-color-black-transparent-50);
	--app-pill-backdrop-filter: var(--app-backdrop-filter-blur-2);

	// App Variables - Capsule
	--capsule-backdrop-filter: var(--app-backdrop-filter-blur-2);

	// App Variables - App Sub Header Toolbar
	--app-sub-header-toolbar-background: rgba(34, 35, 36, 0.7);
	--app-sub-header-toolbar-backdrop-filter: 5px;
}
