.duel-progress {
	&-bar {
		&-wrapper {
			height: 20px;
		}
		&-value {
			width: 50%;
			height: 20px;

			&-edge {
				background-color: var(--app-background-primary);
				box-shadow: 0 0 15px #fe4c58, 0 0 25px #fe4c58;
				animation: glow 5s linear infinite;
			}
		}
		&-info {
			height: 10px;

			* {
				height: 100%;
			}
		}
	}
}
