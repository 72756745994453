.app-fade-in-out {
	-webkit-animation: app-fade-in-out 2s linear infinite;
	animation: app-fade-in-out 2s linear infinite;
}

.app-fade-in-down {
	-webkit-animation-name: app-fade-in-down;
	animation-name: app-fade-in-down;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.app-fade-in-right {
	-webkit-animation-name: app-fade-in-right;
	animation-name: app-fade-in-right;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.app-fade-in-up {
	-webkit-animation-name: app-fade-in-up;
	animation-name: app-fade-in-up;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.app-fade-in-left {
	-webkit-animation-name: app-fade-in-left;
	animation-name: app-fade-in-left;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
