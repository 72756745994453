// QUEST MODULE
body.dark {
	// quest
	--app-segment-quest-badge-color-active: var(--dark-3);
	--app-segment-quest-badge-text-active: var(--white);
	--app-segment-quest-badge-color: var(--primary);
	--app-segment-quest-badge-text: var(--black);
	--app-quest-card-background: var(--dark-1);
	--app-quest-card-border: var(--dark-2);
	--app-quest-name-color: #ffffffc4;
}
