// ACCOUNTING MODULE
body.dark {
	// balance card
	--accounting-card-radius: #{$app-border-radius};
	--accounting-balance-card-background: #ffffff0f;
	--accounting-balance-label-calor: #bdbdbd;
	--accounting-balance-value-calor: #ededed;
	// transactions
	--accounting-transaction-card-background: #ffffff0d;
	--accounting-transaction-icon-background: #ffffff0f;
	--accounting-transaction-description-color: #ffffff;
	--accounting-transaction-date-color: #ffffff69;
	--accounting-transaction-token-color: #ffffffcf;
	--accounting-deposit-modal-background: var(--dark-2);
	// Withdraw
	--withdraw-input-background: var(--dark-1);
	--withdraw-card-background: var(--dark-1);
	--withdraw-card-icon-background: #ffffff0f;
	--withdraw-card-description-color: #ffffff;
	--withdraw-card-date-color: #ffffff5e;
	--withdraw-status-Initial-color: #b9b9b9;
	--withdraw-status-Pending-color: #fd930b;
	--withdraw-status-Processing-color: #09a2ff;
	--withdraw-status-Accepted-color: #00d500;
	--withdraw-status-Rejected-color: #ff0e0e;
	--withdraw-modal-background: var(--dark-2);
	--withdraw-custom-button-background: #ffffff44;
}
