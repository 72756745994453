.app-rounded-square-32 {
	border-radius: var(--app-rounded-square-32-radius);
	width: 32px;
	height: 32px;
}
.app-rounded-square-50 {
	border-radius: var(--app-rounded-square-50-radius);
	width: 50px;
	height: 50px;
}
.app-rounded-square-64 {
	border-radius: var(--app-rounded-square-64-radius);
	width: 64px;
	height: 64px;
}
.app-rounded-square-80 {
	border-radius: var(--app-rounded-square-80-radius);
	width: 80px;
	height: 80px;
}
.app-rounded-square-100 {
	border-radius: var(--app-rounded-square-100-radius);
	width: 100px;
	height: 100px;
}
.app-rounded-square-128 {
	border-radius: var(--app-rounded-square-128-radius);
	width: 128px;
	height: 128px;
}
.app-rounded-square-150 {
	border-radius: var(--app-rounded-square-150-radius);
	width: 150px;
	height: 150px;
}
.app-rounded-square-200 {
	border-radius: var(--app-rounded-square-200-radius);
	width: 200px;
	height: 200px;
}