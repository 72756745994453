ion-alert.app-custom-alert {
	.alert-wrapper {
		background: var(--app-alert-background);
	}
	.alert-title {
		color: var(--app-alert-title-color) !important;
	}
	.alert-message {
		color: var(--app-alert-message-color) !important;
	}
}

.app-custom-alert .alert-button-group {
	// padding: 8px;
	// border: 1px solid var(--app-alert-button-border-ios);
	// border-radius: 13px;
	// overflow: hidden;
}

button.alert-button.alert-button-confirm {
	background-color: var(--app-alert-button-confirm-background);
	color: var(--app-alert-button-confirm-text);
}
button.alert-button.alert-button-danger {
	background-color: var(--app-alert-button-danger-background);
	color: var(--app-color-white);
}

.ios .app-custom-alert button.alert-button-group {
	border: 1px solid var(--app-alert-button-border-ios);
	border-radius: 13px;
	overflow: hidden;
}

// .ios button.alert-button.alert-button-cancel {
// 	border-right: 0;
// 	border-bottom-left-radius: 13px;
// 	border-top-left-radius: 13px;
// }

// .ios button.alert-button.alert-button-confirm {
// 	border-bottom-right-radius: 13px;
// 	border-top-right-radius: 13px;
// }
// .ios button.alert-button.alert-button-danger {
// 	border-bottom-right-radius: 13px;
// 	border-top-right-radius: 13px;
// }
