@import 'colors';

.king-trader-theme {
	--app-color-primary: #{$color-V4};
	--app-color-secondary: #{$color-P4};
	--app-color-success: #{$lime-green};
	--app-color-danger: #{$danger};
	--app-color-warning: #{$salmon};
	--app-color-info: #{$cornflower-blue};
}
