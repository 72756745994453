$app-spacing: 10px;
// Letter Spacing
.app-ls-1 {
	letter-spacing: 1px;
}
.app-ls-2 {
	letter-spacing: 2px;
}
.app-ls-3 {
	letter-spacing: 3px;
}
// App Spacing Padding
.app-p {
	padding: #{$app-spacing} !important;
}
.app-px {
	padding-left: #{$app-spacing} !important;
	padding-right: #{$app-spacing} !important;
}
.app-py {
	padding-top: #{$app-spacing} !important;
	padding-bottom: #{$app-spacing} !important;
}
.app-pt {
	padding-top: #{$app-spacing} !important;
}
.app-pb {
	padding-bottom: #{$app-spacing} !important;
}
.app-ps {
	padding-left: #{$app-spacing} !important;
}
.app-pe {
	padding-right: #{$app-spacing} !important;
}

.app-px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.app-py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.app-pt-0 {
	padding-top: 0 !important;
}
.app-pb-0 {
	padding-bottom: 0 !important;
}
.app-ps-0 {
	padding-left: 0 !important;
}
.app-pe-0 {
	padding-right: 0 !important;
}

// App Spacing Margine
.app-m {
	margin: #{$app-spacing} !important;
}
.app-mx {
	margin-left: #{$app-spacing} !important;
	margin-right: #{$app-spacing} !important;
}
.app-my {
	margin-top: #{$app-spacing} !important;
	margin-bottom: #{$app-spacing} !important;
}
.app-mt {
	margin-top: #{$app-spacing} !important;
}
.app-mb {
	margin-bottom: #{$app-spacing} !important;
}
.app-ms {
	margin-left: #{$app-spacing} !important;
}
.app-me {
	margin-right: #{$app-spacing} !important;
}

.app-mx-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.app-my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.app-mt-0 {
	margin-top: 0 !important;
}
.app-mb-0 {
	margin-bottom: 0 !important;
}
.app-ms-0 {
	margin-left: 0 !important;
}
.app-me-0 {
	margin-right: 0 !important;
}
