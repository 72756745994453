// LEADERBOARD MODULE
body.dark {
	// player item leaderboard
	--app-player-item-background-color: var(--dark-1);
	--app-player-item-border-color: var(--dark-2);
	--app-player-item-score-color: #ffffffd1;
	--app-player-item-gold-border: #fff10063;
	--app-player-item-gold-background: #fff10014;
	--app-player-item-silver-border: #90b0caab;
	--app-player-item-silver-background: #92b3cd45;
	--app-player-item-bronze-border: #bb765687;
	--app-player-item-bronze-background: #bb76564d;
	--app-player-item-self-border: #49dee254;
	--app-player-item-self-background: var(--primary-dark); //#49dee212
}
