/**
* We're going to use multiple box-shadows to make the button throb. we can only
* have one "box-shadow" property on a class; but, each "box-shadow" property can
* contain multiple shadows, each of which can be animated independently(ish).
*/
.primary-box-shadow-animation {
	animation-duration: 1750ms;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-name: app-primary-shadow-throb;
	animation-timing-function: linear;
}
.secondary-box-shadow-animation {
	animation-duration: 1750ms;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-name: app-secondary-shadow-throb;
	animation-timing-function: linear;
}
.success-box-shadow-animation {
	animation-duration: 1750ms;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-name: app-success-shadow-throb;
	animation-timing-function: linear;
}
.danger-box-shadow-animation {
	animation-duration: 1750ms;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-name: app-danger-shadow-throb;
	animation-timing-function: linear;
}
.info-box-shadow-animation {
	animation-duration: 1750ms;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-name: app-info-shadow-throb;
	animation-timing-function: linear;
}
.warning-box-shadow-animation {
	animation-duration: 1750ms;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	animation-name: app-warning-shadow-throb;
	animation-timing-function: linear;
}
