ion-segment {
	ion-segment-button {
		padding: 5px 2px;
		margin: 0;
		font-size: 0.9rem;
		.badge {
			color: var(--app-segment-quest-badge-text) !important;
			background-color: var(--app-segment-quest-badge-color) !important;
		}
	}
	border-radius: 50px;
	padding: 0px;
	color: var(--app-segment-text);
	--background: var(--app-segment-background) !important;
	.segment-button-checked {
		color: var(--app-segment-active-text);
		&.md::part(native) {
			// color: var(--app-color-primary-shade-30);
		}
		.badge {
			color: var(--app-segment-quest-badge-text-active) !important;
			background-color: var(--app-segment-quest-badge-color-active) !important;
		}
	}
	&-button {
		&.md::part(native) {
			// color: #b6b6b6;
		}
		&::part(indicator-background) {
			// background: var(--app-color-primary-shade-30);
			background: var(--app-segment-active-background);
			border-radius: 50px;
		}
	}
}
