.app-icon {
	font-family: 'tbt'; /* Use the font defined above */
	font-style: normal;
	font-weight: normal;
	display: inline-block;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 24px;
	width: 1em;
	align-items: center;
	display: flex;
	justify-content: center;
	&-small {
		font-size: 18px;
		width: 1em;
		align-items: center;
		display: flex;
		justify-content: center;
	}
	&-large {
		font-size: 28px;
		width: 1em;
		align-items: center;
		display: flex;
		justify-content: center;
	}
}

.app-icon-wolf-full::before {
	content: '\e001';
}
.app-icon-wolf-face::before {
	content: '\e002';
}
.app-icon-wolf-crown-eye::before {
	content: '\e003';
}
.app-icon-wolf-crown::before {
	content: '\e004';
}
.app-icon-wolf-eye::before {
	content: '\e005';
}
.app-icon-trading-battle::before {
	content: '\e006';
}
.app-icon-trading::before {
	content: '\e007';
}
.app-icon-battle::before {
	content: '\e008';
}
.app-icon-wolf-full-trading-battle::before {
	content: '\e009';
}
.app-icon-wolf-face-trading::before {
	content: '\e00a';
}
.app-icon-wolf-full-trading-battle-small::before {
	content: '\e00c';
}
.app-icon-wolf-face-trading-small::before {
	content: '\e00d';
}
.app-icon-wolf-crown-eye-battle-small::before {
	content: '\e00e';
}
.app-icon-tbt::before {
	content: '\e011';
}
.app-icon-circle-outline::before {
	content: '\e012';
}
.app-icon-circle-fill::before {
	content: '\e013';
}
.app-icon-home-fill::before {
	content: '\e020';
}
.app-icon-home-outline::before {
	content: '\e021';
}
.app-icon-badge-fill::before {
	content: '\e024';
}
.app-icon-badge-outline::before {
	content: '\e025';
}
.app-icon-cup-fill::before {
	content: '\e026';
}
.app-icon-cup-outline::before {
	content: '\e027';
}
.app-icon-task-fill::before {
	content: '\e028';
}
.app-icon-task-outline::before {
	content: '\e029';
}
.app-icon-gamepad-fill::before {
	content: '\e02a';
}
.app-icon-gamepad2-outline::before {
	content: '\e02b';
}
.app-icon-globe-fill::before {
	content: '\e02c';
}
.app-icon-globe-outline::before {
	content: '\e02d';
}
.app-icon-close-circle-fill::before {
	content: '\e030';
}
.app-icon-close-circle-outline::before {
	content: '\e031';
}
.app-icon-plus-circle-fill::before {
	content: '\e032';
}
.app-icon-plus-circle-outline::before {
	content: '\e033';
}
.app-icon-dash-circle-outline::before {
	content: '\e035';
}
.app-icon-question-circle-fill::before {
	content: '\e040';
}
.app-icon-question-circle-outline::before {
	content: '\e041';
}
.app-icon-exclamation-dimond-fill::before {
	content: '\e042';
}
.app-icon-exclamation-dimond-outline::before {
	content: '\e043';
}
.app-icon-info-bubble-fill::before {
	content: '\e044';
}
.app-icon-info-bubble-outline::before {
	content: '\e045';
}
.app-icon-circle-fill::before {
	content: '\e060';
}
.app-icon-chevron-left-circle-fill::before {
	content: '\e070';
}
.app-icon-chevron-left::before {
	content: '\e071';
}
.app-icon-chevron-right-circle-fill::before {
	content: '\e072';
}
.app-icon-chevron-right::before {
	content: '\e073';
}
.app-icon-chevron-up-circle-fill::before {
	content: '\e074';
}
.app-icon-chevron-down::before {
	content: '\e075';
}
.app-icon-chevron-down-circle-fill::before {
	content: '\e076';
}
.app-icon-chevron-up::before {
	content: '\e077';
}
.app-icon-arrow-right-circle-fill::before {
	content: '\e080';
}
.app-icon-arrow-right::before {
	content: '\e081';
}
.app-icon-arrow-left-circle-fill::before {
	content: '\e082';
}
.app-icon-arrow-left::before {
	content: '\e083';
}
.app-icon-arrow-up-circle-fill::before {
	content: '\e084';
}
.app-icon-arrow-up::before {
	content: '\e085';
}
.app-icon-arrow-down-circle-fill::before {
	content: '\e086';
}
.app-icon-arrow-down::before {
	content: '\e087';
}
.app-icon-person-fill::before {
	content: '\e090';
}
.app-icon-person-outline::before {
	content: '\e091';
}
.app-icon-person-edit-fill::before {
	content: '\e092';
}
.app-icon-person-edit-outline::before {
	content: '\e093';
}
.app-icon-person-search-fill::before {
	content: '\e094';
}
.app-icon-person-search-outline::before {
	content: '\e095';
}
.app-icon-person-add-fill::before {
	content: '\e096';
}
.app-icon-person-add-outline::before {
	content: '\e097';
}
.app-icon-person-dash-fill::before {
	content: '\e098';
}
.app-icon-person-dash-outline::before {
	content: '\e099';
}
.app-icon-person-ban-fill::before {
	content: '\e09a';
}
.app-icon-person-ban-outline::before {
	content: '\e09b';
}
.app-icon-people-fill::before {
	content: '\e09c';
}
.app-icon-people-outline::before {
	content: '\e09d';
}
.app-icon-home-off-fill::before {
	content: '\e100';
}
.app-icon-off::before {
	content: '\e101';
}
.app-icon-item-arrow-left::before {
	content: '\e103';
}
.app-icon-item-arrow-right::before {
	content: '\e105';
}
.app-icon-star-fill::before {
	content: '\e106';
}
.app-icon-star-outline::before {
	content: '\e107';
}
.app-icon-star-half-fill::before {
	content: '\e108';
}
.app-icon-medal-star-fill::before {
	content: '\e11a';
}
.app-icon-medal-star-outline::before {
	content: '\e11b';
}
.app-icon-badge-check-fill::before {
	content: '\e11c';
}
.app-icon-badge-check-outline::before {
	content: '\e11d';
}
.app-icon-bell-fill::before {
	content: '\e120';
}
.app-icon-bell-outline::before {
	content: '\e121';
}
.app-icon-heart-fill::before {
	content: '\e122';
}
.app-icon-heart-outline::before {
	content: '\e23';
}
//////////////////////////////////////
.app-icon-plus::before {
	content: '\e034';
}
.app-icon-tick-2::before {
	content: '\e036';
}
.app-icon-tick::before {
	content: '\e037';
}
.app-icon-square-outline::before {
	content: '\e038';
}
.app-icon-tick-square::before {
	content: '\e039';
}
.app-icon-close-circle-outline::before {
	content: '\e040';
}
.app-icon-tick-circle-outline::before {
	content: '\e041';
}
.app-icon-plus-circle-outline::before {
	content: '\e042';
}
.app-icon-dash-circle-outline::before {
	content: '\e043';
}
.app-icon-question-circle-outline::before {
	content: '\e044';
}
.app-icon-i-circle-outline::before {
	content: '\e045';
}
.app-icon-exclamation-circle::before {
	content: '\e046';
}
.app-icon-time-circle::before {
	content: '\e048';
}
.app-icon-circle-outline::before {
	content: '\e049';
}
.app-icon-exclamation-triangle::before {
	content: '\e050';
}
.app-icon-arrow-repeat-circle::before {
	content: '\e055';
}
.app-icon-arrow-repeat::before {
	content: '\e056';
}
.app-icon-arrow-counterclockwise::before {
	content: '\e057';
}

.app-icon-question-circle-fill::before {
	content: '\e061';
}
.app-icon-close-circle-fill::before {
	content: '\e062';
}
.app-icon-chevron-left-light::before {
	content: '\e070';
}
.app-icon-chevron-right-light::before {
	content: '\e071';
}
.app-icon-chevron-left::before {
	content: '\e074';
}
.app-icon-chevron-right::before {
	content: '\e075';
}
// .app-icon-chevron-down::before {
// 	content: '\e076';
// }
.app-icon-chevron-up::before {
	content: '\e077';
}
.app-icon-arrow-right::before {
	content: '\e078';
}
.app-icon-arrow-right-outline::before {
	content: '\e079';
}
.app-icon-arrow-left-outline::before {
	content: '\e07a';
}
.app-icon-arrows-move::before {
	content: '\e07b';
}
// .app-icon-person-fill::before {
// 	content: '\e080';
// }
.app-icon-person-outline::before {
	content: '\e081';
}

.app-icon-call-outline::before {
	content: '\e090';
}
.app-icon-whatsapp::before {
	content: '\e091';
}
// .app-icon-phone-outline::before {
// 	content: '\e092';
// }
// .app-icon-phone-fill::before {
// 	content: '\e093';
// }
.app-icon-mail-outline::before {
	content: '\e094';
}
.app-icon-mail-fill::before {
	content: '\e095';
}
.app-icon-headset-outline::before {
	content: '\e096';
}
.app-icon-shop-outline::before {
	content: '\e100';
}
.app-icon-fax-outline::before {
	content: '\e101';
}
.app-icon-charts-outline::before {
	content: '\e102';
}
.app-icon-horn-outline::before {
	content: '\e103';
}
.app-icon-sliders-vertical-outline::before {
	content: '\e104';
}
.app-icon-earth-outline::before {
	content: '\e105';
}
.app-icon-box-outline::before {
	content: '\e106';
}
.app-icon-cake-outline::before {
	content: '\e107';
}
.app-icon-tequila-outline::before {
	content: '\e108';
}
.app-icon-gift-outline::before {
	content: '\e109';
}
.app-icon-cart::before {
	content: '\e10a';
}
.app-icon-cart-arrow-down::before {
	content: '\e10b';
}
.app-icon-cart-arrow-up::before {
	content: '\e10c';
}
.app-icon-cash-outline::before {
	content: '\e120';
}
.app-icon-wallet-outline::before {
	content: '\e121';
}
.app-icon-coin-outline::before {
	content: '\e122';
}
.app-icon-credit-card-outline::before {
	content: '\e123';
}
.app-icon-credit-card2-outline::before {
	content: '\e124';
}
.app-icon-credit-card3-outline::before {
	content: '\e125';
}
.app-icon-bill-outline::before {
	content: '\e126';
}
.app-icon-bill-time-outline::before {
	content: '\e127';
}
.app-icon-bill-hand-outline::before {
	content: '\e128';
}
.app-icon-calendar-outline::before {
	content: '\e130';
}
.app-icon-calendar-check-outline::before {
	content: '\e131';
}
.app-icon-calendar-close-outline::before {
	content: '\e132';
}
.app-icon-calendar-number-outline::before {
	content: '\e133';
}
.app-icon-geo-outline::before {
	content: '\e140';
}
.app-icon-target::before {
	content: '\e142';
}
.app-icon-send-outline::before {
	content: '\e144';
}
.app-icon-lock-open-outline::before {
	content: '\e146';
}
.app-icon-lock-close-outline::before {
	content: '\e148';
}
.app-icon-key-outline::before {
	content: '\e150';
}
.app-icon-thumbs-up-outline::before {
	content: '\e151';
}
.app-icon-thumbs-down-outline::before {
	content: '\e152';
}
.app-icon-hand-point-fill::before {
	content: '\e153';
}
.app-icon-hand-touch-fill::before {
	content: '\e154';
}
.app-icon-hand-touch-vertical-fill::before {
	content: '\e155';
}
.app-icon-power-off::before {
	content: '\e160';
}
.app-icon-log-out::before {
	content: '\e161';
}

.app-icon-share-outline::before {
	content: '\e168';
}
.app-icon-bell-outline::before {
	content: '\e170';
}
.app-icon-chat-list-outline::before {
	content: '\e172';
}
.app-icon-chat-plus-outline::before {
	content: '\e174';
}
.app-icon-chat-dots-fill::before {
	content: '\e176';
}
.app-icon-box-arrow-left-fill::before {
	content: '\e180';
}
.app-icon-box-arrow-right-outline::before {
	content: '\e180';
}
.app-icon-box-arrow-down-fill::before {
	content: '\e183';
}
.app-icon-box-arrow-up-outline::before {
	content: '\e184';
}
.app-icon-facebook-fill::before {
	content: '\e201';
}
.app-icon-instagram-outline::before {
	content: '\e202';
}
.app-icon-telegram-fill::before {
	content: '\e203';
}
.app-icon-internet-explorer-fill::before {
	content: '\e204';
}
.app-icon-twitter-fill::before {
	content: '\e205';
}
.app-icon-list-outline::before {
	content: '\e210';
}
.app-icon-list-fill::before {
	content: '\e211';
}
.app-icon-list::before {
	content: '\e212';
}
.app-icon-three-dots-vertical::before {
	content: '\e217';
}
.app-icon-four-dots-vertical::before {
	content: '\e218';
}
.app-icon-three-dots-horizontal::before {
	content: '\e219';
}
.app-icon-trash-outline::before {
	content: '\e240';
}
.app-icon-lamp-outline::before {
	content: '\e242';
}
.app-icon-search-outline::before {
	content: '\e244';
}
.app-icon-search-close-outline::before {
	content: '\e245';
}
.app-icon-funnel-outline::before {
	content: '\e246';
}
.app-icon-gear-outline::before {
	content: '\e248';
}
.app-icon-chair-side-outline::before {
	content: '\e249';
}
.app-icon-pencil-outline::before {
	content: '\e254';
}
.app-icon-file-items-outline::before {
	content: '\e256';
}
.app-icon-box-arrow-out::before {
	content: '\e260';
}
.app-icon-download::before {
	content: '\e262';
}
.app-icon-wifi-ban::before {
	content: '\e271';
}
.app-icon-snapchat-fill::before {
	content: '\e280';
}
.app-icon-yelp-fill::before {
	content: '\e282';
}
.app-icon-picture-outline::before {
	content: '\e300';
}
.app-icon-pictures-outline::before {
	content: '\e301';
}
.app-icon-camera-circle-outline::before {
	content: '\e302';
}
.app-icon-camera-arrow-up-outline::before {
	content: '\e303';
}
.app-icon-camera-square-outline::before {
	content: '\e304';
}
.app-icon-eye-slash-outline::before {
	content: '\e305';
}
.app-icon-eye-outline::before {
	content: '\e306';
}
.app-icon-sun-outline::before {
	content: '\e307';
}
.app-icon-moon-outline::before {
	content: '\e308';
}
.app-icon-caret-right-fill::before {
	content: '\e320';
}
.app-icon-caret-left-fill::before {
	content: '\e321';
}
.app-icon-person-real-circle-fill::before {
	content: '\e400';
}
.app-icon-person-real-outline::before {
	content: '\e401';
}
.app-icon-people-real-outline::before {
	content: '\e402';
}
.app-icon-person-standup-outline::before {
	content: '\e403';
}
.app-icon-person-card-outline::before {
	content: '\e404';
}
.app-icon-people-plus-outline::before {
	content: '\e405';
}
.app-icon-people-arrow-outline::before {
	content: '\e406';
}
.app-icon-person-real-file-outline::before {
	content: '\e407';
}
.app-icon-person-three-outline::before {
	content: '\e408';
}
.app-icon-journal-bookmark-outline::before {
	content: '\e409';
}
.app-icon-chart-line-outline::before {
	content: '\e501';
}
