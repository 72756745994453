.app-bounce-in {
	-webkit-animation-name: app-bounce-in;
	animation-name: app-bounce-in;
	-webkit-animation-duration: 0.75s;
	animation-duration: 0.75s;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
