.custom-toast {
	&-success {
		// margin-top: 65px;
		--background: var(--app-color-success) !important;
		color: var(--app-color-dark) !important;
		--border-radius: 10px;
		white-space: pre-wrap;

		&::part(button) {
			color: var(--app-color-dark);
			border-left: 1px solid var(--app-color-black);
			font-size: 14px;
		}
	}
	&-error {
		// margin-top: 65px;
		--background: var(--app-color-danger) !important;
		color: var(--app-color-white) !important;
		--border-radius: 10px;
		white-space: pre-wrap;

		&::part(button) {
			color: var(--app-color-white);
			border-left: 1px solid var(--app-color-black);
			font-size: 14px;
		}
	}
	&-warning {
		// margin-top: 65px;
		--background: var(--app-color-warning) !important;
		color: var(--app-color-dark) !important;
		--border-radius: 10px;
		white-space: pre-wrap;

		&::part(button) {
			color: var(--app-color-dark);
			border-left: 1px solid var(--app-color-black);
			font-size: 14px;
		}
	}
	&-info {
		// margin-top: 65px;
		--background: var(--app-color-info) !important;
		color: var(--app-color-dark) !important;
		--border-radius: 10px;
		white-space: pre-wrap;

		&::part(button) {
			color: var(--app-color-dark);
			border-left: 1px solid var(--app-color-black);
			font-size: 14px;
		}
	}
}

.below-header {
	top: 48px !important; /* Adjust this value to match your header height */
}
