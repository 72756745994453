// app-shine-animation
@keyframes app-shine-animation {
	0% {
		left: -100%;
		transition-property: left;
	}
	// #{(var(--button-shine-animation-speed) / (var(--button-shine-animation-duration) + var(--button-shine-animation-speed)) * 100%)},
	#{calc(($button-shine-animation-speed / ($button-shine-animation-duration + $button-shine-animation-speed) * 100%))},
	100% {
		left: 100%;
		transition-property: left;
	}
}

// app-rotate-circle-border
@keyframes app-rotate-circle-border {
	from {
		transform: rotate(0deg) scale(1);
	}
	to {
		transform: rotate(360deg) scale(1);
	}
}
@keyframes psycho {
	0% {
		transform: rotate(0deg) scale(1) translate(0, 0);
	}
	33% {
		transform: rotate(360deg) scale(1) translate(5px, 5px);
	}
	66% {
		transform: rotate(720deg) scale(1) translate(-5px, -5px);
	}
	100% {
		transform: rotate(1080deg) scale(1) translate(0, 0);
	}
}

// app-fade-in-out
@keyframes app-fade-in-out {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

// app-fade-in-down
@keyframes app-fade-in-down {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

// app-fade-in-right
@keyframes app-fade-in-right {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

// app-fade-in-up
@keyframes app-fade-in-up {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

// app-fade-in-left
@keyframes app-fade-in-left {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

// app-bounce-in
@keyframes app-bounce-in {
	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		-webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	25% {
		-webkit-transform: scale3d(1.05, 1.05, 1.05);
		transform: scale3d(1.05, 1.05, 1.05);
	}
	50% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	75% {
		opacity: 1;
		-webkit-transform: scale3d(1.03, 1.03, 1.03);
		transform: scale3d(1.03, 1.03, 1.03);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
// app-bg-spin
@-webkit-keyframes app-bg-spin {
	to {
		--border-angle: 1turn;
	}
}
@keyframes app-bg-spin {
	to {
		--border-angle: 1turn;
	}
}

// app-primary-shadow-throb
/**
	* The box-shadow property takes a comma-delimited list of shadows. To make the
	* button "throb", each shadow is going to define a "ring" around the button that
	* grows outward using the "spread-radius" (4th short-hand value). Our three
	* shadows define three rings that have a staggered outward animation.
*/
@keyframes app-primary-shadow-throb {
	0% {
		box-shadow: 0px 0px 0px 0px var(--app-color-primary-transparent-50), /* Ring three - hidden. */ 0px 0px 0px 0px var(--app-color-primary-transparent-50),
			/* Ring two - hidden. */ 0px 0px 0px 0px var(--app-color-primary-transparent-50) /* Ring one - hidden. */;
	}
	15% {
		box-shadow: 0px 0px 0px 0px var(--app-color-primary-transparent-50), 0px 0px 0px 0px var(--app-color-primary-transparent-50),
			0px 0px 0px 5px var(--app-color-primary-transparent-50) /* Ring one - enter. */;
	}
	30% {
		box-shadow: 0px 0px 0px 0px var(--app-color-primary-transparent-50), 0px 0px 0px 5px var(--app-color-primary-transparent-50),
			/* Ring two - enter. */ 0px 0px 0px 10px var(--app-color-primary-transparent-30);
	}
	45% {
		box-shadow: 0px 0px 0px 5px var(--app-color-primary-transparent-50), /* Ring three - enter. */ 0px 0px 0px 10px var(--app-color-primary-transparent-30),
			0px 0px 0px 15px var(--app-color-primary-transparent-20);
	}
	/**
	* Once each ring reaches its outer spread-radius, it's going to fade out using
	* the alpha-channel on the RGB(A) hex color definition. Notice that the alpha-
	* channels go from "80" to "00" over the next couple of keyframes.
	*/
	60% {
		box-shadow: 0px 0px 0px 10px var(--app-color-primary-transparent-30), 0px 0px 0px 15px var(--app-color-primary-transparent-20),
			0px 0px 15px 15px var(--app-color-primary-transparent-0);
	}
	75% {
		box-shadow: 0px 0px 0px 15px var(--app-color-primary-transparent-20), 0px 0px 15px 15px var(--app-color-primary-transparent-0),
			0px 0px 15px 15px var(--app-color-primary-transparent-0);
	}
	90% {
		box-shadow: 0px 0px 15px 15px var(--app-color-primary-transparent-0), 0px 0px 15px 15px var(--app-color-primary-transparent-0),
			0px 0px 15px 15px var(--app-color-primary-transparent-0);
	}
	100% {
		box-shadow: 0px 0px 15px 15px var(--app-color-primary-transparent-0), 0px 0px 15px 15px var(--app-color-primary-transparent-0),
			0px 0px 15px 15px var(--app-color-primary-transparent-0);
	}
}

// app-secondary-shadow-throb
@keyframes app-secondary-shadow-throb {
	0% {
		box-shadow: 0px 0px 0px 0px var(--app-color-secondary-transparent-50), /* Ring three - hidden. */ 0px 0px 0px 0px var(--app-color-secondary-transparent-50),
			/* Ring two - hidden. */ 0px 0px 0px 0px var(--app-color-secondary-transparent-50) /* Ring one - hidden. */;
	}
	15% {
		box-shadow: 0px 0px 0px 0px var(--app-color-secondary-transparent-50), 0px 0px 0px 0px var(--app-color-secondary-transparent-50),
			0px 0px 0px 5px var(--app-color-secondary-transparent-50) /* Ring one - enter. */;
	}
	30% {
		box-shadow: 0px 0px 0px 0px var(--app-color-secondary-transparent-50), 0px 0px 0px 5px var(--app-color-secondary-transparent-50),
			/* Ring two - enter. */ 0px 0px 0px 10px var(--app-color-secondary-transparent-30);
	}
	45% {
		box-shadow: 0px 0px 0px 5px var(--app-color-secondary-transparent-50), /* Ring three - enter. */ 0px 0px 0px 10px var(--app-color-secondary-transparent-30),
			0px 0px 0px 15px var(--app-color-secondary-transparent-20);
	}
	/**
	* Once each ring reaches its outer spread-radius, it's going to fade out using
	* the alpha-channel on the RGB(A) hex color definition. Notice that the alpha-
	* channels go from "80" to "00" over the next couple of keyframes.
	*/
	60% {
		box-shadow: 0px 0px 0px 10px var(--app-color-secondary-transparent-30), 0px 0px 0px 15px var(--app-color-secondary-transparent-20),
			0px 0px 15px 15px var(--app-color-secondary-transparent-0);
	}
	75% {
		box-shadow: 0px 0px 0px 15px var(--app-color-secondary-transparent-20), 0px 0px 15px 15px var(--app-color-secondary-transparent-0),
			0px 0px 15px 15px var(--app-color-secondary-transparent-0);
	}
	90% {
		box-shadow: 0px 0px 15px 15px var(--app-color-secondary-transparent-0), 0px 0px 15px 15px var(--app-color-secondary-transparent-0),
			0px 0px 15px 15px var(--app-color-secondary-transparent-0);
	}
	100% {
		box-shadow: 0px 0px 15px 15px var(--app-color-secondary-transparent-0), 0px 0px 15px 15px var(--app-color-secondary-transparent-0),
			0px 0px 15px 15px var(--app-color-secondary-transparent-0);
	}
}

// app-success-shadow-throb
@keyframes app-success-shadow-throb {
	0% {
		box-shadow: 0px 0px 0px 0px var(--app-color-success-transparent-50), /* Ring three - hidden. */ 0px 0px 0px 0px var(--app-color-success-transparent-50),
			/* Ring two - hidden. */ 0px 0px 0px 0px var(--app-color-success-transparent-50) /* Ring one - hidden. */;
	}
	15% {
		box-shadow: 0px 0px 0px 0px var(--app-color-success-transparent-50), 0px 0px 0px 0px var(--app-color-success-transparent-50),
			0px 0px 0px 5px var(--app-color-success-transparent-50) /* Ring one - enter. */;
	}
	30% {
		box-shadow: 0px 0px 0px 0px var(--app-color-success-transparent-50), 0px 0px 0px 5px var(--app-color-success-transparent-50),
			/* Ring two - enter. */ 0px 0px 0px 10px var(--app-color-success-transparent-30);
	}
	45% {
		box-shadow: 0px 0px 0px 5px var(--app-color-success-transparent-50), /* Ring three - enter. */ 0px 0px 0px 10px var(--app-color-success-transparent-30),
			0px 0px 0px 15px var(--app-color-success-transparent-20);
	}
	/**
	* Once each ring reaches its outer spread-radius, it's going to fade out using
	* the alpha-channel on the RGB(A) hex color definition. Notice that the alpha-
	* channels go from "80" to "00" over the next couple of keyframes.
	*/
	60% {
		box-shadow: 0px 0px 0px 10px var(--app-color-success-transparent-30), 0px 0px 0px 15px var(--app-color-success-transparent-20),
			0px 0px 15px 15px var(--app-color-success-transparent-0);
	}
	75% {
		box-shadow: 0px 0px 0px 15px var(--app-color-success-transparent-20), 0px 0px 15px 15px var(--app-color-success-transparent-0),
			0px 0px 15px 15px var(--app-color-success-transparent-0);
	}
	90% {
		box-shadow: 0px 0px 15px 15px var(--app-color-success-transparent-0), 0px 0px 15px 15px var(--app-color-success-transparent-0),
			0px 0px 15px 15px var(--app-color-success-transparent-0);
	}
	100% {
		box-shadow: 0px 0px 15px 15px var(--app-color-success-transparent-0), 0px 0px 15px 15px var(--app-color-success-transparent-0),
			0px 0px 15px 15px var(--app-color-success-transparent-0);
	}
}

// app-danger-shadow-throb
@keyframes app-danger-shadow-throb {
	0% {
		box-shadow: 0px 0px 0px 0px var(--app-color-danger-transparent-50), /* Ring three - hidden. */ 0px 0px 0px 0px var(--app-color-danger-transparent-50),
			/* Ring two - hidden. */ 0px 0px 0px 0px var(--app-color-danger-transparent-50) /* Ring one - hidden. */;
	}
	15% {
		box-shadow: 0px 0px 0px 0px var(--app-color-danger-transparent-50), 0px 0px 0px 0px var(--app-color-danger-transparent-50),
			0px 0px 0px 5px var(--app-color-danger-transparent-50) /* Ring one - enter. */;
	}
	30% {
		box-shadow: 0px 0px 0px 0px var(--app-color-danger-transparent-50), 0px 0px 0px 5px var(--app-color-danger-transparent-50),
			/* Ring two - enter. */ 0px 0px 0px 10px var(--app-color-danger-transparent-30);
	}
	45% {
		box-shadow: 0px 0px 0px 5px var(--app-color-danger-transparent-50), /* Ring three - enter. */ 0px 0px 0px 10px var(--app-color-danger-transparent-30),
			0px 0px 0px 30px var(--app-color-danger-transparent-20);
	}
	/**
	* Once each ring reaches its outer spread-radius, it's going to fade out using
	* the alpha-channel on the RGB(A) hex color definition. Notice that the alpha-
	* channels go from "80" to "00" over the next couple of keyframes.
	*/
	60% {
		box-shadow: 0px 0px 0px 10px var(--app-color-danger-transparent-30), 0px 0px 0px 30px var(--app-color-danger-transparent-20),
			0px 0px 30px 30px var(--app-color-danger-transparent-0);
	}
	75% {
		box-shadow: 0px 0px 0px 30px var(--app-color-danger-transparent-20), 0px 0px 30px 30px var(--app-color-danger-transparent-0),
			0px 0px 30px 30px var(--app-color-danger-transparent-0);
	}
	90% {
		box-shadow: 0px 0px 30px 30px var(--app-color-danger-transparent-0), 0px 0px 30px 30px var(--app-color-danger-transparent-0),
			0px 0px 30px 30px var(--app-color-danger-transparent-0);
	}
	100% {
		box-shadow: 0px 0px 30px 30px var(--app-color-danger-transparent-0), 0px 0px 30px 30px var(--app-color-danger-transparent-0),
			0px 0px 30px 30px var(--app-color-danger-transparent-0);
	}
}

// app-info-shadow-throb
@keyframes app-info-shadow-throb {
	0% {
		box-shadow: 0px 0px 0px 0px var(--app-color-info-transparent-50), /* Ring three - hidden. */ 0px 0px 0px 0px var(--app-color-info-transparent-50),
			/* Ring two - hidden. */ 0px 0px 0px 0px var(--app-color-info-transparent-50) /* Ring one - hidden. */;
	}
	15% {
		box-shadow: 0px 0px 0px 0px var(--app-color-info-transparent-50), 0px 0px 0px 0px var(--app-color-info-transparent-50),
			0px 0px 0px 5px var(--app-color-info-transparent-50) /* Ring one - enter. */;
	}
	30% {
		box-shadow: 0px 0px 0px 0px var(--app-color-info-transparent-50), 0px 0px 0px 5px var(--app-color-info-transparent-50),
			/* Ring two - enter. */ 0px 0px 0px 10px var(--app-color-info-transparent-30);
	}
	45% {
		box-shadow: 0px 0px 0px 5px var(--app-color-info-transparent-50), /* Ring three - enter. */ 0px 0px 0px 10px var(--app-color-info-transparent-30),
			0px 0px 0px 15px var(--app-color-info-transparent-20);
	}
	/**
	* Once each ring reaches its outer spread-radius, it's going to fade out using
	* the alpha-channel on the RGB(A) hex color definition. Notice that the alpha-
	* channels go from "80" to "00" over the next couple of keyframes.
	*/
	60% {
		box-shadow: 0px 0px 0px 10px var(--app-color-info-transparent-30), 0px 0px 0px 15px var(--app-color-info-transparent-20),
			0px 0px 15px 15px var(--app-color-info-transparent-0);
	}
	75% {
		box-shadow: 0px 0px 0px 15px var(--app-color-info-transparent-20), 0px 0px 15px 15px var(--app-color-info-transparent-0),
			0px 0px 15px 15px var(--app-color-info-transparent-0);
	}
	90% {
		box-shadow: 0px 0px 15px 15px var(--app-color-info-transparent-0), 0px 0px 15px 15px var(--app-color-info-transparent-0),
			0px 0px 15px 15px var(--app-color-info-transparent-0);
	}
	100% {
		box-shadow: 0px 0px 15px 15px var(--app-color-info-transparent-0), 0px 0px 15px 15px var(--app-color-info-transparent-0),
			0px 0px 15px 15px var(--app-color-info-transparent-0);
	}
}

// app-warning-shadow-throb
@keyframes app-warning-shadow-throb {
	0% {
		box-shadow: 0px 0px 0px 0px var(--app-color-warning-transparent-50), /* Ring three - hidden. */ 0px 0px 0px 0px var(--app-color-warning-transparent-50),
			/* Ring two - hidden. */ 0px 0px 0px 0px var(--app-color-warning-transparent-50) /* Ring one - hidden. */;
	}
	15% {
		box-shadow: 0px 0px 0px 0px var(--app-color-warning-transparent-50), 0px 0px 0px 0px var(--app-color-warning-transparent-50),
			0px 0px 0px 5px var(--app-color-warning-transparent-50) /* Ring one - enter. */;
	}
	30% {
		box-shadow: 0px 0px 0px 0px var(--app-color-warning-transparent-50), 0px 0px 0px 5px var(--app-color-warning-transparent-50),
			/* Ring two - enter. */ 0px 0px 0px 10px var(--app-color-warning-transparent-30);
	}
	45% {
		box-shadow: 0px 0px 0px 5px var(--app-color-warning-transparent-50), /* Ring three - enter. */ 0px 0px 0px 10px var(--app-color-warning-transparent-30),
			0px 0px 0px 15px var(--app-color-warning-transparent-20);
	}
	/**
	* Once each ring reaches its outer spread-radius, it's going to fade out using
	* the alpha-channel on the RGB(A) hex color definition. Notice that the alpha-
	* channels go from "80" to "00" over the next couple of keyframes.
	*/
	60% {
		box-shadow: 0px 0px 0px 10px var(--app-color-warning-transparent-30), 0px 0px 0px 15px var(--app-color-warning-transparent-20),
			0px 0px 15px 15px var(--app-color-warning-transparent-0);
	}
	75% {
		box-shadow: 0px 0px 0px 15px var(--app-color-warning-transparent-20), 0px 0px 15px 15px var(--app-color-warning-transparent-0),
			0px 0px 15px 15px var(--app-color-warning-transparent-0);
	}
	90% {
		box-shadow: 0px 0px 15px 15px var(--app-color-warning-transparent-0), 0px 0px 15px 15px var(--app-color-warning-transparent-0),
			0px 0px 15px 15px var(--app-color-warning-transparent-0);
	}
	100% {
		box-shadow: 0px 0px 15px 15px var(--app-color-warning-transparent-0), 0px 0px 15px 15px var(--app-color-warning-transparent-0),
			0px 0px 15px 15px var(--app-color-warning-transparent-0);
	}
}

// app-spin-animation
@keyframes app-spin-animation {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
}

// app-three-dots-loading
@keyframes app-three-dots-loading {
	33% {
		background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
	}

	50% {
		background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
	}

	66% {
		background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
	}
}

@keyframes glow {
	0% {
		filter: hue-rotate(0deg);
	}
	100% {
		filter: hue-rotate(360deg);
	}
}

@keyframes spin {
	100% {
		transform: rotateZ(360deg);
	}
}

// #region Neon Button
@keyframes neon-button-scaling-shadow {
	0% {
		top: 100%;
		transform: perspective(1.5em) rotateX(45deg) scale(0.5, 0.3);
	}
	50% {
		top: 145%;
		transform: perspective(1.5em) rotateX(45deg) scale(0.45, 0.3);
	}
	100% {
		top: 100%;
		transform: perspective(1.5em) rotateX(45deg) scale(0.5, 0.3);
	}
}

@keyframes neon-button-active-animate {
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(-40%);
	}
	100% {
		transform: translateY(0%);
	}
}

// #endregion

// #region Flicker Animation
@-webkit-keyframes app-flicker {
	0% {
		opacity: 1;
	}
	5% {
		opacity: 1;
	}
	5.5% {
		opacity: 0;
	}
	6% {
		opacity: 1;
	}
	6.5% {
		opacity: 1;
	}
	7% {
		opacity: 0;
	}
	8% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
@keyframes app-flicker {
	0% {
		opacity: 1;
	}
	5% {
		opacity: 1;
	}
	5.5% {
		opacity: 0;
	}
	6% {
		opacity: 1;
	}
	6.5% {
		opacity: 1;
	}
	7% {
		opacity: 0;
	}
	8% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
// #endregion
