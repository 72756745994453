ion-modal {
	--background: var(--app-modal-background) !important;
	--border-radius: 15px;
	--ion-background-color: none;

	ion-header {
		background-color: var(--app-ion-modal-header);
		backdrop-filter: blur(30px);
		box-shadow: none !important;
		ion-toolbar {
			ion-button {
				ion-icon {
					color: var(--app-ion-modal-header-icon) !important;
				}
			}
		}
	}

	ion-content {
		background-color: var(--app-ion-modal-content);
		backdrop-filter: blur(30px);
	}
}
ion-modal.ios {
	--border-radius: 0px;
}

ion-modal#battle-invitation-modal {
	--width: fit-content;
	--min-width: 300px;
	--height: 50%;
	--border-radius: 5px;
	--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	--background: rgba(0, 0, 0);
}

ion-modal#battle-invitation-modal::part(backdrop) {
	background: rgba(209, 213, 219);
	opacity: 1;
}

ion-modal#battle-invitation-modal ion-toolbar {
	--background: var(--app-ion-modal-header);
	backdrop-filter: blur(30px);
	--color: var(--app-color-white);
}

ion-modal#battle-invitation-modal h1 {
	margin: 20px 20px 10px 20px;
}

ion-modal#battle-invitation-modal .wrapper {
	margin-bottom: 10px;
}

.app-modal-background {
	background-color: var(--app-modal-background);
}
