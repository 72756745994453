// #region app-shine-animation
.app-shine-animation {
	position: relative;
	overflow: hidden; // debug
	padding: 0;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		transform: skewX(-20deg);
		// background: linear-gradient(135deg, transparent, rgba(255, 255, 255, 0.9), transparent);
		background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.3), transparent);
		animation: app-shine-animation var(--button-shine-animation-duration) ease infinite;
	}
}

.app-shine-animation-2 {
	position: relative;
	overflow: hidden; // debug
	padding: 0;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		transform: skewX(-20deg);
		// background: linear-gradient(135deg, transparent, rgba(255, 255, 255, 0.9), transparent);
		background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.3), transparent);
		animation: app-shine-animation 3s ease infinite;
	}
}

// #endregion

// #region app-flicker-animation
.app-flicker-animation {
	-webkit-animation: app-flicker 2s ease-in-out infinite alternate;
	animation: app-flicker 2s ease-in-out infinite alternate;
}
// #endregion
